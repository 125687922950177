<div *ngIf="visible" class="d-flex flex-column align-items-end jumbotron mb-4 p-4">
    <div *ngIf="closable">
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="d-flex justify-content-arround vp-gap-48 px-4 pb-4">
        <div *ngIf="imageSrc" class="d-flex justify-content-center align-items-center">
            <img class="image" [src]="imageSrc" />
        </div>
        <div class="d-flex flex-column vp-gap-16">
            <h2 *ngIf="title" class="text-large">{{ title }}</h2>
            <div class="content"><ng-content></ng-content></div>
        </div>
    </div>
</div>
