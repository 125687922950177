/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CreateQuoteResponse } from '../models/create-quote-response';
import { quotesControllerCreateQuote } from '../fn/quotes-controller/quotes-controller-create-quote';
import { QuotesControllerCreateQuote$Params } from '../fn/quotes-controller/quotes-controller-create-quote';
import { quotesControllerGetQuotes } from '../fn/quotes-controller/quotes-controller-get-quotes';
import { QuotesControllerGetQuotes$Params } from '../fn/quotes-controller/quotes-controller-get-quotes';
import { QuotesResponseGetRecordsList } from '../models/quotes-response-get-records-list';


/**
 * Represents all API calls related to special params calls specific for particular satellite applications.
 */
@Injectable({ providedIn: 'root' })
export class QuotesControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `quotesControllerGetQuotes()` */
  static readonly QuotesControllerGetQuotesPath = '/planning-projects/v1-develop/quotes';

  /**
   * Gets all quote records for a given project variant plannings.
   *         Only given variant planning quotes will be returned. Quotes are created for plannings,
   *         one to one relation. There must exist a planning for each quote, otherwise quote
   *         is skipped in response.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `quotesControllerGetQuotes()` instead.
   *
   * This method doesn't expect any request body.
   */
  quotesControllerGetQuotes$Response(params: QuotesControllerGetQuotes$Params, context?: HttpContext): Observable<StrictHttpResponse<QuotesResponseGetRecordsList>> {
    return quotesControllerGetQuotes(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets all quote records for a given project variant plannings.
   *         Only given variant planning quotes will be returned. Quotes are created for plannings,
   *         one to one relation. There must exist a planning for each quote, otherwise quote
   *         is skipped in response.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `quotesControllerGetQuotes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  quotesControllerGetQuotes(params: QuotesControllerGetQuotes$Params, context?: HttpContext): Observable<QuotesResponseGetRecordsList> {
    return this.quotesControllerGetQuotes$Response(params, context).pipe(
      map((r: StrictHttpResponse<QuotesResponseGetRecordsList>): QuotesResponseGetRecordsList => r.body)
    );
  }

  /** Path part for operation `quotesControllerCreateQuote()` */
  static readonly QuotesControllerCreateQuotePath = '/planning-projects/v1-develop/quotes';

  /**
   * Request a new quote project to be created in Quote Assist tool. As a result we receive new project URL.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `quotesControllerCreateQuote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  quotesControllerCreateQuote$Response(params: QuotesControllerCreateQuote$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateQuoteResponse>> {
    return quotesControllerCreateQuote(this.http, this.rootUrl, params, context);
  }

  /**
   * Request a new quote project to be created in Quote Assist tool. As a result we receive new project URL.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `quotesControllerCreateQuote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  quotesControllerCreateQuote(params: QuotesControllerCreateQuote$Params, context?: HttpContext): Observable<CreateQuoteResponse> {
    return this.quotesControllerCreateQuote$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateQuoteResponse>): CreateQuoteResponse => r.body)
    );
  }

}
