import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs';
import { HeatloadFormService } from './heatload-form.service';
import { ProjectStore } from '../../state/project/project.store';
import { TranslateService } from '@ngx-translate/core';
import { AccordionService, CalculationMethodOptions, CalculationMethodValue } from '@customer-apps/shared/interfaces';
import { BuildingType, CountryCode, FormKeys, HeatLoadCalculationMethod, ProjectType, SuccessMessages } from '@customer-apps/shared/enums';
import { ProjectResponseGetById } from '@customer-apps/api-planning-projects';

@Injectable({
    providedIn: 'root'
})
export class CalculationMethodService implements AccordionService {
    constructor(
        private route: ActivatedRoute,
        private translate: TranslateService,
        private projectStore: ProjectStore,
        private heatloadFormService: HeatloadFormService
    ) {}

    public questions$ = this.projectStore.project$.pipe(
        filter(Boolean),
        map(project => {
            const options = {
                projectType: project.projectType as ProjectType,
                buildingType: project.building.buildingType as BuildingType,
                countryCode: project.address.countryCode as CountryCode
            };

            const alreadyCalculatedMethods = project.building.heatLoadCalculations?.map(
                heatloadCalculation => heatloadCalculation.method
            ) as HeatLoadCalculationMethod[];

            if (!this.isPrefillNeeded()) {
                return this.heatloadFormService.getCalculationMethodQuestions({
                    ...options,
                    alreadyCalculatedMethods
                });
            }
            if (this.getRecalculationMethod() === HeatLoadCalculationMethod.CustomValue) {
                return this.questionsByCustomValueRecalculation(options, project, alreadyCalculatedMethods);
            } else {
                return this.questionsByAnyMethodRecalculation(options, alreadyCalculatedMethods);
            }
        })
    );

    public isPrefillNeeded(): boolean {
        const calculationMethods = [
            HeatLoadCalculationMethod.BuildingHull,
            HeatLoadCalculationMethod.LivingSpace,
            HeatLoadCalculationMethod.CustomValue,
            HeatLoadCalculationMethod.EnergyCertificate,
            HeatLoadCalculationMethod.Consumption
        ];

        return Boolean(
            this.getRecalculationMethod() &&
                calculationMethods.some(calculationMethod => calculationMethod === this.getRecalculationMethod())
        );
    }

    public getSuccessMessage(formValue: CalculationMethodValue): string {
        if (!formValue.methods.standardMethod) {
            return '';
        }
        const config = {
            [HeatLoadCalculationMethod.LivingSpace]: SuccessMessages.LivingArea,
            [HeatLoadCalculationMethod.BuildingHull]: SuccessMessages.BuildingHull,
            [HeatLoadCalculationMethod.Consumption]: SuccessMessages.ByConsumption,
            [HeatLoadCalculationMethod.EnergyCertificate]: SuccessMessages.EnergyCertificate,
            [HeatLoadCalculationMethod.CustomValue]: ''
        };
        return this.translate.instant(config[formValue.methods.standardMethod] as string);
    }

    private questionsByCustomValueRecalculation(
        options: CalculationMethodOptions,
        project: ProjectResponseGetById,
        alreadyCalculatedMethods: HeatLoadCalculationMethod[]
    ) {
        const heatlaod = project.building.heatLoadCalculations?.find(
            calculation => calculation.method === HeatLoadCalculationMethod.CustomValue
        );
        return this.heatloadFormService.getCalculationMethodQuestions({
            ...options,
            questions: {
                [FormKeys.DefinedValue]: { value: heatlaod?.baseHeatLoad },
                [FormKeys.StandardMethod]: { disabled: true }
            },
            alreadyCalculatedMethods: alreadyCalculatedMethods.filter(
                alreadyCalculatedMethod => alreadyCalculatedMethod !== HeatLoadCalculationMethod.CustomValue
            )
        });
    }

    private questionsByAnyMethodRecalculation(options: CalculationMethodOptions, alreadyCalculatedMethods: HeatLoadCalculationMethod[]) {
        return this.heatloadFormService.getCalculationMethodQuestions({
            ...options,
            questions: {
                [FormKeys.StandardMethod]: { value: this.getRecalculationMethod() }
            },
            alreadyCalculatedMethods: alreadyCalculatedMethods.filter(
                alreadyCalculatedMethod => alreadyCalculatedMethod !== this.getRecalculationMethod()
            )
        });
    }

    private getRecalculationMethod(): string | null | undefined {
        return this.route.firstChild?.firstChild?.snapshot.queryParamMap.get('method');
    }
}
