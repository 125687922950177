import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { ExternalToolUrlParams } from '@customer-apps/shared/interfaces';
import { ExternalTool } from '@customer-apps/shared/enums';
import { WindowService } from '@customer-apps/shared/services';
import { ProjectAccessStore } from '../state/project-access/project-access.store';

@Injectable({
    providedIn: 'root'
})
export class ExternalToolUrlService {
    constructor(
        private environment: EnvironmentService,
        private windowService: WindowService,
        private projectAccessStore: ProjectAccessStore
    ) {}

    public getExternalToolUrl(tool: ExternalTool, params: ExternalToolUrlParams, route: string = ''): string {
        const queryParams = this.buildQueryParams(params);
        const externalToolUrl = this.environment.getExternalUrl(tool);
        return `${externalToolUrl}${route}?${queryParams}`;
    }

    public redirectToExternalToolByProjectId(tool: ExternalTool, params: ExternalToolUrlParams, route: string = ''): void {
        this.projectAccessStore.skipProjectUnlockBeforeUnload();
        const url = this.getExternalToolUrl(tool, params, route);
        setTimeout(() => {
            this.windowService.nativeWindow.location.href = url;
        });
    }

    private buildQueryParams(params: ExternalToolUrlParams): string {
        return Object.keys(params)
            .map((key: string) => `${key}=${params[key as keyof ExternalToolUrlParams]}`)
            .join('&');
    }
}
