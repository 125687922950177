/**
 * Offset from the top used for scrolling purposes.
 * toolbar 64px + navbar 49px = offsetY 113px
 */
export const projectDetailsOffset = 113;
/**
 * Phrase key for the default name given to a variant at the moment of creation.
 */
export const defaultVariantNameKey = 'COMMON.VARIANT_NAMES.VARIANT';
/**
 * Phrase key for the name given to the first project's variant.
 */
export const firstVariantNameKey = 'COMMON.VARIANT_NAMES.VARIANT_1';
/**
 * Phrase key for the name given to the second project's variant.
 */
export const secondVariantNameKey = 'COMMON.VARIANT_NAMES.VARIANT_2';
/**
 * Phrase key for the name given to the third project's variant.
 */
export const thirdVariantNameKey = 'COMMON.VARIANT_NAMES.VARIANT_3';
