import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'vp-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusComponent {
    @Input() color: string;
    @Input() label: string;
}
