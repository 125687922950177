import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Message } from '@customer-apps/shared/interfaces';
import { WindowService } from '@customer-apps/shared/services';

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    private messages$: BehaviorSubject<Message[]> = new BehaviorSubject([] as Message[]);
    private highLevelMessages: Message[] = [];
    private lowLevelMessages: Message[] = [];

    constructor(private windowService: WindowService) {}

    public init(): void {
        this.messages$.next([]);
        this.highLevelMessages = [];
        this.lowLevelMessages = [];
    }

    public get messages(): Observable<Message[]> {
        return this.messages$.asObservable();
    }

    public add(message: Message, options?: { highLevel: boolean }): void {
        message = this.addUUID(message);
        options?.highLevel ? this.highLevelMessages.unshift(message) : this.lowLevelMessages.unshift(message);
        this.refreshMessages();
    }

    public remove(message: Message): void {
        this.highLevelMessages = this.highLevelMessages.filter(highLevelMessage => highLevelMessage.uuid !== message.uuid);
        this.lowLevelMessages = this.lowLevelMessages.filter(lowLevelMessage => lowLevelMessage.uuid !== message.uuid);
        this.refreshMessages();
    }

    private refreshMessages(): void {
        this.messages$.next([...this.highLevelMessages, ...this.lowLevelMessages]);
    }

    private addUUID(message: Message): Message {
        return { ...message, uuid: this.windowService.nativeWindow.crypto.randomUUID() };
    }
}
