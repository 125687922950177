import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'vp-company-tile',
    templateUrl: './company-tile.component.html',
    styleUrls: ['./company-tile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyTileComponent {
    @Input() partnerName: string;
    @Input() postalCode: string;
    @Input() street: string;
    @Input() city: string;
    @Input() partnerNumber: number;
    @Input() salesOrganization: string;
    @Input() salesChannel: string;
}
