import { Injectable } from '@angular/core';
import { HeatloadFormService } from './heatload-form.service';
import { TranslateService } from '@ngx-translate/core';
import { HeatloadStore } from '../../state/heatload/heatload.store';
import { HeatloadPrefillService } from './heatload-prefill.service';
import { map, withLatestFrom } from 'rxjs';
import { FormName, RoofType } from '@customer-apps/shared/enums';
import { AccordionService, RoofDimensionsFormValue } from '@customer-apps/shared/interfaces';
import { HeatloadFormOptionsService } from './heatload-form-options.service';
import { ProjectStore } from '../../state/project/project.store';
import { HeatloadPrefillMapper } from '../../services';
import { HeatloadService } from './heatload.service';

@Injectable({
    providedIn: 'root'
})
export class RoofDimensionsService implements AccordionService {
    constructor(
        private prefillMapper: HeatloadPrefillMapper,
        private heatloadPrefillService: HeatloadPrefillService,
        private heatloadFormOptionsService: HeatloadFormOptionsService,
        private heatloadStore: HeatloadStore,
        private heatloadService: HeatloadService,
        private translate: TranslateService,
        private projectStore: ProjectStore,
        private heatloadFormService: HeatloadFormService
    ) {}

    public questions$ = this.heatloadStore.roofType$.pipe(
        withLatestFrom(this.projectStore.project$, this.heatloadStore.cache$),
        map(([roofType, project, cache]) => ({
            roofType,
            project,
            cache
        })),
        map(state => {
            if (!state.roofType || this.isFlatRoofType(state.roofType.value)) {
                return null;
            }
            const projectPrefill = this.prefillMapper.projectToRoofDimensionsPrefill(state.project);
            const cachePrefill = this.prefillMapper.stateToRoofDimensionsPrefill(state.cache?.roofDimensions);
            const options = this.heatloadFormOptionsService.getRoofDimensionsFormOptions(
                cachePrefill || projectPrefill,
                state.roofType!.value as RoofType
            );

            return this.heatloadFormService.getRoofDimensionsQuestions(options);
        })
    );

    public isPrefillNeeded(): boolean {
        return this.heatloadService.isRecalculation() && !this.heatloadPrefillService.isPrefilled(FormName.RoofDimensions);
    }

    public getSuccessMessage(formValue: RoofDimensionsFormValue): string {
        const successMessage: string[] = [];
        Object.entries(formValue.roofDimensions).forEach(value => {
            successMessage.push(this.translate.instant('HEATLOAD.ROOF_DIMENSIONS.' + value[0].toUpperCase(), { value: value[1] }));
        });
        return successMessage.join(', ');
    }

    public afterPrefill(): void {
        this.heatloadPrefillService.setAsPrefilled(FormName.RoofDimensions);
    }

    public isExpanded$ = this.heatloadStore.sameValueSubmited$.pipe(
        map(form => form?.formName === FormName.RoofType && !this.isFlatRoofType(form?.value.roofType.value))
    );

    private isFlatRoofType(roofType: RoofType): boolean {
        return roofType === RoofType.Flat;
    }
}
