import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CalculationsControllerService } from '@customer-apps/api-planning-projects';
import { EnergySource, GEGSystemType, HeatGeneratorType, RoutePaths, RouterLinks } from '@customer-apps/shared/enums';
import { withLatestFrom, tap, map, EMPTY, switchMap, catchError, filter, merge } from 'rxjs';
import { HeatloadService } from '../../heatload/services/heatload.service';
import { ProjectStore } from '../project/project.store';
import { GEGActions } from './geg.actions';
import { GEGStore } from './geg.store';
import { GlobalSpinnerService } from '../../core';
import { GegCurrentSystemHeatGeneratorDetailsService } from '../../geg/services';

@Injectable()
export class GEGEffects {
    constructor(
        private globalSpinnerService: GlobalSpinnerService,
        private actions$: Actions,
        private projectStore: ProjectStore,
        private gegStore: GEGStore,
        private heatloadService: HeatloadService,
        private calculationsControllerService: CalculationsControllerService,
        private currentSystemHeatGeneratorDetails: GegCurrentSystemHeatGeneratorDetailsService,
        private router: Router
    ) {}

    public submitSystem$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(GEGActions.submitSystem),
                withLatestFrom(this.projectStore.project$, (action, project) => ({ action, project })),
                tap(({ action, project }) => {
                    if (action.system === GEGSystemType.New) {
                        this.router.navigate([RouterLinks.GEG, project!.id, RoutePaths.NewSystem]);
                        return;
                    }
                    this.router.navigate([RouterLinks.GEG, project!.id, RoutePaths.CurrentSystem]);
                })
            ),
        { dispatch: false }
    );

    public extendSystem$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(GEGActions.extendSystem),
                withLatestFrom(this.projectStore.project$, (action, project) => ({ action, project })),
                tap(({ project }) => {
                    this.router.navigate([RouterLinks.GEG, project!.id, RoutePaths.AddNewSystem]);
                })
            ),
        { dispatch: false }
    );

    public getHeatGenerators$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GEGActions.getHeatGenerators),
            switchMap(action =>
                this.heatloadService.getHeatGenerators(action.systemType).pipe(
                    map(generators => GEGActions.getHeatGeneratorsSuccess({ generators })),
                    catchError(() => EMPTY)
                )
            )
        )
    );

    public getHeatingSystemsForCurrentSystem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GEGActions.getHeatingSystemsForCurrentSystem),
            withLatestFrom(this.gegStore.currentSystemEnergySource$, (action, energySourceState) => ({ action, energySourceState })),
            switchMap(({ action, energySourceState }) =>
                this.heatloadService
                    .getHeatingSystems(
                        energySourceState!.selectedEnergySource!.type as EnergySource,
                        +action.installationYear,
                        energySourceState!.selectedHeatGenerator as HeatGeneratorType,
                        GEGSystemType.Current
                    )
                    .pipe(catchError(error => EMPTY))
            ),
            map(heatingSystems => GEGActions.getHeatingSystemsForCurrentSystemSuccess({ heatingSystems }))
        )
    );

    public submitNewSystemHeatGenerator$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GEGActions.submitNewSystemHeatGenerator),
            map(() => {
                const installationYear = `${new Date().getFullYear()}`;
                return GEGActions.getHeatingSystemForNewSystem({ installationYear });
            })
        )
    );

    public submitCurrentSystemHeatGeneratorDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GEGActions.submitCurrentSystemHeatGeneratorDetails),
            withLatestFrom(this.gegStore.system$, (_, system) => system!.value),
            filter(system => system === GEGSystemType.Current),
            map(() => GEGActions.evaluateGEG())
        )
    );

    public getHeatingSystemForNewSystem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GEGActions.getHeatingSystemForNewSystem),
            withLatestFrom(this.gegStore.state$, (action, gegState) => ({ action, gegState })),
            switchMap(({ action, gegState }) => {
                const { newSystem, system } = gegState;
                const energySource = newSystem!.energySource!.selectedEnergySource.type as EnergySource;
                const heatGeneratorType = newSystem!.energySource!.selectedHeatGenerator as HeatGeneratorType;
                const installationYear = +action.installationYear;
                return this.heatloadService.getHeatingSystems(energySource, installationYear, heatGeneratorType, system!.value).pipe(
                    switchMap(heatingSystems => [
                        GEGActions.getHeatingSystemForNewSystemSuccess({ heatingSystem: heatingSystems[0] }),
                        GEGActions.evaluateGEG()
                    ]),
                    catchError(() => EMPTY)
                );
            })
        )
    );

    public evaluateGEG$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GEGActions.evaluateGEG),
            tap(() => {
                setTimeout(() => {
                    this.globalSpinnerService.next({
                        isShown: true,
                        loadingText: 'LOADER.TEXT.GEG_CHECK'
                    });
                });
            }),
            withLatestFrom(this.gegStore.gegEvaluationRequest$.pipe(filter(Boolean)), (_, request) => ({ request })),
            switchMap(({ request }) =>
                this.calculationsControllerService.calculationsControllerGegEvaluation(request).pipe(
                    map(evaluation => GEGActions.evaluateGEGSuccess({ evaluation })),
                    catchError(() => EMPTY)
                )
            )
        )
    );

    public evaluateGEGSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(GEGActions.evaluateGEGSuccess),
                withLatestFrom(this.projectStore.project$, (action, project) => ({ action, project })),
                tap(({ project }) => {
                    this.router.navigate([RouterLinks.GEG, project!.id, RoutePaths.EvaluatedSystem]);
                })
            ),
        { dispatch: false }
    );

    public resetCurrentSystemHeatGeneratorDetailsPrefillState$ = createEffect(
        () =>
            merge(this.gegStore.currentSystemHeatGeneratorDetails$, this.gegStore.currentSystemEnergySource$.pipe(filter(Boolean))).pipe(
                tap(() => this.currentSystemHeatGeneratorDetails.toggleIsConstructionYearPrefillFinished(false))
            ),
        { dispatch: false }
    );
}
