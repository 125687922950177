import { animate, keyframes, style, transition, trigger } from '@angular/animations';

export const slideUp = trigger('slideUp', [
    transition(':leave', [
        animate(
            '300ms',
            keyframes([
                style({ height: '*', opacity: 1 }),
                style({ height: '*', opacity: 0.9 }),
                style({ height: '*', opacity: 0.7 }),
                style({ height: '*', opacity: 0.5 }),
                style({ height: '*', opacity: 0.3 }),
                style({ height: '*', opacity: 0.2 }),
                style({ height: 0, opacity: 0 })
            ])
        )
    ])
]);
