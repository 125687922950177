import { ValidatorFn } from '@angular/forms';
import { ControlTypes } from '@customer-apps/shared/enums';
import { QuestionGroupOptions } from '@customer-apps/shared/interfaces';

export class QuestionGroupBase<T> {
    public controlType: ControlTypes;
    public question: string;
    public description: string;
    public groupName: string;
    public order: number;
    public styles: string;
    public questions: T;
    public validators: ValidatorFn[];
    public descriptionList: string[] | undefined;
    public bottomDescription: string;
    public defaultErrorMessage: string;
    public img: string;
    public tooltip: string;

    constructor(options: QuestionGroupOptions<T>) {
        this.controlType = options.controlType || ControlTypes.MultiTextbox;
        this.groupName = options.groupName;
        this.order = options.order || 1;
        this.questions = options.questions;
        this.question = options.question || '';
        this.description = options.description || '';
        this.styles = options.styles || '';
        this.validators = options.validators || [];
        this.descriptionList = options.descriptionList;
        this.bottomDescription = options.bottomDescription || '';
        this.img = options.img || '';
        this.defaultErrorMessage = options.defaultErrorMessage || '';
        this.tooltip = options.tooltip || '';
    }
}
