export class LocalStorageSubstitute {
    private static items: any = {};

    public get length(): number {
        return Object.keys(LocalStorageSubstitute.items).length;
    }

    public getItem(key: string): any {
        return LocalStorageSubstitute.items[key];
    }

    public setItem(key: string, value: any): void {
        return (LocalStorageSubstitute.items[key] = value);
    }

    public removeItem(key: string): void {
        delete LocalStorageSubstitute.items[key];
    }

    public clear(): void {
        for (const prop of Object.keys(LocalStorageSubstitute.items)) {
            delete LocalStorageSubstitute.items[prop];
        }
    }

    public key(index: number): any {
        return Object.keys(LocalStorageSubstitute.items)[index];
    }
}
