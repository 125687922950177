import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
    selector: 'vp-dialog-header',
    templateUrl: './dialog-header.component.html',
    styleUrl: './dialog-header.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogHeaderComponent {
    @Input() title?: string;
    @Input() titleElement?: TemplateRef<any>;
    @Input() context: any;
    @Output() close: EventEmitter<void> = new EventEmitter();
}
