import { Injectable } from '@angular/core';
import { LocaleService } from './locale.service';
import { CurrencyPipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class CurrencyService {
    private pipe: CurrencyPipe;

    constructor(public localeService: LocaleService) {
        this.pipe = new CurrencyPipe(this.localeService.locale!, this.localeService.getCurrencyCode()!);
    }
    public transform(value: number | undefined): string | null {
        return this.pipe.transform(value, undefined, 'symbol-narrow', '1.0-2');
    }
}
