export enum RoutePaths {
    // Projects
    Projects = 'projects',
    NewProject = 'new-project',
    ProjectDetails = 'project/:id',
    EditProject = 'project/:id/edit',
    VerifyBasicInformation = 'project/:id/verify-basic-information',
    EditEnergyPrices = 'project/:id/edit-prices',
    VerifyEnergyPrices = 'project/:id/verify-prices',
    PdfReport = 'project/:id/pdf-report',
    CreateReport = 'project/:id/create-report',

    //Errors
    Error403 = 'errors/403',
    Error404 = 'errors/404',
    ErrorNoAccess = 'errors/no-access', // no Sales Force access page

    // GEG
    GEG = 'geg/:id',
    SystemChoice = 'system-choice',
    CurrentSystem = 'current-system',
    NewSystem = 'new-system',
    AddNewSystem = 'add-new-system',
    EvaluatedSystem = 'evaluated-system',
    AlternativeSystem = 'alternative-system',
    ReviewSystem = 'review-system/:configurationIdx',

    //
    Heatload = 'project/:id/heatload',
    HeatPumpIntermediary = 'project/:id/plan-heat-pump',
    PhotovoltaicIntermediary = 'project/:id/plan-photovoltaic',
    VariantDetails = 'project/:id/variants/:variantId',
    LandingPage = ''
}
