import { Injectable } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
    BuildingType,
    ConsumptionProfile,
    EnergySource,
    FormKeys,
    HeatGeneratorType,
    HeatingDistributionMethod,
    HeatingSystemType,
    HeatPumpOperatingMode
} from '@customer-apps/shared/enums';
import {
    EnergyConsumptionOptions,
    FormOptions,
    HeatGeneratorDetailsOptions,
    Mask,
    QuestionOverride,
    RadioQuestionOverride
} from '@customer-apps/shared/interfaces';
import {
    CheckboxQuestion,
    CheckboxSet,
    EnumDictionary,
    RadioQuestion,
    TextboxQuestion,
    energySourceToPhraseKey,
    heatGeneratorToIconName,
    heatGeneratorToPhraseKey
} from '@customer-apps/shared/utils';
import { ValidatorsService } from './validators.service';
import { FormToolsService } from './form-tools.service';
import { SystemItem } from '@customer-apps/api-planning-projects';
import { LodashService } from '@customer-apps/shared/services';

@Injectable({
    providedIn: 'root'
})
export class CommonFormService {
    constructor(
        private validatorsService: ValidatorsService,
        private formToolsService: FormToolsService,
        private translateService: TranslateService
    ) {}

    public getHeatGenerator(
        override: RadioQuestionOverride,
        options?: { heatGeneratorDictionary?: EnumDictionary<HeatGeneratorType, string> }
    ): RadioQuestion {
        const selectedHeatGeneratorDictionary = options?.heatGeneratorDictionary || heatGeneratorToPhraseKey;
        const heatGeneratorTypes: HeatGeneratorType[] = override.options;
        const questionOptions: { key: string; value: string; icon: string }[] = heatGeneratorTypes.map(key => ({
            key: selectedHeatGeneratorDictionary[key],
            value: key,
            icon: heatGeneratorToIconName[key]
        }));

        return new RadioQuestion({
            question: 'HEATLOAD.ENERGY_SOURCE.HEAT_GENERATOR.TITLE',
            order: 1,
            key: FormKeys.HeatGenerator,
            styles: 'vp-radio-columns',
            defaultErrorMessage: 'HEATLOAD.ENERGY_SOURCE.HEAT_GENERATOR.ERRORS.REQUIRED',
            validators: [Validators.required],
            options: questionOptions,
            value: override?.value
        });
    }

    public getBoilerType(override: RadioQuestionOverride): RadioQuestion {
        const heatingSystemTypes: HeatingSystemType[] = override.options;
        let value = heatingSystemTypes.length === 1 ? heatingSystemTypes[0] : '';
        if (override.value) {
            value = override.value;
        }

        const questionOptions: { key: string; value: string }[] = heatingSystemTypes.map(key => ({
            key: `HEATLOAD.HEAT_GENERATOR_DETAILS.BOILER_TYPE.${key}`,
            value: key
        }));
        return new RadioQuestion({
            order: 2,
            question: 'HEATLOAD.HEAT_GENERATOR_DETAILS.BOILER_TYPE.TITLE',
            key: FormKeys.BoilerType,
            styles: 'vp-radio-columns',
            description: 'HEATLOAD.HEAT_GENERATOR_DETAILS.BOILER_TYPE.DESCRIPTION',
            defaultErrorMessage: 'HEATLOAD.HEAT_GENERATOR_DETAILS.BOILER_TYPE.ERRORS.REQUIRED',
            validators: [Validators.required],
            options: questionOptions,
            value,
            hidden: heatingSystemTypes.length === 1
        });
    }

    public getSpecificEnergySource(override: RadioQuestionOverride): RadioQuestion {
        const energySources: EnergySource[] = override.options;
        const questionOptions: { key: string; value: string }[] = energySources.map(key => ({
            key: energySourceToPhraseKey[key] || '',
            value: key
        }));

        return new RadioQuestion({
            value: override?.value || '',
            order: 2,
            question: 'HEATLOAD.ENERGY_SOURCE.SPECIFIC_SOURCE.TITLE',
            key: FormKeys.EnergySource,
            styles: 'vp-radio-single-column',
            defaultErrorMessage: 'HEATLOAD.ENERGY_SOURCE.SPECIFIC_SOURCE.ERRORS.REQUIRED',
            validators: [Validators.required],
            options: questionOptions
        });
    }

    public getInstallationYear(options?: HeatGeneratorDetailsOptions): TextboxQuestion {
        const minYear = 1970;
        const maxYear = new Date().getFullYear();
        const minMaxOptions = { min: minYear, max: maxYear };
        const ifConditionalHint = this.formToolsService.hasHeatGeneratorInstallationYearHint(
            options?.questions?.[FormKeys.InstallationYear]?.value,
            options?.buildingConstructionYear
        );

        return new TextboxQuestion({
            value: options?.questions?.[FormKeys.InstallationYear]?.value,
            order: 1,
            question: 'HEATLOAD.HEAT_GENERATOR_DETAILS.INSTALLATION_YEAR.TITLE',
            label: 'HEATLOAD.HEAT_GENERATOR_DETAILS.INSTALLATION_YEAR.LABEL',
            description: 'HEATLOAD.HEAT_GENERATOR_DETAILS.INSTALLATION_YEAR.DESCRIPTION',
            key: FormKeys.InstallationYear,
            defaultErrorMessage: 'HEATLOAD.HEAT_GENERATOR_DETAILS.INSTALLATION_YEAR.ERRORS.REQUIRED',
            validators: [Validators.required, this.validatorsService.minMaxValidator(minMaxOptions)],
            mask: { pattern: '0000' },
            styles: ifConditionalHint ? 'mb-3' : '',
            ifConditionalHint,
            conditionalHint: 'HEATLOAD.HEAT_GENERATOR_DETAILS.INSTALLATION_YEAR.HINT'
        });
    }

    public getBoilerTypeSubquestion(options: HeatGeneratorDetailsOptions): CheckboxSet {
        const { selectedInstallationType } = options;
        const questions: CheckboxQuestion[] = [];
        if (selectedInstallationType?.isLowTemperature) {
            const lowTemperatureOverride = options.questions?.[FormKeys.LowTemperature];
            const lowTemperature = new CheckboxQuestion({
                key: FormKeys.LowTemperature,
                hidden: !selectedInstallationType?.isLowTemperatureOption,
                label: 'HEATLOAD.HEAT_GENERATOR_DETAILS.BOILER_TYPE_SUBQUESTION.LOW_TEMPERATURE',
                value: this.getLowTemperatureValue(selectedInstallationType!, lowTemperatureOverride)
            });
            questions.push(lowTemperature);
        }
        if (selectedInstallationType!.warmWaterByHeatGenerator) {
            const warmWaterByHeatGeneratorOverride = options.questions?.[FormKeys.WarmWaterByHeatGenerator];
            const warmWaterByHeatGenerator = new CheckboxQuestion({
                key: FormKeys.WarmWaterByHeatGenerator,
                hidden: !selectedInstallationType?.warmWaterByHeatGeneratorOption,
                label: 'HEATLOAD.HEAT_GENERATOR_DETAILS.BOILER_TYPE_SUBQUESTION.WARM_WATER_BY_HEAT_GENERATOR',
                value: this.getWarmWaterByHeatGeneratorValue(selectedInstallationType!, warmWaterByHeatGeneratorOverride)
            });
            questions.push(warmWaterByHeatGenerator);
        }
        return new CheckboxSet({
            groupName: FormKeys.BoilerTypeSubquestion,
            order: 3,
            styles: 'd-flex flex-column vp-negative-margin-bottom-16 vp-negative-margin-top-16',
            questions
        });
    }

    public getNominalPower(options: FormOptions): TextboxQuestion {
        if (!options.buildingType) {
            throw Error('Please specify buildingType for calculation method question.');
        }

        const minMaxSettings = {
            [BuildingType.SingleFamilyHouse]: this.validatorsService.minMaxValidator({ min: 3, max: 50 }),
            [BuildingType.MultiFamilyHouse]: this.validatorsService.minMaxValidator({ min: 15, max: 1000 })
        };

        const selectedMinMaxValidator = minMaxSettings[options.buildingType];

        return new TextboxQuestion({
            question: 'HEATLOAD.HEAT_GENERATOR_DETAILS.NOMINAL_POWER.TITLE',
            order: 5,
            label: 'HEATLOAD.HEAT_GENERATOR_DETAILS.NOMINAL_POWER.LABEL',
            key: FormKeys.NominalPower,
            defaultErrorMessage: 'HEATLOAD.HEAT_GENERATOR_DETAILS.NOMINAL_POWER.ERRORS.REQUIRED',
            validators: [Validators.required, selectedMinMaxValidator],
            mask: this.formToolsService.getNumericMask({ separatorLimit: '100' }),
            suffix: 'MEASUREMENT_UNITS.KW',
            value: options?.questions?.[FormKeys.NominalPower]?.value
        });
    }

    public gasLossExhaustKnown(options?: HeatGeneratorDetailsOptions): CheckboxSet {
        const isKnownOverride = options?.questions?.[FormKeys.IsKnown];
        const isKnown = new CheckboxQuestion({
            key: FormKeys.IsKnown,
            label: 'HEATLOAD.HEAT_GENERATOR_DETAILS.GAS_LOSS_EXHAUST_KNOWN.LABEL',
            value: isKnownOverride?.value || false
        });

        return new CheckboxSet({
            groupName: FormKeys.GasLossExhaustKnown,
            order: 6,
            styles: 'vp-negative-margin-bottom-16',
            questions: [isKnown]
        });
    }

    public getGasLossExhaust(options?: FormOptions): TextboxQuestion {
        return new TextboxQuestion({
            label: 'HEATLOAD.HEAT_GENERATOR_DETAILS.GAS_LOSS_EXHAUST.LABEL',
            order: 7,
            key: FormKeys.GasLossExhaust,
            defaultErrorMessage: 'HEATLOAD.HEAT_GENERATOR_DETAILS.GAS_LOSS_EXHAUST.ERRORS.REQUIRED',
            validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 3, max: 15 })],
            mask: this.formToolsService.getNumericMask({ separatorLimit: '10' }),
            suffix: 'MEASUREMENT_UNITS.PERCENT',
            value: options?.questions?.[FormKeys.GasLossExhaust]?.value
        });
    }

    public getEnergyRequiredPerYear(options: EnergyConsumptionOptions): TextboxQuestion {
        const source = this.translateService.instant(`HEATLOAD.ENERGY_SOURCE.SOURCES.${options.selectedSource!.toUpperCase()}`);
        const unit = this.translateService.instant(`MEASUREMENT_UNITS.${options.selectedUnit!.toUpperCase()}`);
        const description = this.translateService.instant('HEATLOAD.ENERGY_CONSUMPTION.REQUIRED_ENERGY.DESCRIPTION', {
            source,
            unit
        });
        const settings = this.energyRequiredPeryearSettings;

        if (!options.buildingType) {
            throw Error('Please specify buildingType for energyRequiredPerYear question.');
        }

        const selectedSettings = settings[options.buildingType][options.selectedSource!];

        return new TextboxQuestion({
            question: 'HEATLOAD.ENERGY_CONSUMPTION.REQUIRED_ENERGY.TITLE',
            label: 'HEATLOAD.ENERGY_CONSUMPTION.REQUIRED_ENERGY.LABEL',
            description,
            key: FormKeys.RequiredEnergyPerYear,
            defaultErrorMessage: 'HEATLOAD.ENERGY_CONSUMPTION.REQUIRED_ENERGY.ERRORS.REQUIRED',
            validators: [Validators.required, selectedSettings.minMaxValidator],
            mask: selectedSettings.mask,
            suffix: unit,
            value: options?.questions?.[FormKeys.RequiredEnergyPerYear]?.value
        });
    }

    public nonHeatingElectricityConsumption(options: FormOptions): TextboxQuestion {
        if (!options.buildingType) {
            throw Error('Please specify buildingType for nonHeatingElectricityConsumption question.');
        }

        const settings = {
            [BuildingType.SingleFamilyHouse]: {
                minMaxValidator: this.validatorsService.minMaxValidator({ min: 1000, max: 15000 }),
                mask: this.formToolsService.getNumericMask({ separatorLimit: '10000' })
            },

            [BuildingType.MultiFamilyHouse]: {
                minMaxValidator: this.validatorsService.minMaxValidator({ min: 3000, max: 999999 }),
                mask: this.formToolsService.getNumericMask({ separatorLimit: '100000' })
            }
        };

        const selectedSettings = settings[options.buildingType];

        return new TextboxQuestion({
            question: 'HEATLOAD.ENERGY_CONSUMPTION.NON_HEATING_ELECTRICITY.TITLE',
            label: 'HEATLOAD.ENERGY_CONSUMPTION.NON_HEATING_ELECTRICITY.LABEL',
            description: 'HEATLOAD.ENERGY_CONSUMPTION.NON_HEATING_ELECTRICITY.DESCRIPTION',
            key: FormKeys.NonHeatingElectricityPerYear,
            defaultErrorMessage: 'HEATLOAD.ENERGY_CONSUMPTION.NON_HEATING_ELECTRICITY.ERRORS.REQUIRED',
            validators: [Validators.required, selectedSettings.minMaxValidator],
            mask: selectedSettings.mask,
            suffix: 'MEASUREMENT_UNITS.KWH',
            value: options?.questions?.[FormKeys.NonHeatingElectricityPerYear]?.value
        });
    }

    public getHeatingFlowTemperature(options?: FormOptions): TextboxQuestion {
        return new TextboxQuestion({
            question: 'HEATLOAD.HEATING_DISTRIBUTION.TEMPERATURE.TITLE',
            label: 'HEATLOAD.HEATING_DISTRIBUTION.TEMPERATURE.LABEL',
            description: 'HEATLOAD.HEATING_DISTRIBUTION.TEMPERATURE.DESCRIPTION',
            key: FormKeys.HeatingFlowTemperature,
            defaultErrorMessage: 'HEATLOAD.HEATING_DISTRIBUTION.TEMPERATURE.ERRORS.REQUIRED',
            validators: [Validators.required],
            mask: this.formToolsService.getNumericMask({ separatorLimit: '10' }),
            suffix: 'MEASUREMENT_UNITS.DEGREE',
            value: options?.questions?.[FormKeys.HeatingFlowTemperature]?.value
        });
    }

    public getHeatingSchedule(override: RadioQuestionOverride): RadioQuestion {
        const consumptionProfiles: ConsumptionProfile[] = override.options;
        let value = consumptionProfiles.length === 1 ? consumptionProfiles[0] : '';
        if (override.value) {
            value = override.value;
        }

        const questionOptions: { key: string; value: string }[] = consumptionProfiles.map(key => ({
            key: `HEATLOAD.HEATING_DISTRIBUTION.SCHEDULE.${key}`,
            subtext: `HEATLOAD.HEATING_DISTRIBUTION.SCHEDULE.${key}_SUBTEXT`,
            value: key
        }));

        return new RadioQuestion({
            question: 'HEATLOAD.HEATING_DISTRIBUTION.SCHEDULE.TITLE',
            key: FormKeys.HeatingSchedule,
            styles: 'vp-radio-columns',
            description: 'HEATLOAD.HEATING_DISTRIBUTION.SCHEDULE.DESCRIPTION',
            defaultErrorMessage: 'HEATLOAD.HEATING_DISTRIBUTION.SCHEDULE.ERRORS.REQUIRED',
            validators: [Validators.required],
            options: questionOptions,
            value,
            hidden: consumptionProfiles.length === 1
        });
    }

    public getBuildingConstructionYear(options?: FormOptions): TextboxQuestion {
        return new TextboxQuestion({
            question: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.BUILDING.TITLE',
            key: FormKeys.BuildingConstructionYear,
            styles: 'vp-form-field-thin vp-form-field-w-65 with-wrapped-error-message-350',
            defaultErrorMessage: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.BUILDING.ERRORS.REQUIRED',
            paragraph: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.BUILDING.PARAGRAPH',
            placeholder: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.BUILDING.PLACEHOLDER',
            validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 1950, max: new Date().getFullYear() })],
            mask: { pattern: '0000' },
            value: options?.questions?.[FormKeys.BuildingConstructionYear]?.value
        });
    }

    public getDistributionMethod(options?: FormOptions): RadioQuestion {
        return new RadioQuestion({
            question: 'HEATLOAD.HEATING_DISTRIBUTION.METHOD.TITLE',
            key: FormKeys.DistributionMethod,
            styles: 'vp-radio-columns',
            description: 'HEATLOAD.HEATING_DISTRIBUTION.METHOD.DESCRIPTION',
            defaultErrorMessage: 'HEATLOAD.HEATING_DISTRIBUTION.METHOD.ERRORS.REQUIRED',
            validators: [Validators.required],
            options: [
                {
                    key: 'HEATLOAD.HEATING_DISTRIBUTION.METHOD.RADIATOR',
                    value: HeatingDistributionMethod.Radiator,
                    icon: 'radiator-heating'
                },
                {
                    key: 'HEATLOAD.HEATING_DISTRIBUTION.METHOD.UNDERFLOOR_HEATING',
                    value: HeatingDistributionMethod.Underfloor,
                    icon: 'floor-heating'
                },
                {
                    key: 'HEATLOAD.HEATING_DISTRIBUTION.METHOD.RADIATOR_AND_UNDERFLOOR_HEATING',
                    value: HeatingDistributionMethod.RadiatorAndUnderfloor,
                    icon: 'radiator-and-underfloor'
                }
            ],
            value: options?.questions?.[FormKeys.DistributionMethod]?.value
        });
    }

    public getHeatPumpOperatingMode(override: RadioQuestionOverride): RadioQuestion {
        const heatPumpOperatingModes: HeatPumpOperatingMode[] = override.options;
        const questionOptions: { key: string; value: string }[] = heatPumpOperatingModes.map(key => ({
            key: `HEATLOAD.HEAT_GENERATOR_DETAILS.HEAT_PUMP_OPERATING_MODE.${key}`,
            value: key
        }));
        return new RadioQuestion({
            order: 4,
            question: 'HEATLOAD.HEAT_GENERATOR_DETAILS.HEAT_PUMP_OPERATING_MODE.TITLE',
            key: FormKeys.HeatPumpOperatingMode,
            styles: 'vp-radio-columns',
            defaultErrorMessage: 'HEATLOAD.HEAT_GENERATOR_DETAILS.HEAT_PUMP_OPERATING_MODE.ERRORS.REQUIRED',
            validators: [Validators.required],
            options: questionOptions,
            value: override.value
        });
    }

    private get energyRequiredPeryearSettings(): {
        [key: string]: { [key: string]: { minMaxValidator: ValidatorFn; mask: Mask } };
    } {
        return {
            [BuildingType.SingleFamilyHouse]: {
                [EnergySource.Electricity]: {
                    minMaxValidator: this.validatorsService.minMaxValidator({
                        min: 999,
                        max: 99999
                    }),
                    mask: this.formToolsService.getNumericMask({ separatorLimit: '10000', numberOfDecimals: '2' })
                },
                [EnergySource.HeatPumpElectricity]: {
                    minMaxValidator: this.validatorsService.minMaxValidator({
                        min: 999,
                        max: 99999
                    }),
                    mask: this.formToolsService.getNumericMask({ separatorLimit: '10000', numberOfDecimals: '2' })
                },
                [EnergySource.HeatingOil]: {
                    minMaxValidator: this.validatorsService.minMaxValidator({
                        min: 99,
                        max: 9999
                    }),
                    mask: this.formToolsService.getNumericMask({ separatorLimit: '1000', numberOfDecimals: '2' })
                },
                [EnergySource.Gas]: {
                    minMaxValidator: this.validatorsService.minMaxValidator({
                        min: 999,
                        max: 99999
                    }),
                    mask: this.formToolsService.getNumericMask({ separatorLimit: '10000', numberOfDecimals: '2' })
                },
                [EnergySource.Hardwood]: {
                    minMaxValidator: this.validatorsService.minMaxValidator({
                        min: 5,
                        max: 999
                    }),
                    mask: this.formToolsService.getNumericMask({ separatorLimit: '100', numberOfDecimals: '2' })
                },
                [EnergySource.Softwood]: {
                    minMaxValidator: this.validatorsService.minMaxValidator({
                        min: 5,
                        max: 999
                    }),
                    mask: this.formToolsService.getNumericMask({ separatorLimit: '100', numberOfDecimals: '2' })
                },
                [EnergySource.Pellet]: {
                    minMaxValidator: this.validatorsService.minMaxValidator({
                        min: 1,
                        max: 25
                    }),
                    mask: this.formToolsService.getNumericMask({ separatorLimit: '10', numberOfDecimals: '2' })
                }
            },
            [BuildingType.MultiFamilyHouse]: {
                [EnergySource.Electricity]: {
                    minMaxValidator: this.validatorsService.minMaxValidator({
                        min: 3000,
                        max: 999999
                    }),
                    mask: this.formToolsService.getNumericMask({ separatorLimit: '100000', numberOfDecimals: '2' })
                },
                [EnergySource.HeatPumpElectricity]: {
                    minMaxValidator: this.validatorsService.minMaxValidator({
                        min: 999,
                        max: 999999
                    }),
                    mask: this.formToolsService.getNumericMask({ separatorLimit: '100000', numberOfDecimals: '2' })
                },
                [EnergySource.HeatingOil]: {
                    minMaxValidator: this.validatorsService.minMaxValidator({
                        min: 300,
                        max: 99999
                    }),
                    mask: this.formToolsService.getNumericMask({ separatorLimit: '10000', numberOfDecimals: '2' })
                },
                [EnergySource.Gas]: {
                    minMaxValidator: this.validatorsService.minMaxValidator({
                        min: 3000,
                        max: 999999
                    }),
                    mask: this.formToolsService.getNumericMask({ separatorLimit: '100000', numberOfDecimals: '2' })
                },
                [EnergySource.Hardwood]: {
                    minMaxValidator: this.validatorsService.minMaxValidator({
                        min: 15,
                        max: 9999
                    }),
                    mask: this.formToolsService.getNumericMask({ separatorLimit: '1000', numberOfDecimals: '2' })
                },
                [EnergySource.Softwood]: {
                    minMaxValidator: this.validatorsService.minMaxValidator({
                        min: 15,
                        max: 9999
                    }),
                    mask: this.formToolsService.getNumericMask({ separatorLimit: '1000', numberOfDecimals: '2' })
                },
                [EnergySource.Pellet]: {
                    minMaxValidator: this.validatorsService.minMaxValidator({
                        min: 10,
                        max: 250
                    }),
                    mask: this.formToolsService.getNumericMask({ separatorLimit: '100', numberOfDecimals: '2' })
                }
            }
        };
    }

    /**
     * When warm water by heat generator is not selectable or the override is not specified then we prefill the question with the value from the selectedInstallation.
     * Otherwise we prefill the question value with one from the override (project data).
     * @param selectedInstallationType Value computed from request response and user selection/input.
     * @param override Question settings override.
     * @returns Question prefill value.
     */
    private getWarmWaterByHeatGeneratorValue(selectedInstallationType: SystemItem, override?: QuestionOverride): boolean {
        if (!selectedInstallationType?.warmWaterByHeatGeneratorOption || LodashService.isNil(override?.value)) {
            return selectedInstallationType.warmWaterByHeatGenerator!;
        }
        return override?.value;
    }

    /**
     * When low temparture is not selectable or the override is not specified then we prefill the question with the value from the selectedInstallation.
     * Otherwise we prefill the question value with one from the override (project data).
     * @param selectedInstallationType Value computed from request response and user selection/input.
     * @param override Question settings override.
     * @returns Question prefill value.
     */
    private getLowTemperatureValue(selectedInstallationType: SystemItem, override?: QuestionOverride): boolean {
        if (!selectedInstallationType?.isLowTemperatureOption || LodashService.isNil(override?.value)) {
            return selectedInstallationType.isLowTemperature!;
        }
        return override?.value;
    }
}
