<div class="navbar-container d-flex align-items-center justify-content-between ps-2 pe-4">
    <ng-content select="[backlink]"></ng-content>
    <div class="d-none d-md-flex">
        <ng-container *ngFor="let anchor of anchors">
            <div
                (click)="anchorClick.emit(anchor)"
                class="navbar-anchor vp-border-box vp-pointer d-flex align-items-center ps-3 pe-3"
                [class.active]="anchor.id === activeAnchorId"
            >
                <span class="title title-lg">{{ anchor.title | translate }}</span>
                <span class="title title-sm">{{ anchor.titleSmall | translate }}</span>
            </div>
        </ng-container>
    </div>
    <ng-content select="[clipboard-button]"></ng-content>
</div>
