import { Injectable } from '@angular/core';
import { first, forkJoin, map, Observable, switchMap, take, tap, catchError, EMPTY, filter } from 'rxjs';
import {
    BuildingPartial,
    CalculationsControllerService,
    CurrentHeatingSystemPartial,
    HeatDemandCalculationRequestPost,
    HeatDemandCalculationResponse,
    HeatLoadCalculationGetById,
    HeatPumpToolsRequestPost,
    HeatPumpToolsResponse,
    ParamsControllerService,
    ProjectResponseGetById,
    UpdateProjectRequestPartial
} from '@customer-apps/api-planning-projects';
import { LodashService } from '@customer-apps/shared/services';
import { BuildingType, CountryCode, FormKeys, HeatGeneratorType, ProjectType } from '@customer-apps/shared/enums';
import { AppState } from '@customer-apps/shared/interfaces';
import { GlobalSpinnerService } from '../../core';
import { GlobalStore } from '../../state/global/global.store';
import { ProjectStore } from '../../state/project/project.store';
import { ProjectsService } from '../../services';
import { DEFAULT_EXHAUST_GAS_LOSSES } from '../../shared/utils/constants';

@Injectable({
    providedIn: 'root'
})
export class HeatPumpIntermediaryService {
    constructor(
        private paramsControllerService: ParamsControllerService,
        private calculationsControllerService: CalculationsControllerService,
        private globalSpinnerService: GlobalSpinnerService,
        private projectStore: ProjectStore,
        private projectsService: ProjectsService,
        private globalStore: GlobalStore
    ) {}

    public isSubsidyPossible(project: ProjectResponseGetById): boolean {
        return project.countryCode === CountryCode.DE;
    }

    public saveHeatPumpIntermediary(options: { migration?: boolean; variantId: string }): Observable<{
        variantId: string;
        request: UpdateProjectRequestPartial;
    }> {
        const intermediaryPatch$ = this.globalStore.state$.pipe(
            map(state => this.stateToHeatPumpIntermediary(state)),
            map(patch => ({ ...patch, variantId: options.variantId })),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
                });
            }),
            take(1)
        );

        if (options?.migration) {
            return this.saveIntermediaryForMigration(intermediaryPatch$);
        }

        return this.saveIntermediaryWithHeatDemand(intermediaryPatch$);
    }

    private saveIntermediaryForMigration(
        intermediaryPatch$: Observable<{
            variantId: string;
            request: {
                building: BuildingPartial;
            };
        }>
    ): Observable<{
        variantId: string;
        request: UpdateProjectRequestPartial;
    }> {
        return forkJoin([intermediaryPatch$, this.postHeatPumpToolsParams()]).pipe(
            map(
                ([projectPatch, heatPumpToolsParams]: [
                    { variantId: string; request: UpdateProjectRequestPartial },
                    HeatPumpToolsResponse
                ]) => {
                    const buildingPatch = projectPatch.request.building;
                    projectPatch.request.building = {
                        ...buildingPatch,
                        thermalCapacity: heatPumpToolsParams.thermalCapacity,
                        heatingLimitTemperature: heatPumpToolsParams.heatingLimitTemperature,
                        outsideTemperature: heatPumpToolsParams.outsideTemperature,
                        currentHeatingSystem: {
                            ...buildingPatch?.currentHeatingSystem,
                            annualHeatingCosts: heatPumpToolsParams.annualHeatingCosts,
                            annualElectricityCosts: heatPumpToolsParams.annualElectricityCosts,
                            maxFlowTemp: heatPumpToolsParams.maxFlowTemp,
                            minFlowTemp: heatPumpToolsParams.minFlowTemp,
                            electricPerformance: heatPumpToolsParams.electricPerformance,
                            efficiencyHeating: heatPumpToolsParams.efficiencyHeating,
                            efficiencyWarmWater: heatPumpToolsParams.efficiencyWarmWater,
                            electricityConsumptionForHeating: heatPumpToolsParams.electricityConsumptionForHeating,
                            electricityConsumptionForWarmWater: heatPumpToolsParams.electricityConsumptionForWarmWater,
                            standbyHeatLoss: heatPumpToolsParams.standbyHeatLoss,
                            co2EmissionTotal: heatPumpToolsParams.co2EmissionTotal,
                            co2EmissionForElectricity: heatPumpToolsParams.co2EmissionForElectricity,
                            co2EmissionForHeating: heatPumpToolsParams.co2EmissionForHeating
                        }
                    };

                    return projectPatch;
                }
            ),
            tap(projectPatch => {
                this.projectStore.saveHeatPumpIntermediary(projectPatch);
            })
        );
    }

    private saveIntermediaryWithHeatDemand(
        intermediaryPatch$: Observable<{
            variantId: string;
            request: {
                building: BuildingPartial;
            };
        }>
    ): Observable<{
        variantId: string;
        request: UpdateProjectRequestPartial;
    }> {
        return forkJoin([intermediaryPatch$, this.getHeatloadCalculationsWithHeatDemand(), this.postHeatPumpToolsParams()]).pipe(
            map(
                ([projectPatch, heatloadCalculations, heatPumpToolsParams]: [
                    { variantId: string; request: UpdateProjectRequestPartial },
                    HeatLoadCalculationGetById[],
                    HeatPumpToolsResponse
                ]) => {
                    const buildingPatch = projectPatch.request.building;
                    projectPatch.request.building = {
                        ...buildingPatch,
                        heatLoadCalculations: heatloadCalculations,
                        thermalCapacity: heatPumpToolsParams.thermalCapacity,
                        heatingLimitTemperature: heatPumpToolsParams.heatingLimitTemperature,
                        outsideTemperature: heatPumpToolsParams.outsideTemperature,
                        currentHeatingSystem: {
                            ...buildingPatch?.currentHeatingSystem,
                            annualHeatingCosts: heatPumpToolsParams.annualHeatingCosts,
                            annualElectricityCosts: heatPumpToolsParams.annualElectricityCosts,
                            maxFlowTemp: heatPumpToolsParams.maxFlowTemp,
                            minFlowTemp: heatPumpToolsParams.minFlowTemp,
                            electricPerformance: heatPumpToolsParams.electricPerformance,
                            efficiencyHeating: heatPumpToolsParams.efficiencyHeating,
                            efficiencyWarmWater: heatPumpToolsParams.efficiencyWarmWater,
                            electricityConsumptionForHeating: heatPumpToolsParams.electricityConsumptionForHeating,
                            electricityConsumptionForWarmWater: heatPumpToolsParams.electricityConsumptionForWarmWater,
                            standbyHeatLoss: heatPumpToolsParams.standbyHeatLoss,
                            co2EmissionTotal: heatPumpToolsParams.co2EmissionTotal,
                            co2EmissionForElectricity: heatPumpToolsParams.co2EmissionForElectricity,
                            co2EmissionForHeating: heatPumpToolsParams.co2EmissionForHeating
                        }
                    };

                    return projectPatch;
                }
            ),
            tap(projectPatch => {
                this.projectStore.saveHeatPumpIntermediary(projectPatch);
            })
        );
    }

    private stateToHeatPumpIntermediary(state: AppState) {
        const {
            project: { selectedProject },
            heatPumpIntermediary: {
                building,
                livingArea,
                heatingDistribution,
                heatingEnergySource,
                energyConsumption,
                heatGeneratorDetails,
                isOldDeviceUsed,
                subsidyEligibility
            }
        } = state;

        const currentHeatingSystem: CurrentHeatingSystemPartial = {};

        if (heatGeneratorDetails?.[FormKeys.BoilerType]) {
            currentHeatingSystem.heatingSystemType = heatGeneratorDetails[FormKeys.BoilerType];
        }
        if (heatingEnergySource?.selectedEnergySource) {
            currentHeatingSystem.energySource = heatingEnergySource.selectedEnergySource.type;
        }
        if (heatGeneratorDetails?.[FormKeys.InstallationYear]) {
            currentHeatingSystem.installationConstructionYear = +heatGeneratorDetails[FormKeys.InstallationYear]!;
        }
        if (heatGeneratorDetails?.[FormKeys.LowTemperature]) {
            currentHeatingSystem.isLowTemperature = heatGeneratorDetails?.[FormKeys.LowTemperature];
        }
        if (heatGeneratorDetails?.[FormKeys.NominalPower]) {
            currentHeatingSystem.heatGeneratorPower = heatGeneratorDetails[FormKeys.NominalPower];
        }
        if (energyConsumption?.[FormKeys.RequiredEnergyPerYear]) {
            currentHeatingSystem.consumption = energyConsumption[FormKeys.RequiredEnergyPerYear];
        }
        if (!LodashService.isNil(heatGeneratorDetails?.[FormKeys.GasLossExhaust])) {
            currentHeatingSystem.exhaustGasLosses = heatGeneratorDetails![FormKeys.GasLossExhaust];
        }
        if (!LodashService.isNil(heatGeneratorDetails?.[FormKeys.HeatPumpOperatingMode])) {
            currentHeatingSystem.heatPumpOperatingMode = heatGeneratorDetails![FormKeys.HeatPumpOperatingMode];
        }
        if (!LodashService.isNil(isOldDeviceUsed?.value)) {
            currentHeatingSystem.currentHeaterAsPeakLoadDevice = isOldDeviceUsed!.value;
        }

        const buildingPartial: BuildingPartial = {};

        if (Object.keys(currentHeatingSystem).length) {
            buildingPartial.currentHeatingSystem = currentHeatingSystem;
        }

        if (building?.[FormKeys.BuildingConstructionYear]) {
            buildingPartial.buildingConstructionYear = +building[FormKeys.BuildingConstructionYear];
        }
        if (heatingDistribution?.[FormKeys.DistributionMethod]) {
            buildingPartial.heatingType = heatingDistribution[FormKeys.DistributionMethod];
        }
        if (heatingDistribution?.[FormKeys.HeatingFlowTemperature]) {
            buildingPartial.flowTemperature = heatingDistribution[FormKeys.HeatingFlowTemperature];
        }
        if (energyConsumption?.[FormKeys.NonHeatingElectricityPerYear]) {
            buildingPartial.electricityDemand = energyConsumption[FormKeys.NonHeatingElectricityPerYear];
        }
        if (heatingDistribution?.[FormKeys.HeatingSchedule]) {
            buildingPartial.consumptionProfileForHeating = heatingDistribution[FormKeys.HeatingSchedule];
        }
        if (heatGeneratorDetails?.[FormKeys.WarmWaterByHeatGenerator]) {
            buildingPartial.warmWaterByHeatGenerator = heatGeneratorDetails[FormKeys.WarmWaterByHeatGenerator];
        }
        if (livingArea) {
            buildingPartial.livingArea = livingArea.value;
        }

        if (
            selectedProject?.building?.buildingType === BuildingType.MultiFamilyHouse &&
            !LodashService.isNil(subsidyEligibility?.numberOfResidentialUnits?.value)
        ) {
            buildingPartial.numberOfResidentialUnits = subsidyEligibility!.numberOfResidentialUnits!.value;
        } else if (
            !LodashService.isNil(subsidyEligibility?.isIncomeLowForSubsidy?.value) &&
            !LodashService.isNil(subsidyEligibility?.isOwnerOfTheBuilding?.value) &&
            !LodashService.isNil(subsidyEligibility?.isOwnerMainResidence?.value)
        ) {
            buildingPartial.household = {
                isIncomeLowForSubsidy: subsidyEligibility?.isIncomeLowForSubsidy?.value,
                isOwnerOfTheBuilding: subsidyEligibility?.isOwnerOfTheBuilding?.value,
                isOwnerMainResidence: subsidyEligibility?.isOwnerMainResidence?.value
            };

            if (
                selectedProject?.projectType === ProjectType.Renovation &&
                !LodashService.isNil(subsidyEligibility?.isOldHeatingSystemDefective?.value)
            ) {
                buildingPartial.household = {
                    ...buildingPartial.household,
                    isOldHeatingSystemDefective: subsidyEligibility?.isOldHeatingSystemDefective?.value
                };
            }
        }

        return { request: { building: buildingPartial } };
    }

    private getHeatloadCalculationsWithHeatDemand(): Observable<HeatLoadCalculationGetById[]> {
        const selectedProject$ = this.projectStore.project$.pipe(filter(Boolean), first());

        return forkJoin([selectedProject$, this.calculateHeatDemand()]).pipe(
            first(),
            map(([project, heatDemand]) => {
                const calculations = this.projectsService.getHeatloads(project)!;
                const selectedCalculation = this.projectsService.getSelectedHeatload(project)!;
                const restOfCalcuations = calculations.filter(item => !item.isSelected);
                return [
                    ...restOfCalcuations,
                    {
                        ...selectedCalculation,
                        baseHeatDemand: heatDemand.baseHeatDemand,
                        warmWaterHeatDemand: heatDemand.warmWaterHeatDemand
                    }
                ];
            })
        );
    }

    private calculateHeatDemand(): Observable<HeatDemandCalculationResponse> {
        return this.globalStore.state$.pipe(
            first(),
            map((state: AppState): HeatDemandCalculationRequestPost => {
                const {
                    project: { selectedProject },
                    heatPumpIntermediary: { heatingDistribution }
                } = state;
                const heatloadCalculation = this.projectsService.getSelectedHeatload(selectedProject!)!;
                const countryCode = selectedProject!.address.countryCode;
                const consumptionProfileForHeating =
                    heatingDistribution?.heatingSchedule || selectedProject!.building.consumptionProfileForHeating!;

                return {
                    baseHeatLoad: heatloadCalculation.baseHeatLoad,
                    postalCode: selectedProject!.address.postalCode,
                    countryCode,
                    consumptionProfileForHeating,
                    numberOfPersons: selectedProject!.building.numberOfPersons
                };
            }),
            switchMap(request => {
                return this.calculationsControllerService.calculationsControllerHeatDemand({ body: request }).pipe(catchError(() => EMPTY));
            })
        );
    }

    private postHeatPumpToolsParams(): Observable<HeatPumpToolsResponse> {
        return this.globalStore.state$.pipe(
            first(),
            map((state: AppState): HeatPumpToolsRequestPost => {
                const {
                    project: { selectedProject },
                    heatPumpIntermediary: { heatingEnergySource, energyConsumption, heatGeneratorDetails }
                } = state;
                const countryCode = selectedProject!.address.countryCode;

                const heatGenerator =
                    heatingEnergySource?.selectedHeatGenerator || selectedProject?.building.currentHeatingSystem?.heatGeneratorType;
                // New building project
                if (selectedProject?.projectType === ProjectType.NewConstruction) {
                    return {
                        countryCode,
                        postalCode: selectedProject?.address.postalCode,
                        projectType: selectedProject?.projectType!
                    } as HeatPumpToolsRequestPost;
                }
                // Modernisation project (HP as an old device)
                else if (heatGenerator === HeatGeneratorType.HeatPump) {
                    return {
                        projectType: selectedProject?.projectType!,
                        energySource:
                            heatingEnergySource?.selectedEnergySource?.type || selectedProject?.building.currentHeatingSystem?.energySource,
                        energyPrices: selectedProject?.energyPrices,
                        electricityDemand: energyConsumption?.nonHeatingElectricityPerYear || selectedProject?.building.electricityDemand,
                        consumption:
                            energyConsumption?.requiredEnergyPerYear || selectedProject?.building.currentHeatingSystem?.consumption,
                        postalCode: selectedProject?.address.postalCode!,
                        countryCode,
                        numberOfPersons: selectedProject!.building.numberOfPersons,
                        installationConstructionYear:
                            +heatGeneratorDetails?.installationYear! ||
                            selectedProject?.building.currentHeatingSystem?.installationConstructionYear,
                        heatGeneratorPower:
                            heatGeneratorDetails?.nominalPower || selectedProject?.building.currentHeatingSystem?.heatGeneratorPower,
                        exhaustGasLosses: heatGeneratorDetails?.gasLossExhaust || DEFAULT_EXHAUST_GAS_LOSSES,
                        heatingSystemType:
                            heatGeneratorDetails?.boilerType || selectedProject?.building.currentHeatingSystem?.heatingSystemType,
                        isLowTemperature:
                            !!heatGeneratorDetails?.lowTemperature ||
                            !!selectedProject?.building.currentHeatingSystem?.heatPumpOperatingMode,
                        heatPumpOperatingMode:
                            heatGeneratorDetails?.heatPumpOperatingMode ||
                            selectedProject?.building.currentHeatingSystem?.heatPumpOperatingMode
                    } as HeatPumpToolsRequestPost;
                }
                // Modernisation project (no HP as an old device)
                else {
                    return {
                        projectType: selectedProject?.projectType!,
                        energySource:
                            heatingEnergySource?.selectedEnergySource?.type || selectedProject?.building.currentHeatingSystem?.energySource,
                        energyPrices: selectedProject?.energyPrices,
                        electricityDemand: energyConsumption?.nonHeatingElectricityPerYear || selectedProject?.building.electricityDemand,
                        consumption:
                            energyConsumption?.requiredEnergyPerYear || selectedProject?.building.currentHeatingSystem?.consumption,
                        postalCode: selectedProject?.address.postalCode!,
                        countryCode,
                        numberOfPersons: selectedProject!.building.numberOfPersons,
                        installationConstructionYear:
                            +heatGeneratorDetails?.installationYear! ||
                            selectedProject?.building.currentHeatingSystem?.installationConstructionYear,
                        heatGeneratorPower:
                            heatGeneratorDetails?.nominalPower || selectedProject?.building.currentHeatingSystem?.heatGeneratorPower,
                        exhaustGasLosses: heatGeneratorDetails?.gasLossExhaust || DEFAULT_EXHAUST_GAS_LOSSES,
                        heatingSystemType:
                            heatGeneratorDetails?.boilerType || selectedProject?.building.currentHeatingSystem?.heatingSystemType,
                        isLowTemperature: !!heatGeneratorDetails?.lowTemperature
                    } as HeatPumpToolsRequestPost;
                }
            }),
            switchMap(request => {
                return this.paramsControllerService.paramsControllerHeatPumpTools({ body: request as HeatPumpToolsRequestPost });
            })
        );
    }
}
