import { VerifyProjectLockResponse, LockProjectResponse, UnlockProjectResponse } from '@customer-apps/api-planning-projects';
import { createAction, props } from '@ngrx/store';

export namespace ProjectAccessActions {
    export const startVerifyProjectLock = createAction('[Project Access] Start Verify Lock Project');
    export const stopVerifyProjectLock = createAction('[Project Access] Stop Verify Lock Project');
    export const verifyProjectLock = createAction('[Project Access] Verify Lock Project', props<{ id: string }>());
    export const verifyProjectLockSuccess = createAction(
        '[Project Access] Verify Lock Project Success',
        props<VerifyProjectLockResponse>()
    );
    export const lockProject = createAction('[Project Access] Lock Project', props<{ id: string }>());
    export const lockProjectSuccess = createAction('[Project Access] Lock Project Success', props<LockProjectResponse>());
    export const skipProjectUnlockBeforeUnload = createAction('[Project Access] Skip Project Unlocking Before Unload');
    export const unlockProjectBeforeUnload = createAction('[Project Access] Unlock Project Before Unload');
    export const unlockProject = createAction('[Project Access] Unlock Project', props<{ id?: string }>());
    export const unlockProjectSuccess = createAction('[Project Access] Unlock Project Success', props<UnlockProjectResponse>());
    export const nextAttemptProjectLock = createAction('[Project Access] Lock Attempt Project', props<{ id: string }>());
    export const nextAttemptProjectLockSuccess = createAction(
        '[Project Access] Lock Attempt Project Success',
        props<{ res: LockProjectResponse; otherSessionExists: boolean }>()
    );
    export const nextAttemptProjectLockResult = createAction(
        '[Project Access] Lock Attempt Project Response',
        props<LockProjectResponse>()
    );

    export const showExpiredLockSessionMessage = createAction('[Project Access] Show Expired Lock Session Message');
    export const hideExpiredLockSessionMessage = createAction('[Project Access] Hide Expired Lock Session Message');
    export const showOtherSessionExistsSnackbar = createAction(
        '[Project Access] Show Other Session Exists Snackbar',
        props<{ action: string; message: string }>()
    );
}
