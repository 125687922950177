import { Pipe, PipeTransform } from '@angular/core';
import { LodashService } from '@customer-apps/shared/services';

@Pipe({
    name: 'isNil'
})
export class IsNilPipe implements PipeTransform {
    transform(value: unknown): boolean {
        return LodashService.isNil(value);
    }
}
