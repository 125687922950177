import { EnumDictionary } from '../types';

export const gegSystemToIconName: EnumDictionary<string, string> = {
    'GEG_SYSTEM.GAS_BOILER_GAS_CONDENSING': 'gas-boiler',
    'GEG_SYSTEM.GAS_BOILER_GAS_NON_CONDENSING': 'gas-boiler',
    'GEG_SYSTEM.GAS_BOILER_GAS_LOW_TEMP': 'gas-boiler',
    'GEG_SYSTEM.GAS_BOILER_GAS_HIGH_TEMP': 'gas-boiler',
    'GEG_SYSTEM.GAS_BOILER_GAS_CONDENSING_LOW_TEMP': 'gas-boiler',
    'GEG_SYSTEM.GAS_BOILER_GAS_CONDENSING_HIGH_TEMP': 'gas-boiler',

    'GEG_SYSTEM.GAS_BOILER_METHANE_CONDENSING': 'gas-boiler',
    'GEG_SYSTEM.GAS_BOILER_METHANE_NON_CONDENSING': 'gas-boiler',
    'GEG_SYSTEM.GAS_BOILER_METHANE_LOW_TEMP': 'gas-boiler',
    'GEG_SYSTEM.GAS_BOILER_METHANE_HIGH_TEMP': 'gas-boiler',

    'GEG_SYSTEM.GAS_BOILER_HYDROGEN_CONDENSING': 'gas-boiler',
    'GEG_SYSTEM.GAS_BOILER_HYDROGEN_NON_CONDENSING': 'gas-boiler',
    'GEG_SYSTEM.GAS_BOILER_HYDROGEN_LOW_TEMP': 'gas-boiler',
    'GEG_SYSTEM.GAS_BOILER_HYDROGEN_HIGH_TEMP': 'gas-boiler',

    'GEG_SYSTEM.WOOD_STOVE_PELLET_NON_CONDENSING': 'pellet',
    'GEG_SYSTEM.WOOD_STOVE_SOFTWOOD_NON_CONDENSING': 'wood-stove',
    'GEG_SYSTEM.WOOD_STOVE_HARDWOOD_NON_CONDENSING': 'wood-stove',

    'GEG_SYSTEM.HEAT_PUMP_AIR': 'heat-pump',
    'GEG_SYSTEM.HEAT_PUMP_AIR_PARALLEL': 'heat-pump',
    'GEG_SYSTEM.HEAT_PUMP_AIR_MONOVALENT': 'heat-pump',
    'GEG_SYSTEM.HEAT_PUMP_AIR_ALTERNATE': 'heat-pump',

    'GEG_SYSTEM.HEAT_PUMP_WATER': 'heat-pump',
    'GEG_SYSTEM.HEAT_PUMP_WATER_PARALLEL': 'heat-pump',
    'GEG_SYSTEM.HEAT_PUMP_WATER_MONOVALENT': 'heat-pump',
    'GEG_SYSTEM.HEAT_PUMP_WATER_ALTERNATE': 'heat-pump',

    'GEG_SYSTEM.HEAT_PUMP_SOIL': 'heat-pump',
    'GEG_SYSTEM.HEAT_PUMP_SOIL_PARALLEL': 'heat-pump',
    'GEG_SYSTEM.HEAT_PUMP_SOIL_MONOVALENT': 'heat-pump',
    'GEG_SYSTEM.HEAT_PUMP_SOIL_ALTERNATE': 'heat-pump',

    'GEG_SYSTEM.OIL_BOILER': 'oil-boiler',
    'GEG_SYSTEM.ELECTRIC_HEATER': 'electric-heater',
    'GEG_SYSTEM.SOLAR_THERMAL': 'solar-thermal',
    'GEG_SYSTEM.DISTRICT_HEATING': 'district-heating'
};
