/**
 * Defines an energy source, the type of fuel used for heating devices.
 */
export enum EnergySource {
    HeatingOil = 'HEATING_OIL',
    Gas = 'GAS',
    Electricity = 'ELECTRICITY',
    HeatPumpElectricity = 'ELECTRICITY_HEATPUMP',
    ElectricityFeed = 'ELECTRICITY_FEED',
    ElectricityOwnNeeds = 'ELECTRICITY_OWN_NEEDS',
    ElectricityProduced = 'ELECTRICITY_PRODUCED',
    Pellet = 'PELLET',
    Softwood = 'SOFTWOOD',
    Hardwood = 'HARDWOOD',
    Methane = 'METHANE',
    Hydrogen = 'HYDROGEN',
    SolarThermal = 'SOLAR_THERMAL',
    DistrictHeating = 'DISTRICT_HEATING'
}
