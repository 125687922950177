<div class="d-flex justify-content-between py-3">
    <div class="d-flex flex-column">
        <b>{{ partnerName }}</b>
        <span>{{ postalCode }} {{ city }}</span>
        <span>{{ street }}</span>
    </div>
    <div class="d-flex flex-column">
        <span>{{ partnerNumber }}</span>
        <span>{{ salesOrganization }}</span>
        <span>{{ salesChannel }}</span>
    </div>
</div>
