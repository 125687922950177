import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'vp-custom-snackbar-message',
    templateUrl: './custom-snackbar-message.component.html'
})
export class CustomSnackbarMessageComponent implements OnInit {
    public message: string;
    public action: string;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA)
        private data: { message: string; action: string },
        private snackBarRef: MatSnackBarRef<CustomSnackbarMessageComponent>
    ) {}

    public ngOnInit(): void {
        this.message = this.data.message || '';
        this.action = this.data.action || '';
    }

    public dismiss(): void {
        this.snackBarRef.dismiss();
    }
}
