<div class="d-flex justify-content-between vp-gap-24 pt-4 pb-4">
    <div>
        <button
            mat-stroked-button
            id="previous-page-btn"
            data-testid="previous-page-btn"
            class="vp-button mt-auto"
            [disabled]="disabled || pageIndex === 0"
            (click)="onNavigationClick(-1)"
        >
            <mat-icon class="m-0">navigate_before</mat-icon>
        </button>
    </div>

    <div class="d-none d-sm-flex justify-content-center vp-gap-8 pages">
        <ng-container *ngFor="let index of pageIndexes">
            <div
                *ngIf="index === -1; else pageButtonEl"
                data-testid="paginator-bar-dots"
                class="d-flex align-items-center justify-content-center dots"
            >
                ...
            </div>
            <ng-template #pageButtonEl>
                <button
                    mat-stroked-button
                    id="page-{{ index + 1 }}-btn"
                    [attr.data-testid]="'page-' + (index + 1) + '-btn'"
                    color="primary"
                    class="vp-button mt-auto page-btn"
                    [class.active-page]="pageIndex === index"
                    (click)="onPageClick(index)"
                    [disabled]="disabled"
                >
                    {{ index + 1 }}
                </button>
            </ng-template>
        </ng-container>
    </div>

    <div data-testid="paginator-bar-page" class="d-flex d-sm-none align-items-center justify-content-center pages">
        {{ 'PAGINATOR_BAR.PAGE' | translate }} {{ pageIndex + 1 }}/{{ totalPagesCount }}
    </div>

    <div>
        <button
            mat-stroked-button
            id="next-page-btn"
            data-testid="next-page-btn"
            class="vp-button mt-auto"
            [disabled]="disabled || pageIndex === totalPagesCount - 1"
            (click)="onNavigationClick(1)"
        >
            <mat-icon class="m-0">navigate_next</mat-icon>
        </button>
    </div>
</div>
