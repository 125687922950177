import { Injectable } from '@angular/core';
import { filter, map, merge, Observable, withLatestFrom } from 'rxjs';
import { ProjectStore } from '../../state/project/project.store';
import { HeatloadPrefillMapper, ProjectsService } from '../../services';
import { Question } from '@customer-apps/shared/utils';
import { BuildingType, CountryCode, FormName, HeatLoadCalculationMethod, ProjectType, SuccessMessages } from '@customer-apps/shared/enums';
import { HeatPumpIntermediaryStore } from '../../state/heat-pump-intermediary/heat-pump-intermediary.store';
import { HeatPumpIntermediaryPrefillService } from './heat-pump-intermediary-prefill.service';
import { AccordionService, HeatPumpIntermediaryState, LivingAreaFormValue } from '@customer-apps/shared/interfaces';
import { HeatloadFormOptionsService, HeatloadFormService } from '../../heatload/services';
import { TranslateService } from '@ngx-translate/core';
import { ProjectResponseGetById } from '@customer-apps/api-planning-projects';

@Injectable({
    providedIn: 'root'
})
export class HeatPumpIntermediaryLivingAreaService implements AccordionService {
    constructor(
        private projectStore: ProjectStore,
        private prefillService: HeatPumpIntermediaryPrefillService,
        private heatPumpIntermediaryStore: HeatPumpIntermediaryStore,
        private translate: TranslateService,
        private formService: HeatloadFormService,
        private formOptionsService: HeatloadFormOptionsService,
        private projectsService: ProjectsService,
        private prefillMapper: HeatloadPrefillMapper
    ) {}

    public questions$: Observable<Question[] | null> = merge(
        this.qustionsForRenovationProjectCalculatedByBuildingHull$(),
        this.qustionsForRenovationProjectCalculatedByCustomValue$(),
        this.qustionsForNewConstructionProject$()
    );

    public getSuccessMessage(formValue: LivingAreaFormValue): string {
        return this.translate.instant(SuccessMessages.LivingAreaSize, { value: formValue.livingArea });
    }

    public isPrefillNeeded(): boolean {
        return !this.prefillService.isPrefilled(FormName.LivingArea);
    }

    public afterPrefill(): void {
        this.prefillService.setAsPrefilled(FormName.LivingArea);
    }

    public isExpanded$ = merge(this.isSubmitedPreviousFormSameValue$());

    private qustionsForRenovationProjectCalculatedByCustomValue$() {
        return this.heatPumpIntermediaryStore.building$.pipe(
            withLatestFrom(this.projectStore.project$, this.heatPumpIntermediaryStore.cache$, (building, project, cache) => ({
                building,
                project,
                cache
            })),
            filter(
                ({ project }) =>
                    this.projectsService.isRenovation(project!.projectType as ProjectType) &&
                    (this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.CustomValue) ||
                        this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.Consumption))
            ),
            map(state => {
                if (!state.building) {
                    return null;
                }

                return this.returnQuestions(state);
            })
        );
    }

    private qustionsForRenovationProjectCalculatedByBuildingHull$() {
        return this.heatPumpIntermediaryStore.isOldDeviceUsed$.pipe(
            withLatestFrom(this.projectStore.project$, this.heatPumpIntermediaryStore.cache$, (device, project, cache) => ({
                device,
                project,
                cache
            })),
            filter(
                ({ project }) =>
                    this.projectsService.isRenovation(project!.projectType as ProjectType) &&
                    this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.BuildingHull)
            ),
            map(state => {
                if (!state.device) {
                    return null;
                }

                return this.returnQuestions(state);
            })
        );
    }

    private returnQuestions(state: { project?: ProjectResponseGetById; cache?: HeatPumpIntermediaryState }) {
        const cachePrefill = this.prefillMapper.stateToLivingAreaPrefill(state.cache);
        const projectPrefill = this.prefillMapper.projectToLivingAreaPrefill(state.project!);
        const options = this.formOptionsService.getLivingAreaFormOptions({
            prefill: cachePrefill || projectPrefill,
            buildingType: state.project!.building.buildingType as BuildingType,
            countryCode: state.project!.address.countryCode as CountryCode
        });

        return this.formService.getLivingAreaQuestions(options);
    }

    private qustionsForNewConstructionProject$() {
        return this.projectStore.project$.pipe(
            filter(
                project =>
                    this.projectsService.isNewConstruction(project!.projectType as ProjectType) &&
                    !this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.LivingSpace)
            ),
            map(project => {
                const projectPrefill = this.prefillMapper.projectToLivingAreaPrefill(project!);
                const options = this.formOptionsService.getLivingAreaFormOptions({
                    buildingType: project!.building.buildingType as BuildingType,
                    countryCode: project!.address.countryCode as CountryCode,
                    prefill: projectPrefill
                });

                return this.formService.getLivingAreaQuestions(options);
            })
        );
    }

    private isSubmitedPreviousFormSameValue$(): Observable<boolean> {
        return this.heatPumpIntermediaryStore.sameValueSubmited$.pipe(
            withLatestFrom(this.projectStore.project$, (form, project) => ({ form, project })),
            map(({ form, project }) => {
                const afterDeviceForm =
                    form?.formName === FormName.Device &&
                    this.projectsService.isRenovation(project!.projectType as ProjectType) &&
                    this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.BuildingHull);

                const afterBuildingForm =
                    form?.formName === FormName.Building &&
                    this.projectsService.isRenovation(project!.projectType as ProjectType) &&
                    (this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.Consumption) ||
                        this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.CustomValue));

                return afterDeviceForm || afterBuildingForm;
            })
        );
    }
}
