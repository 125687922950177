import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'vp-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {
    @Input() diameter: number = 32;
    @Input() text?: string = 'SPINNER.LOADING';
    @Input() subtext?: string;
}
