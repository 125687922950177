import { Injectable } from '@angular/core';
import { Observable, filter, first, map, merge, withLatestFrom } from 'rxjs';
import { HeatloadPrefillService } from './heatload-prefill.service';
import { HeatloadStore } from '../../state/heatload/heatload.store';
import { TranslateService } from '@ngx-translate/core';
import { ProjectStore } from '../../state/project/project.store';
import { HeatloadFormService } from './heatload-form.service';
import { FormName, HeatLoadCalculationMethod } from '@customer-apps/shared/enums';
import { AccordionService, BuildingHull, FormOptions } from '@customer-apps/shared/interfaces';
import { LodashService } from '@customer-apps/shared/services';
import { HeatloadFormOptionsService } from './heatload-form-options.service';
import { HeatloadPrefillMapper } from '../../services';
import { HeatloadService } from './heatload.service';

@Injectable({
    providedIn: 'root'
})
export class BuildingHullService implements AccordionService {
    constructor(
        private heatloadPrefillService: HeatloadPrefillService,
        private prefillMapper: HeatloadPrefillMapper,
        private heatloadFormOptionsService: HeatloadFormOptionsService,
        private heatloadStore: HeatloadStore,
        private heatloadService: HeatloadService,
        private translate: TranslateService,
        private projectStore: ProjectStore,
        private heatloadFormService: HeatloadFormService
    ) {}

    public questions$: Observable<any> = this.heatloadStore.calculationMethod$.pipe(
        withLatestFrom(this.projectStore.project$, this.heatloadStore.cache$),
        map(([method, project, cache]) => ({
            isBuildingHull: method?.value === HeatLoadCalculationMethod.BuildingHull,
            project,
            cache
        })),

        map(state => {
            if (!state.isBuildingHull) {
                return null;
            }

            const projectPrefill = this.prefillMapper.projectToBuildingHullPrefill(state.project!);
            const cachePrefill = this.prefillMapper.stateToBuildingHullPrefill(state.cache?.buildingHull);
            const options = this.heatloadFormOptionsService.getBuildingHullFormOptions(state.project!, cachePrefill || projectPrefill);
            return this.heatloadFormService.getBuildingQuestions(options as FormOptions);
        })
    );

    public isPrefillNeeded(): boolean {
        return this.heatloadService.isRecalculation() && !this.heatloadPrefillService.isPrefilled(FormName.BuildingHull);
    }

    public getSuccessMessage(formValue: BuildingHull): string {
        const options = {
            area: this.roundToFixed2(+formValue.buildingDimensions.buildingLength * +formValue.buildingDimensions.buildingWidth),
            numberOfFloors: formValue.buildingDimensions.numberOfFloors
        };
        return this.translate.instant('HEATLOAD.BUILDING.SUCCESS_MESSAGE', options);
    }

    public afterPrefill(): void {
        this.heatloadPrefillService.setAsPrefilled(FormName.BuildingHull);
    }

    public isExpanded$ = merge(this.isSubmitedCalculationMethodSameValue$(), this.isPrefillCompletedByBuildingHull$());

    private isSubmitedCalculationMethodSameValue$() {
        return this.heatloadStore.sameValueSubmited$.pipe(
            map(
                form =>
                    form?.formName === FormName.CalculationMethod &&
                    form.value.methods.standardMethod === HeatLoadCalculationMethod.BuildingHull
            )
        );
    }

    private isPrefillCompletedByBuildingHull$(): Observable<boolean> {
        return this.heatloadPrefillService.state$.pipe(
            map(prefillState => {
                const lastAccordionToBePrefilled = FormName.WindowsAndGlazing;
                return prefillState[lastAccordionToBePrefilled].isPrefilled && this.heatloadService.isRecalculation();
            }),
            filter(Boolean),
            first()
        );
    }

    private roundToFixed2(value: number): number {
        if (LodashService.isNaN(value)) {
            return value;
        }
        return Math.round(value * 100) / 100;
    }
}
