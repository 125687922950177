import { Component, Input, OnInit } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator
} from '@angular/forms';
import { SliderQuestion } from '@customer-apps/shared/utils';

@Component({
    selector: 'vp-slider',
    templateUrl: './slider.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SliderComponent
        },
        {
            provide: NG_VALIDATORS,
            useExisting: SliderComponent,
            multi: true
        }
    ]
})
export class SliderComponent implements ControlValueAccessor, Validator, OnInit {
    @Input() question: SliderQuestion;
    @Input() form: FormGroup;
    public textField: FormControl;
    public touched: boolean;
    public blurred: boolean;
    public validationError: ValidationErrors | null;
    /**
     * Value is allowed to be an empty string to prevent displaying "0" in the text field after user deletes text field's content.
     */
    public value: number | '';

    ngOnInit(): void {
        this.form?.statusChanges.subscribe(() => this.markAsTouched());
        this.textField = new FormControl(this.question?.value);
        this.onTextFieldChange();
    }

    public writeValue(value: number | ''): void {
        this.value = value;
    }

    public onChange: (...args: any) => void = (value: number | '') => {};

    public registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }

    public onTouched: (...args: any) => void = () => {};

    public registerOnTouched(onTouched: (...args: any) => void): void {
        this.onTouched = onTouched;
    }

    public markAsBlurred(): void {
        if (!this.blurred) {
            this.blurred = true;
            this.validate(this.textField);
        }
    }

    public markAsTouched(): void {
        if (!this.touched) {
            this.onTouched();
            this.textField.markAsTouched();
            this.touched = true;
        }
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        this.validationError = null;
        const isValueOutOfTheRage =
            control.value && control.value !== '' && (control.value > this.question.max || control.value < this.question.min);
        const isValueEmpty = !!control.value;
        this.validationError = isValueOutOfTheRage ? { incorrectValue: true, emptyValue: false } : null;
        if (!isValueEmpty) {
            this.validationError = { emptyValue: true };
        }
        if (this.blurred) {
            this.textField.setErrors(this.validationError);
        }
        return this.validationError;
    }

    public onValidationChange: (...args: any) => void = () => {};

    public registerOnValidatorChange?(onValidationChange: (...args: any) => void): void {
        this.onValidationChange = onValidationChange;
    }

    public onSliderInput(event: Event): void {
        const value = Number((event?.target as HTMLInputElement)?.value) || '';
        this.writeValue(value);
        this.textField.setValue(value);
        this.markAsTouched();
        this.onChange(this.value);
    }

    private onTextFieldChange() {
        this.textField.valueChanges.subscribe(valueSource => {
            const value = Number(valueSource) || '';
            this.writeValue(value);
            this.markAsTouched();
            this.onChange(this.value);
        });
    }
}
