import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { BuildingType, FormKeys, HeatingDistributionMethod } from '@customer-apps/shared/enums';
import { FormValueChangeMinMaxValidatorOptions } from '@customer-apps/shared/interfaces';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class ValidatorsService {
    constructor(private translateService: TranslateService) {}

    public minMaxValidator({ min, max }: { min: number; max: number }): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value && control.value !== 0) {
                return null;
            }

            if (control.value < min || control.value > max) {
                return {
                    message: this.translateService.instant('COMMON.ERRORS.VALUE_RANGE', {
                        value1: min,
                        value2: max
                    })
                };
            }
            return null;
        };
    }

    public buildingYearValidator({ buildingYear, buildingElement }: { buildingYear: number; buildingElement: string }): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value && control.value !== 0) {
                return null;
            }

            if (control.value < buildingYear) {
                return {
                    message: this.translateService.instant('COMMON.ERRORS.BUILDING_YEAR', {
                        buildingElement: this.translateService.instant(buildingElement),
                        buildingConstructionYear: buildingYear
                    })
                };
            }
            return null;
        };
    }

    public installationYearValidator(installationConstructionYear: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value && control.value !== 0) {
                return null;
            }

            if (control.value > installationConstructionYear) {
                return {
                    message: this.translateService.instant('COMMON.ERRORS.INSTALLATION_YEAR', {
                        installationConstructionYear
                    })
                };
            }
            return null;
        };
    }

    /**
     * Compares installation construction year provided to the form with building construction year saved in the project or other source
     * @param buildingConstructionYear comparison value saved in the project or other source.
     */
    public installationYearToBuildingConstructionYearValidator(buildingConstructionYear: number): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            if (!buildingConstructionYear) {
                return null;
            }
            const installationYear = formGroup.get(FormKeys.InstallationYear);

            if (!installationYear) {
                return null;
            }

            const options = {
                buildingYear: buildingConstructionYear,
                buildingElement: 'HEATLOAD.HEAT_GENERATOR_DETAILS.INSTALLATION_YEAR.LABEL'
            };
            const validator = this.buildingYearValidator(options);
            const error = validator(installationYear);
            if (error) {
                installationYear.setErrors(error);
            }
            return null;
        };
    }
    /**
     * Compares building construction year provided to the form with installation construction year saved in the project or other source.
     * @param installationConstructionYear comparison value saved in the project or other source.
     */
    public buildingConstructionYearToInstallationYearValidator(installationConstructionYear: number): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            if (!installationConstructionYear) {
                return null;
            }
            const buildingConstructionYear = formGroup.get(FormKeys.BuildingConstructionYear);

            if (!buildingConstructionYear) {
                return null;
            }

            const validator = this.installationYearValidator(installationConstructionYear);
            const error = validator(buildingConstructionYear);
            if (error) {
                buildingConstructionYear.setErrors(error);
            }
            return null;
        };
    }

    public residentQuantityMinMaxValidator() {
        const minMaxSetting = {
            [BuildingType.MultiFamilyHouse]: { min: 6, max: 1000 },
            [BuildingType.SingleFamilyHouse]: { min: 1, max: 15 }
        };
        const options: FormValueChangeMinMaxValidatorOptions = {
            validatedQuestionFormKey: FormKeys.ResidentQuantity,
            observedQuestionFormKey: FormKeys.BuildingType,
            minMaxSetting
        };

        return this.onFormValueChangeMinMaxValidator(options);
    }

    public flowTemperatureMinMaxValidator() {
        const minMaxSetting = {
            [HeatingDistributionMethod.Radiator]: { min: 35, max: 90 },
            [HeatingDistributionMethod.RadiatorAndUnderfloor]: { min: 35, max: 90 },
            [HeatingDistributionMethod.Underfloor]: { min: 28, max: 55 }
        };
        const options: FormValueChangeMinMaxValidatorOptions = {
            validatedQuestionFormKey: FormKeys.HeatingFlowTemperature,
            observedQuestionFormKey: FormKeys.DistributionMethod,
            minMaxSetting
        };

        return this.onFormValueChangeMinMaxValidator(options);
    }

    private onFormValueChangeMinMaxValidator(options: FormValueChangeMinMaxValidatorOptions): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            const observedQuestion = formGroup.get(options.observedQuestionFormKey);
            if (!observedQuestion) {
                return null;
            }
            const validatedQuestion = formGroup.get(options.validatedQuestionFormKey);
            if (!validatedQuestion) {
                return null;
            }

            const minMaxSelectedSettings = options.minMaxSetting[observedQuestion.value];
            if (!minMaxSelectedSettings) {
                return null;
            }
            const minMaxValidator = this.minMaxValidator(minMaxSelectedSettings);
            const error = minMaxValidator(validatedQuestion);
            if (error) {
                validatedQuestion.setErrors(error);
            }
            return null;
        };
    }

    private onBuildingConstructionYearChangeValidator(validatedQuestion: { formPath: FormKeys[] | FormKeys; phraseKey: string }) {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            const buildingConstructionYear = formGroup.get(FormKeys.BuildingConstructionYear);

            if (!buildingConstructionYear?.value) {
                return null;
            }
            const control = formGroup.get(validatedQuestion.formPath);

            if (!control?.value) {
                return null;
            }
            const validatorOptions = {
                buildingYear: buildingConstructionYear.value,
                buildingElement: validatedQuestion.phraseKey
            };
            const validator = this.buildingYearValidator(validatorOptions);
            const error = validator(control);
            const errors = error || control.errors ? { ...error, ...control.errors } : null;
            control.setErrors(errors);
            return null;
        };
    }

    /**
     * Validates if windows modernisation year is not older than building construction year in modernisations form.
     */
    public windowsModernisationYearValidator(): ValidatorFn {
        const validatedQuestion = {
            formPath: FormKeys.WindowsModernization,
            phraseKey: 'HEATLOAD.MODERNIZATIONS.WINDOWS.TITLE'
        };
        return this.onBuildingConstructionYearChangeValidator(validatedQuestion);
    }

    /**
     * Validates if walls modernisation year is not older than building construction year in modernisations form.
     */
    public wallsModernisationYearValidator(): ValidatorFn {
        const validatedQuestion = {
            formPath: FormKeys.WallsModernization,
            phraseKey: 'HEATLOAD.MODERNIZATIONS.WALL.TITLE'
        };
        return this.onBuildingConstructionYearChangeValidator(validatedQuestion);
    }

    /**
     * Validates if roof modernisation year is not older than building construction year in modernisations form.
     */
    public roofModernisationYearValidator(): ValidatorFn {
        const validatedQuestion = {
            formPath: FormKeys.RoofModernization,
            phraseKey: 'HEATLOAD.MODERNIZATIONS.ROOF.TITLE'
        };
        return this.onBuildingConstructionYearChangeValidator(validatedQuestion);
    }

    /**
     * Validates if windows construction year is not older than building construction year in modernisation and insulation form.
     */
    public windowsConstructionYearValidator(): ValidatorFn {
        const validatedQuestion = {
            formPath: FormKeys.WindowsConstructionYear,
            phraseKey: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.WINDOWS.TITLE'
        };
        return this.onBuildingConstructionYearChangeValidator(validatedQuestion);
    }

    /**
     * Validates if walls renovation year is not older than building construction year in modernisation and insulation form.
     */
    public wallsRenovationYearValidator(): ValidatorFn {
        const validatedQuestion = {
            formPath: [FormKeys.WallsRenovationAndInsulation, FormKeys.WallsRenovationYear],
            phraseKey: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.WALLS.TITLE'
        };
        return this.onBuildingConstructionYearChangeValidator(validatedQuestion);
    }

    /**
     * Validates if roof renovation year is not older than building construction year in modernisation and insulation form.
     */
    public roofRenovationYearValidator(): ValidatorFn {
        const validatedQuestion = {
            formPath: [FormKeys.RoofRenovationAndInsulation, FormKeys.RoofRenovationYear],
            phraseKey: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.ROOF.TITLE'
        };
        return this.onBuildingConstructionYearChangeValidator(validatedQuestion);
    }

    /**
     * Validates if upper floor renovation year is not older than building construction year in modernisation and insulation form.
     */
    public upperFloorRenovationYearValidator(): ValidatorFn {
        const validatedQuestion = {
            formPath: [FormKeys.UpperFloorRenovationAndInsulation, FormKeys.AtticRenovationYear],
            phraseKey: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.ROOF.TITLE'
        };
        return this.onBuildingConstructionYearChangeValidator(validatedQuestion);
    }

    /**
     * Validates if basement renovation year is not older than building construction year in modernisation and insulation form.
     */
    public basementRenovationYearValidator(): ValidatorFn {
        const validatedQuestion = {
            formPath: [FormKeys.BasementRenovationAndInsulation, FormKeys.BasementRenovationYear],
            phraseKey: 'HEATLOAD.MODERNIZATIONS_AND_INSULATION.BASEMENT.TITLE'
        };
        return this.onBuildingConstructionYearChangeValidator(validatedQuestion);
    }
}
