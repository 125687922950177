import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FooterSelectors } from './footer.selectors';
import { AppState } from '@customer-apps/shared/interfaces';
import { FooterActions } from './footer.actions';

@Injectable({ providedIn: 'root' })
export class FooterStore {
    public state$ = this.store$.pipe(select(FooterSelectors.getState()));

    constructor(private store$: Store<AppState>) {}

    public getApiInfo(): void {
        this.store$.dispatch(FooterActions.getApiInfo());
    }
}
