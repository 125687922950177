export enum AnchorIds {
    NextSteps = 'next-steps',
    Quotes = 'quotes',
    Heatload = 'heatload-section',
    EnergyPrices = 'energy-prices',
    BasicDetails = 'basic-details',
    Results = 'results',
    CalculationSummary = 'calculation-summary',
    GEGConfigurations = 'geg-configurations',
    Orders = 'orders'
}
