import { Injectable } from '@angular/core';
import { map, withLatestFrom } from 'rxjs';
import { HeatloadPrefillService } from './heatload-prefill.service';
import { HeatloadStore } from '../../state/heatload/heatload.store';
import { TranslateService } from '@ngx-translate/core';
import { HeatloadFormService } from './heatload-form.service';
import { AccordionService, BuildingHull, BuildingHullFormValue, FormOptions, RoofTypeFormValue } from '@customer-apps/shared/interfaces';
import { FormName, RoofType } from '@customer-apps/shared/enums';
import { LocaleService } from '../../services/locale.service';
import { HeatloadFormOptionsService } from './heatload-form-options.service';
import { ProjectStore } from '../../state/project/project.store';
import { HeatloadPrefillMapper } from '../../services';
import { HeatloadService } from './heatload.service';

@Injectable({
    providedIn: 'root'
})
export class RoofTypeService implements AccordionService {
    constructor(
        private prefillMapper: HeatloadPrefillMapper,
        private heatloadPrefillService: HeatloadPrefillService,
        private heatloadStore: HeatloadStore,
        private heatloadService: HeatloadService,
        private translate: TranslateService,
        private heatloadFormOptionsService: HeatloadFormOptionsService,
        private projectStore: ProjectStore,
        private localeService: LocaleService,
        private heatloadFormService: HeatloadFormService
    ) {}

    public questions$ = this.heatloadStore.buildingHull$.pipe(
        withLatestFrom(this.projectStore.project$, this.heatloadStore.cache$),
        map(([buildingHull, project, cache]) => ({
            buildingHull,
            project,
            cache
        })),
        map(state => {
            if (!this.isAtticHeated(state.buildingHull)) {
                return null;
            }

            const projectPrefill = this.prefillMapper.projectToRoofTypePrefill(state.project);
            const cachePrefill = this.prefillMapper.stateToRoofTypePrefill(state.cache?.roofType);
            const options: FormOptions = this.heatloadFormOptionsService.getRoofTypeFormOptions(cachePrefill || projectPrefill);
            return this.heatloadFormService.getRoofTypeQuestions(options);
        })
    );

    public isPrefillNeeded(): boolean {
        return this.heatloadService.isRecalculation() && !this.heatloadPrefillService.isPrefilled(FormName.RoofType);
    }

    public getSuccessMessage(formValue: RoofTypeFormValue): string {
        let message: string = '';
        switch (formValue.roofType) {
            case RoofType.Flat:
                message = this.translate.instant('HEATLOAD.ROOF_TYPE.FLAT');
                break;
            case RoofType.SinglePitch:
                message = this.translate.instant('HEATLOAD.ROOF_TYPE.PENT');
                break;
            case RoofType.Saddle:
                message = this.translate.instant('HEATLOAD.ROOF_TYPE.GABLE');
                break;
            case RoofType.Hipped:
                message = this.translate.instant('HEATLOAD.ROOF_TYPE.HIPPED');
                break;
            case RoofType.HalfHipped:
                message = this.translate.instant('HEATLOAD.ROOF_TYPE.HALF_HIPPED');
                break;
            default:
                throw Error('Provided roof type is invalid');
        }

        return this.localeService.getLanguageCode() === 'de' ? message : message.toLowerCase();
    }

    public afterPrefill(): void {
        this.heatloadPrefillService.setAsPrefilled(FormName.RoofType);
    }

    public isExpanded$ = this.heatloadStore.sameValueSubmited$.pipe(
        map(form => form?.formName === FormName.BuildingHull && this.isAtticHeated(form.value as BuildingHullFormValue))
    );

    private isAtticHeated(buildingHull: BuildingHull | undefined) {
        return Boolean(buildingHull?.ventilationAndHeating.atticHeated);
    }
}
