import { ApplicationId } from '@customer-apps/shared/enums';
import { EnumDictionary } from '@customer-apps/shared/utils';

export const QuoteApplicationIdDictionary: EnumDictionary<ApplicationId, string> = {
    [ApplicationId.HeatPumpPlanner]: 'QUOTE.TITLE.HEAT_PUMP_PLANNER',
    [ApplicationId.PhotovoltaicPlanner]: 'QUOTE.TITLE.PHOTOVOLTAIC_PLANNER',
    [ApplicationId.VentilationPlanner]: 'QUOTE.TITLE.VENTILATION_PLANNER',
    [ApplicationId.CogenerationCalculator]: 'QUOTE.TITLE.COGENERATION_CALCULATOR',
    [ApplicationId.ElectricityPlanner]: 'QUOTE.TITLE.ELECTRICITY_PLANNER',
    [ApplicationId.SystemAdviser]: 'QUOTE.TITLE.SYSTEM_ADVISER',
    [ApplicationId.CylinderPlanner]: 'QUOTE.TITLE.CYLINDER_PLANNER',
    [ApplicationId.ViGuidePlanning]: 'QUOTE.TITLE.VIGUIDE_PLANNING'
};
