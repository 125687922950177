import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { FooterActions } from './footer.actions';

import { InfoControllerService } from '@customer-apps/api-planning-projects';

@Injectable()
export class FooterEffects {
    constructor(private actions$: Actions, private infoControllerService: InfoControllerService) {}

    public getApiVersion$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FooterActions.getApiInfo),
            switchMap(() =>
                this.infoControllerService.infoControllerGetServerInfo().pipe(
                    map(res => FooterActions.getApiInfoSuccess(res)),
                    catchError(error => EMPTY)
                )
            )
        )
    );
}
