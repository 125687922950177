<div [ngClass]="question.styles" [formGroup]="formGroup" *ngIf="formGroup">
    <mat-checkbox
        [formControlName]="option.key"
        class="vp-checkbox"
        [disableRipple]="true"
        [id]="question.groupName + option.key"
        [attr.data-testid]="question.groupName + option.key"
        *ngFor="let option of question.questions; let isLast = last"
        [ngClass]="{ 'mb-0': isLast, 'd-none': option.hidden }"
    >
        {{ option.label | translate }}
    </mat-checkbox>
    <div *ngIf="question.validators?.length" class="vp-subscript position-relative mt-2">
        <div class="position-absolute vp-error-message" *ngIf="isInvalid && isTouched" @slideDown>
            <vp-form-error-message>
                {{ errorMessage | translate }}
            </vp-form-error-message>
        </div>
    </div>
</div>
