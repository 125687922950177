import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { FormName } from '@customer-apps/shared/enums';
import { FormCacheConfig, SameFormValue } from '@customer-apps/shared/interfaces';
import { FormComponent } from '@customer-apps/shared/ui';
import { Question } from '@customer-apps/shared/utils';

@Component({ template: '' })
export abstract class AccordionBaseComponent<TFormValue> implements OnChanges {
    @Input() public questions: Question[] | null;
    @Input() public disabled: boolean;
    @Input() public emitValueOnPrefill: boolean = true;
    @Input() public isExpanded: boolean | null;
    @Input() public cacheConfig: FormCacheConfig;
    @Input() public getSuccessMessage: (...args: any) => string;
    @Input() public isPrefillNeeded: () => boolean;
    @Input() public afterPrefill: (() => void) | undefined;
    @Input() public onPrefillInit: (() => void) | undefined;
    @Output() public formSubmit: EventEmitter<TFormValue> = new EventEmitter();
    @Output() public sameValue: EventEmitter<SameFormValue> = new EventEmitter();
    @Output() public afterExpand: EventEmitter<void> = new EventEmitter();
    @ViewChild('accordion') accordion: MatExpansionPanel;
    @ViewChild('form') form: FormComponent;
    public isSubmited: boolean = false;
    public formNames = FormName;
    public successMessage: string | null;

    /**
     * Triggered when the form is submitted (user clicks next/save/submit button).
     */
    public onFormValue(formValue: TFormValue): void {
        this.successMessage = this.getSuccessMessage(formValue);
        this.accordion.close();
        this.formSubmit.emit(formValue);
        this.isSubmited = true;
    }

    /**
     * Triggered when the form refferance is created. It's a dedicated place to subscribe "form value change events".
     * @param formRef Refferance to accordion's form
     */
    public onFormInitialized(formRef: FormGroup): void {
        if (this.isPrefillNeeded()) {
            setTimeout(() => {
                this.onPrefillInit?.();
                if (formRef.invalid) {
                    this.accordion.open();
                    return;
                }
                const formValue = formRef.getRawValue();
                this.successMessage = this.getSuccessMessage(formValue);
                if (this.emitValueOnPrefill) {
                    this.formSubmit.emit(formValue);
                }
                this.afterPrefill?.();
            });
        }
    }

    constructor(protected changeDetectorRef: ChangeDetectorRef) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['isExpanded']?.currentValue) {
            this.open();
        }
        if (!changes['questions']?.currentValue || this.isPrefillNeeded()) {
            return;
        }

        this.successMessage = null;
        this.open();
    }

    public open(): void {
        this.changeDetectorRef.detectChanges();
        this.accordion?.open();
    }

    public onSameValue(form: SameFormValue) {
        this.sameValue.emit(form);
    }

    public onAfterExpand(event: any) {
        this.afterExpand.emit();
    }
}
