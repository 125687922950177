// Each value represent icon name
// Some icons are the same so the values are
export enum EnergySourcesIcons {
    'HEATING_OIL' = 'oil-boiler',
    'GAS' = 'gas-boiler',
    'ELECTRICITY' = 'electric-heater',
    'ELECTRICITY_HEATPUMP' = 'heat-pump',
    'PELLET' = 'pellet',
    'SOFTWOOD' = 'wood',
    'HARDWOOD' = 'wood'
}
