import { Component, OnInit } from '@angular/core';

import { FooterStore } from '../../../state/footer/footer.store';
import { SettingsStore } from '../../../state/settings/settings.store';

@Component({
    selector: 'vp-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    public readonly year = new Date().getFullYear();

    constructor(public footerStore: FooterStore, public settingsStore: SettingsStore) {}

    public ngOnInit(): void {
        this.footerStore.getApiInfo();
    }
}
