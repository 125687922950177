import { Injectable } from '@angular/core';
import { AccordionService, DistributionAndScheduleFormValue, FormOptions } from '@customer-apps/shared/interfaces';
import { Observable, filter, first, map, merge, withLatestFrom } from 'rxjs';
import { ProjectStore } from '../../state/project/project.store';
import { HeatPumpIntermediaryStore } from '../../state/heat-pump-intermediary/heat-pump-intermediary.store';
import { HeatloadFormOptionsService, HeatloadFormService } from '../../heatload/services';
import { TranslateService } from '@ngx-translate/core';
import { heatingScheduleSuccessMessageToPhraseKey } from '@customer-apps/shared/utils';
import { HeatPumpIntermediaryPrefillService } from './heat-pump-intermediary-prefill.service';
import { FormName } from '@customer-apps/shared/enums';
import { HeatPumpIntermediaryService } from './heat-pump-intermediary.service';
import { HeatloadPrefillMapper } from '../../services';

@Injectable({
    providedIn: 'root'
})
export class HeatPumpIntermediaryHeatingDistributionService implements AccordionService {
    constructor(
        private projectStore: ProjectStore,
        private heatPumpIntermediaryStore: HeatPumpIntermediaryStore,
        private heatPumpIntermediaryService: HeatPumpIntermediaryService,
        private formService: HeatloadFormService,
        private formOptionsService: HeatloadFormOptionsService,
        private translate: TranslateService,
        private prefillService: HeatPumpIntermediaryPrefillService,
        private prefillMapper: HeatloadPrefillMapper
    ) {}

    public questions$ = this.heatPumpIntermediaryStore.consumptionProfiles$.pipe(
        withLatestFrom(this.projectStore.project$, this.heatPumpIntermediaryStore.cache$),
        map(([consumptionProfiles, project, cache]) => ({
            consumptionProfiles,
            project,
            cache
        })),
        map(state => {
            if (!state.consumptionProfiles) {
                return null;
            }

            const projectPrefill = this.prefillMapper.projectToHeatingDistributionPrefill(state.project);
            const cachePrefill = this.prefillMapper.stateToHeatingDistributionPrefill(state.cache?.heatingDistribution);
            const options = this.formOptionsService.getHeatingDistributionFormOptions({
                prefill: cachePrefill || projectPrefill,
                profiles: state.consumptionProfiles,
                project: state.project!
            });
            return this.formService.getHeatingDistributionQuestions(options as FormOptions);
        })
    );

    public getSuccessMessage(formValue: DistributionAndScheduleFormValue): string {
        const distributionMethod = this.translate.instant(`HEATLOAD.HEATING_DISTRIBUTION.SUCCESS_MESSAGE.${formValue.distributionMethod}`);
        const temperatureUnit = this.translate.instant('MEASUREMENT_UNITS.DEGREE');

        const schedulePhraseKey = heatingScheduleSuccessMessageToPhraseKey[formValue.heatingSchedule];
        const schedule = schedulePhraseKey ? this.translate.instant(schedulePhraseKey) : undefined;

        const messageParts = [distributionMethod, `${formValue.heatingFlowTemperature} ${temperatureUnit}`, schedule];
        return messageParts.filter(Boolean).join(', ');
    }

    public isPrefillNeeded(): boolean {
        return !this.prefillService.isPrefilled(FormName.HeatingDistribution);
    }

    public afterPrefill(): void {
        this.prefillService.setAsPrefilled(FormName.HeatingDistribution);
    }

    public isExpanded$ = merge(this.isSubmitedPreviousFormSameValue$(), this.isPrefillCompleted$());

    private isSubmitedPreviousFormSameValue$() {
        return this.heatPumpIntermediaryStore.sameValueSubmited$.pipe(map(form => form?.formName === FormName.EnergyConsumption));
    }

    private isPrefillCompleted$(): Observable<boolean> {
        return this.prefillService.state$.pipe(
            withLatestFrom(this.projectStore.project$, (prefillState, project) => ({ prefillState, project })),
            map(({ prefillState, project }) => {
                return (
                    !this.heatPumpIntermediaryService.isSubsidyPossible(project!) && prefillState[FormName.HeatingDistribution].isPrefilled
                );
            }),
            filter(Boolean),
            first()
        );
    }
}
