import { GegEvaluationResponse, HeatGeneratorInformation, SystemItem } from '@customer-apps/api-planning-projects';
import { GEGSystemType } from '@customer-apps/shared/enums';
import { HeatGeneratorDetailsFormValue, HeatGeneratorFormValue, SameFormValue, SystemFormValue } from '@customer-apps/shared/interfaces';
import { createAction, props } from '@ngrx/store';

export namespace GEGActions {
    export const submitSystem = createAction('[GEG] Submit System', props<SystemFormValue>());

    export const extendSystem = createAction('[GEG] Extend System');

    export const submitCurrentSystemHeatGenerator = createAction(
        '[GEG] Submit Current System Heat Generator',
        props<HeatGeneratorFormValue>()
    );

    export const submitCurrentSystemHeatGeneratorDetails = createAction(
        '[GEG] Submit Current System Heat Generator Details',
        props<HeatGeneratorDetailsFormValue>()
    );

    export const submitNewSystemHeatGenerator = createAction('[GEG] Submit New System Heat Generator', props<HeatGeneratorFormValue>());

    export const getHeatGenerators = createAction('[GEG] Get Heat Generators', props<{ systemType: GEGSystemType }>());

    export const getHeatGeneratorsSuccess = createAction(
        '[GEG] Get Heat Generators Success',
        props<{ generators: HeatGeneratorInformation[] }>()
    );

    export const getHeatingSystemsForCurrentSystem = createAction(
        '[GEG] Get Heating Systems For Current System',
        props<{ installationYear: string }>()
    );

    export const getHeatingSystemsForCurrentSystemSuccess = createAction(
        '[GEG] Get Heating Systems For Current System Success',
        props<{ heatingSystems: SystemItem[] }>()
    );

    export const getHeatingSystemForNewSystem = createAction('[GEG] Get Heating System New System', props<{ installationYear: string }>());

    export const getHeatingSystemForNewSystemSuccess = createAction(
        '[GEG] Get Heating System New System Success',
        props<{ heatingSystem: SystemItem }>()
    );

    export const evaluateGEG = createAction('[GEG] Evaluate GEG');

    export const evaluateGEGSuccess = createAction('[GEG] Evaluate GEG Success', props<{ evaluation: GegEvaluationResponse }>());

    export const resetGEG = createAction('[GEG] Reset');

    export const cacheState = createAction('[GEG] Cache state');

    export const restoreStateFromCache = createAction('[GEG] Restore state from cache');

    export const resetEvaluatedGEGResponse = createAction('[GEG] Reset Evaluated GEG Response');

    export const submitSameValue = createAction('[GEG] Submit Same Value', props<SameFormValue>());

    export const resetSameValue = createAction('[GEG] Reset Same Value');
}
