export enum HeatloadHeaders {
    ByConsumption = 'HEATLOAD.CALCULATION_METHOD.CALC_BY_CONSUMPTION',
    ByLivingArea = 'HEATLOAD.CALCULATION_METHOD.CALC_BY_LIVING_AREA',
    ByBuildingHull = 'HEATLOAD.CALCULATION_METHOD.CALC_BY_BUILDING_HULL',
    ByCertificate = 'HEATLOAD.CALCULATION_METHOD.CALC_BY_ENERGY_CERT',
    HeatingLoad = 'HEATLOAD.TITLE',
    RecalculateHeatingLoad = 'RECALCULATE_HEATLOAD.TITLE',
    RecalcByBuildingHull = 'HEATLOAD.RECALCULATION_METHOD.RECALC_BY_BUILDING_HULL',
    RecalcByLivingSpace = 'HEATLOAD.RECALCULATION_METHOD.RECALC_BY_LIVING_AREA',
    RecalcByCustomValue = 'HEATLOAD.RECALCULATION_METHOD.RECALC_BY_CUSTOM_VALUE',
    RecalcByEnergyCertificate = 'HEATLOAD.RECALCULATION_METHOD.RECALC_BY_ENERGY_CERT',
    RecalcByConsumption = 'HEATLOAD.RECALCULATION_METHOD.RECALC_BY_CONSUMPTION'
}
