import { Pipe, PipeTransform } from '@angular/core';
import { ProjectStatus } from '@customer-apps/shared/enums';

@Pipe({
    name: 'isOfStatus'
})
export class IsOfStatusPipe implements PipeTransform {
    transform(projectStatus: ProjectStatus, compareStatuses: ProjectStatus[]): boolean {
        return compareStatuses.includes(projectStatus);
    }
}
