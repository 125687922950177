import { ViewportScroller } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, OnDestroy, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { auditTime, Subject, takeUntil } from 'rxjs';
import { FormKeys, FormName } from '@customer-apps/shared/enums';
import { ConfirmConfig, FormCacheConfig, FormFieldHighlights, SameFormValue } from '@customer-apps/shared/interfaces';
import { FormStateService, LodashService, QuestionControlService } from '@customer-apps/shared/services';
import { Question, QuestionBase } from '@customer-apps/shared/utils';

@Component({
    selector: 'vp-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnChanges, OnDestroy {
    /**
     * Helps to indentify the form whithin the other forms.
     * For ex. To inform parent component about edition state @see FormStateService
     */
    @Input() name: FormName;
    @Input() cacheConfig: FormCacheConfig | undefined;
    @Input() questions: Question[];
    @Input() customConfirmEnabled: boolean;
    @Input() highlights: FormFieldHighlights = {};

    private _customConfirm: ConfirmConfig;
    @Input() set customConfirm(value: ConfirmConfig) {
        this._customConfirm = value;
    }
    get customConfirm(): ConfirmConfig {
        return { ...this._customConfirm, disabled: !this.form.valid };
    }

    @Output() value: EventEmitter<any> = new EventEmitter();
    @Output() sameValue: EventEmitter<SameFormValue> = new EventEmitter();
    @Output() valueChange: EventEmitter<any> = new EventEmitter();
    @Output() afterQuestionsPatch: EventEmitter<{ [key: string]: FormControl }> = new EventEmitter();
    @Output() afterQuestionsAdd: EventEmitter<{ [key: string]: FormControl }> = new EventEmitter();
    @Output() afterQuestionsRemove: EventEmitter<FormKeys[]> = new EventEmitter();
    @Output() afterRetriveCache: EventEmitter<any> = new EventEmitter();
    @Output() formInitialized: EventEmitter<any> = new EventEmitter();
    public form: FormGroup;
    public isSubmited: boolean = false;
    private destroy$: Subject<void> = new Subject();
    /**
     * Caches the last submited form value and questions configuration.
     * It helps to bring back the last submited form value and state when user provides changes to the form and then decides to withdraw the changes.
     */
    private cache: { formValue?: any; questions?: Question[] } | null;

    constructor(
        private questionControl: QuestionControlService,
        private viewportScroller: ViewportScroller,
        public formStateService: FormStateService
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['questions']) {
            this.form = this.questionControl.toFormGroup(this.questions);
            this.onValueChange();
            this.formInitialized.emit(this.form);
            this.initCache();
        }
        if (changes['cacheConfig']) {
            this.initCache();
        }
    }

    public addQuestions(questions: Question[]): void {
        const questionsToAdd = questions.filter(question => {
            const newQuestionFormKey = this.questionControl.getQuestionFormKey(question);
            const questionFormKeys = this.questions.map(oldQuestion => this.questionControl.getQuestionFormKey(oldQuestion));
            return !questionFormKeys.includes(newQuestionFormKey);
        });

        if (!questionsToAdd.length) {
            return;
        }

        const newForm = this.questionControl.toFormGroup(questionsToAdd);

        this.afterQuestionsAdd.emit(newForm.controls as { [key: string]: FormControl });
        Object.keys(newForm.controls).forEach(item => {
            const control = newForm.controls[item];
            this.form.addControl(item, control, { emitEvent: false });
            if (control.value) {
                control.updateValueAndValidity();
            }
        });

        this.questions = [...this.questions, ...questionsToAdd].sort((a, b) => a.order - b.order);
    }

    public removeQuestions(formKeys: FormKeys[]): void {
        formKeys.forEach(key => {
            this.questions = this.questions.filter(question => {
                const formKey = this.questionControl.getQuestionFormKey(question);
                return !formKeys.includes(formKey as FormKeys);
            });
            this.form.removeControl(key, { emitEvent: false });
        });
        this.afterQuestionsRemove.emit(formKeys);
    }

    public patchQuestions(questions: Question[]): void {
        const questionsToPatch = new Map();
        questions.forEach(question => {
            const formKey = this.questionControl.getQuestionFormKey(question);
            questionsToPatch.set(formKey, question);
        });

        this.questions = this.questions
            .map(question => {
                const formKey = this.questionControl.getQuestionFormKey(question);
                if (!questionsToPatch.has(formKey)) {
                    return question;
                }
                const patch = questionsToPatch.get(formKey);
                this.updateQuestionValue(patch);
                this.updateQuestionValidators(patch);
                return { ...question, ...patch };
            })
            .sort((a, b) => a.order - b.order);

        const formKeys = Array.from(questionsToPatch.keys());
        const patchedControls = formKeys.reduce((acc, curr) => {
            acc[curr] = this.form.controls[curr];
            return acc;
        }, {});
        this.afterQuestionsPatch.emit(patchedControls as { [key: string]: FormControl });
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public onSubmit(): void {
        this.validate(this.form);
        if (!this.form.valid || (this.isValueDifferentThanCache && this.cacheConfig?.updateOnConfim)) {
            return;
        }
        const value = this.form.getRawValue();
        if (this.isValueSameAsCache) {
            this.sameValue.next({ formName: this.name, value });
            return;
        }
        this.setCache();
        this.formStateService.setEditedForm(null);
        this.value.next(value);
        this.isSubmited = true;
    }

    public updateCacheAndSubmit(): void {
        this.setCache();
        this.formStateService.setEditedForm(null);
        this.validate(this.form);
        if (!this.form.valid || this.isValueDifferentThanCache) {
            return;
        }
        const value = this.form.getRawValue();
        this.value.next(value);
        this.isSubmited = true;
    }

    public restoreValueFromCache() {
        if (!this.cache) {
            return;
        }
        this.questions = this.cache.questions!;
        this.form = this.questionControl.toFormGroup(this.questions);
        this.onValueChange();
        this.form.patchValue(this.cache.formValue);
        this.afterRetriveCache.emit(this.form);
        this.emitFormNameOnEdition();
    }

    public get isValueSameAsCache() {
        return this.cache && LodashService.isEqual(this.cache.formValue, this.form.getRawValue());
    }

    public get isValueDifferentThanCache() {
        return this.cache && !LodashService.isEqual(this.cache.formValue, this.form.getRawValue());
    }

    private updateQuestionValue(question: QuestionBase<any>): void {
        if (LodashService.isNil(question.value)) {
            return;
        }
        const control = this.form.get(question.key);
        control?.reset(question.value);
    }

    private updateQuestionValidators(question: QuestionBase<any>): void {
        if (LodashService.isNil(question.validators)) {
            return;
        }
        const control = this.form.get(question.key);
        control?.setValidators(question.validators);
    }

    private onValueChange(): void {
        this.form.valueChanges.pipe(auditTime(10), takeUntil(this.destroy$)).subscribe(value => {
            this.valueChange.emit(value);
            this.emitFormNameOnEdition();
        });
    }

    private setCache() {
        const formValue = this.form.getRawValue();
        if (this.cacheConfig?.enabled) {
            this.cache = this.cache || {};
            this.cache.formValue = formValue;
            this.cache.questions = LodashService.cloneDeep(this.questions);
        }
    }

    private validate(group: FormGroup): void {
        const controlNames: string[] = Object.keys(group.controls);
        let firstInvalidQuestionFormKey: string | undefined;
        controlNames.forEach(name => {
            const control = group.controls[name];
            if (control instanceof FormGroup) {
                this.validate(control as FormGroup);
                return;
            }

            control.updateValueAndValidity({ emitEvent: false });
            control.markAsTouched();
            if (!firstInvalidQuestionFormKey && control.errors) {
                firstInvalidQuestionFormKey = name;
            }
        });
        if (firstInvalidQuestionFormKey) {
            this.viewportScroller.scrollToAnchor(firstInvalidQuestionFormKey);
        }
    }

    /**
     * Emits form name when it's beeing edited and resets the value when the form restores its cached value @see FormStateService
     */
    private emitFormNameOnEdition() {
        if (this.isValueDifferentThanCache) {
            this.formStateService.setEditedForm(this.name);
        } else {
            this.formStateService.setEditedForm(null);
        }
    }

    private initCache() {
        if (this.cacheConfig?.cacheInitialValue && this.form.valid) {
            this.setCache();
            return;
        }
        this.cache = null;
    }
}
