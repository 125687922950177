import { Injectable } from '@angular/core';
import { HeatloadPrefillService } from './heatload-prefill.service';
import { HeatloadStore } from '../../state/heatload/heatload.store';
import { TranslateService } from '@ngx-translate/core';
import { ProjectStore } from '../../state/project/project.store';
import { HeatloadFormService } from './heatload-form.service';
import { withLatestFrom, map, merge, Observable, filter, first } from 'rxjs';
import { CountryCode, EnergyCertMeasurementUnits, FormName, HeatLoadCalculationMethod } from '@customer-apps/shared/enums';
import { AccordionService, EnergyCertificateFormValue } from '@customer-apps/shared/interfaces';
import { HeatloadFormOptionsService } from './heatload-form-options.service';
import { HeatloadPrefillMapper } from '../../services';
import { HeatloadService } from './heatload.service';

@Injectable({
    providedIn: 'root'
})
export class EnergyCertificateService implements AccordionService {
    constructor(
        private heatloadPrefillService: HeatloadPrefillService,
        private prefillMapper: HeatloadPrefillMapper,
        private heatloadFormOptionsService: HeatloadFormOptionsService,
        private heatloadStore: HeatloadStore,
        private translate: TranslateService,
        private projectStore: ProjectStore,
        private heatloadService: HeatloadService,
        private heatloadFormService: HeatloadFormService
    ) {}

    public questions$ = this.heatloadStore.calculationMethod$.pipe(
        withLatestFrom(this.projectStore.project$, this.heatloadStore.cache$),
        map(([method, project, cache]) => ({
            isEnergyCertificate: method?.value === HeatLoadCalculationMethod.EnergyCertificate,
            project,
            cache
        })),
        map(state => {
            if (!state.isEnergyCertificate) {
                return null;
            }

            const projectPrefill = this.prefillMapper.projectToEnergyCertificatePrefill(state.project);
            const cachePrefill = this.prefillMapper.stateToEnergyCertificatePrefill(state.cache?.energyCertificate);
            const formOptions = this.heatloadFormOptionsService.getEnergyCertificateFormOptions({
                countryCode: state.project!.address.countryCode as CountryCode,
                prefill: cachePrefill || projectPrefill
            });
            return this.heatloadFormService.getEnergyCertificateQuestions(formOptions);
        })
    );

    public isPrefillNeeded(): boolean {
        return this.heatloadService.isRecalculation() && !this.heatloadPrefillService.isPrefilled(FormName.EnergyCertificate);
    }

    public getSuccessMessage(formValue: EnergyCertificateFormValue): string {
        return Object.entries(formValue)
            .map(value => {
                const result = value[1];
                const unitTranslationKey = EnergyCertMeasurementUnits[value[0] as keyof typeof EnergyCertMeasurementUnits];
                return `${result} ${this.translate.instant(unitTranslationKey)}`;
            })
            .join(', ');
    }

    public afterPrefill(): void {
        this.heatloadPrefillService.setAsPrefilled(FormName.EnergyCertificate);
    }

    public isExpanded$ = merge(this.isSubmitedCalculationMethodSameValue$(), this.prefillCompletedByEnergyCertificate$());

    private isSubmitedCalculationMethodSameValue$() {
        return this.heatloadStore.sameValueSubmited$.pipe(
            map(
                form =>
                    form?.formName === FormName.CalculationMethod &&
                    form.value.methods.standardMethod === HeatLoadCalculationMethod.EnergyCertificate
            )
        );
    }

    private prefillCompletedByEnergyCertificate$(): Observable<boolean> {
        return this.heatloadPrefillService.state$.pipe(
            map(prefillState => {
                const lastAccordionToBePrefilled = FormName.EnergyCertificate;
                return prefillState[lastAccordionToBePrefilled].isPrefilled && this.heatloadService.isRecalculation();
            }),
            filter(Boolean),
            first()
        );
    }
}
