import { AbstractControl, FormGroup } from '@angular/forms';
import { FormKeys } from '@customer-apps/shared/enums';

/**
 * HeatGeneratorDetails form is dynamic and its questions are miscellenious based on selected energy source and installation year.
 * The form is valid when all the dynamic questions are filled but we don't know what questions are built in the form.
 * Hence that we assume the form is filled and valid when the last question (nominal power) is filled.
 */
export const heatGeneratorDetailsValidator = (control: AbstractControl) => {
    const nominalPower = <FormGroup>control.get([FormKeys.NominalPower]);
    return nominalPower?.valid ? null : { message: 'The form is not fully completed.' };
};
