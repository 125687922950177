// AoT requires an exported function for factories
import { HttpBackend } from '@angular/common/http';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { environment } from '../../environments/environment';

export function HttpLoaderFactory(http: HttpBackend) {
    return new MultiTranslateHttpLoader(http, [
        // host app i18n assets
        {
            prefix: `${environment.everestApiUrl}/translations/v2/phraseapp-proxy/projects/daae8a6956d574b1552b935daf85ab8d/locales/`,
            suffix: '/download?file_format=simple_json'
        },
        // dsw lib assets
        {
            prefix: `${environment.everestApiUrl}/translations/v2/phraseapp-proxy/projects/ec38b083a23c58c604a49d7f443dd7f2/locales/`,
            suffix: '/download?file_format=simple_json'
        }
    ]);
}
