import { HeatGeneratorType } from '@customer-apps/shared/enums';
import { EnumDictionary } from '../types';

export const gegHeatGeneratorToPhraseKey: EnumDictionary<HeatGeneratorType, string> = {
    [HeatGeneratorType.ElectricHeater]: 'HEATLOAD.ENERGY_SOURCE.HEAT_GENERATOR.ELECTRIC_HEATER',
    [HeatGeneratorType.GasBoiler]: 'HEATLOAD.ENERGY_SOURCE.HEAT_GENERATOR.GAS_CONDENSING_BOILER',
    [HeatGeneratorType.HeatPump]: 'HEATLOAD.ENERGY_SOURCE.HEAT_GENERATOR.HEAT_PUMP',
    [HeatGeneratorType.OilBoiler]: 'HEATLOAD.ENERGY_SOURCE.HEAT_GENERATOR.OIL_CONDENSING_BOILER',
    [HeatGeneratorType.WoodStove]: 'HEATLOAD.ENERGY_SOURCE.HEAT_GENERATOR.WOOD_STOVE',
    [HeatGeneratorType.SolarThermal]: 'HEATLOAD.ENERGY_SOURCE.HEAT_GENERATOR.SOLAR_THERMAL',
    [HeatGeneratorType.DistrictHeating]: 'HEATLOAD.ENERGY_SOURCE.HEAT_GENERATOR.DISTRICT_HEATING'
};
