import { Pipe, PipeTransform } from '@angular/core';
import { HeatLoadCalculationPartial } from '@customer-apps/api-planning-projects';
import { LodashService } from '@customer-apps/shared/services';

@Pipe({
    name: 'totalHeatload'
})
export class TotalHeatloadPipe implements PipeTransform {
    transform(calculation: HeatLoadCalculationPartial | null): number | null {
        if (LodashService.isNil(calculation?.baseHeatLoad)) {
            return null;
        }

        return (calculation?.baseHeatLoad || 0) + (calculation?.lockTimeHeatLoad || 0) + (calculation?.warmWaterHeatLoad || 0);
    }
}
