import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Message } from '@customer-apps/shared/interfaces';

@Component({
    selector: 'vp-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss']
})
export class MessageComponent {
    @Input() public message: Message;

    @Output() public remove = new EventEmitter<Message>();
}
