import { CountryLanguageCodes } from '@customer-apps/shared/enums';
import { EnumDictionary } from '@customer-apps/shared/utils';

export const LanguageCountryDictionary: EnumDictionary<CountryLanguageCodes, string> = {
    [CountryLanguageCodes.deDE]: 'LANGUAGE_COUNTRY.GERMANY_GERMAN',
    [CountryLanguageCodes.enDE]: 'LANGUAGE_COUNTRY.GERMANY_ENGLISH',
    [CountryLanguageCodes.frBE]: 'LANGUAGE_COUNTRY.BELGIUM_FRENCH',
    [CountryLanguageCodes.nlBE]: 'LANGUAGE_COUNTRY.BELGIUM_DUTCH',
    [CountryLanguageCodes.deAT]: 'LANGUAGE_COUNTRY.AUSTRIA_GERMAN',
    [CountryLanguageCodes.deCH]: 'LANGUAGE_COUNTRY.SWITZERLAND_GERMAN',
    [CountryLanguageCodes.frCH]: 'LANGUAGE_COUNTRY.SWITZERLAND_FRENCH',
    [CountryLanguageCodes.frFR]: 'LANGUAGE_COUNTRY.FRANCE_FRENCH',
    [CountryLanguageCodes.esES]: 'LANGUAGE_COUNTRY.SPAIN_SPANISH',
    [CountryLanguageCodes.plPL]: 'LANGUAGE_COUNTRY.POLAND_POLISH',
    [CountryLanguageCodes.csCZ]: 'LANGUAGE_COUNTRY.CZECH_REPUBLIC_CZECH',
    [CountryLanguageCodes.skSK]: 'LANGUAGE_COUNTRY.SLOVAKIA_SLOVAKIAN'
};
