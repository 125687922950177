import { Action, createReducer, on } from '@ngrx/store';
import { SettingsState } from '@customer-apps/shared/interfaces';
import { LocaleUtils } from '@customer-apps/shared/utils';
import { SettingsActions } from './settings.actions';

export const initialSettingsState: SettingsState = {
    isInitilized: false,
    locale: LocaleUtils.defaultBrowserLocale,
    salesOrganizations: null
};

const reducer = createReducer(
    initialSettingsState,
    on(SettingsActions.setLocale, state => ({
        ...state,
        isInitilized: true
    })),
    on(SettingsActions.setLocale, SettingsActions.changeLocale, (state, { locale }) => ({
        ...state,
        locale
    })),
    on(SettingsActions.getSalesOrganizationsSuccess, (state, { organizations }) => ({
        ...state,
        salesOrganizations: organizations
    }))
);

export function settingsReducer(state: SettingsState | undefined, action: Action) {
    return reducer(state, action);
}
