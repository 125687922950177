import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LanguageCodes } from '@customer-apps/shared/enums';

@Component({
    selector: 'vp-tuv-logo',
    templateUrl: './tuv-logo.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TuvLogoComponent {
    private readonly logoSourcePlaceholder = '/assets/images/tuv/TR-Testmark_0000038703_{{LANGUAGE}}.png';

    @Input() width: number = 376;
    @Input() locale: string = '';

    public get logoSource(): string {
        const [language, _country] = this.locale.split('-') || navigator.language.split('-');
        const logoLanguage = language.toLocaleLowerCase() === LanguageCodes.de ? LanguageCodes.de : LanguageCodes.en;
        return this.logoSourcePlaceholder.replace('{{LANGUAGE}}', logoLanguage);
    }
}
