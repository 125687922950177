import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, combineLatestWith, filter, map, tap, withLatestFrom } from 'rxjs';
import { ProjectStore } from '../state/project/project.store';
import { RoutePaths, RouterLinks } from '@customer-apps/shared/enums';
import { SettingsStore } from '../state/settings/settings.store';
import { ProjectResponseGetById } from '@customer-apps/api-planning-projects';
import { LocaleUtils } from '@customer-apps/shared/utils';
import { EnvironmentService } from '../services';

@Injectable({
    providedIn: 'root'
})
export class IsCorrectLocaleGuardService {
    constructor(
        private router: Router,
        private projectStore: ProjectStore,
        private environment: EnvironmentService,
        private settingsStore: SettingsStore
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.settingsStore.isInitialized$.pipe(
            filter(Boolean),
            combineLatestWith(this.projectStore.project$),
            map(([_, project]) => project),
            tap(project => {
                const projectId = route.paramMap.get('id');
                if (!projectId) {
                    this.router.navigate([RoutePaths.Error404]);
                }

                if (!project) {
                    this.projectStore.getProject({ id: projectId! });
                }
            }),
            filter(Boolean),
            withLatestFrom(this.settingsStore.countryCode$, (project, countryCode) => ({ project, countryCode })),
            map(({ project, countryCode }) => {
                const isCorrectLocale = countryCode === project.countryCode;
                const localeByProjectSalesOrgCountryCode = this.getLocaleByProjectSalesOrg(project);
                if (!isCorrectLocale && localeByProjectSalesOrgCountryCode) {
                    const url = `${this.environment.baseUrl}${RouterLinks.Project}${project.id}`;
                    this.settingsStore.changeLocale(localeByProjectSalesOrgCountryCode, url);
                }
                return isCorrectLocale;
            })
        );
    }

    private getLocaleByProjectSalesOrg(project: ProjectResponseGetById): string | undefined {
        return LocaleUtils.supportedLocales.find(locale => project.countryCode === LocaleUtils.getCountryCode(locale));
    }
}
