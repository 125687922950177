import { Pipe, PipeTransform } from '@angular/core';
import { FormName } from '@customer-apps/shared/enums';

@Pipe({
    name: 'disableOn'
})
export class DisableOnPipe implements PipeTransform {
    transform(editedFormName: FormName | null, names: FormName[]): boolean {
        if (!editedFormName) {
            return false;
        }
        return names.includes(editedFormName);
    }
}
