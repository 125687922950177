/**
 * Defines a special profile, which describes how the heating/warm water is consumed in the building.
 */
export enum ConsumptionProfile {
    DayAndNocturnalFall = 'HEAT_WITH_DAY_AND_NOCTURNAL_FALL',
    NocturnalFall = 'HEAT_WITH_NOCTURNAL_FALL',
    MultiFamilyHouse = 'DEFAULT_MULTI_FAMILY_HOUSE',
    HeatPump = 'HEATPUMP_MODE',
    SingleFamilyHouseMorning = 'ONE_FAMILY_HOUSE_MORNING_PEAK',
    SingleFamilyHouseEvening = 'ONE_FAMILY_HOUSE_EVENING_PEAK',
    Office = 'OFFICE',
    School = 'SCHOOL',
    Hospital = 'HOSPITAL',
    Hotel = 'HOTEL',
    GovernmentAgency = 'GOVERNMENT_AGENCY',
    OneShift = 'INDUSTRY_1_SHIFT_OPERATION_WITHOUT_WEEKEND',
    TwoShifts = 'INDUSTRY_2_SHIFT_OPERATION_WITHOUT_WEEKEND',
    ThreeShifts = 'INDUSTRY_3_SHIFT_OPERATION_WITHOUT_WEEKEND',
    ThreeShiftsWithWeekend = 'INDUSTRY_3_SHIFT_OPERATION_WITH_WEEKEND'
}
