/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { EnergyPricesResponseGetRecordsList } from '../../models/energy-prices-response-get-records-list';

export interface EconomicsControllerGetEnergyPrices$Params {

/**
 * ISO standard Alpha-2 country code.The building address country code.
 */
  countryCode: 'AT' | 'BE' | 'CH' | 'CZ' | 'DE' | 'ES' | 'FR' | 'NL' | 'PL' | 'SK';
}

export function economicsControllerGetEnergyPrices(http: HttpClient, rootUrl: string, params: EconomicsControllerGetEnergyPrices$Params, context?: HttpContext): Observable<StrictHttpResponse<EnergyPricesResponseGetRecordsList>> {
  const rb = new RequestBuilder(rootUrl, economicsControllerGetEnergyPrices.PATH, 'get');
  if (params) {
    rb.path('countryCode', params.countryCode, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<EnergyPricesResponseGetRecordsList>;
    })
  );
}

economicsControllerGetEnergyPrices.PATH = '/planning-projects/v1-develop/economics/energy-prices/{countryCode}';
