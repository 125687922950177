import { LOCALE_ID, Provider } from '@angular/core';
import { LocaleUtils } from '@customer-apps/shared/utils';
import { environment } from '../../environments/environment';

class LocaleId extends String {
    override toString(): string {
        const storedLocale = localStorage.getItem(environment.storageKeys.locale) || LocaleUtils.defaultLocale;
        return LocaleUtils.supportedLocales.some(locale => locale === storedLocale) ? storedLocale : LocaleUtils.defaultLocale;
    }

    override valueOf(): string {
        return this.toString();
    }
}

export const LocaleProvider: Provider = {
    provide: LOCALE_ID,
    useClass: LocaleId
};
