<div *ngIf="message" class="message message-{{ message.type }} vp-border-box p-3 d-flex flex-row vp-gap-8 at-message">
    <vp-icon [icon]="message.type | messageTypeToIcon" class="me-2 icon-{{ message.type }}"></vp-icon>
    <div class="d-flex flex-column vp-w-100">
        <div class="d-flex justify-content-between align-items-start">
            <div>
                <span class="vp-bold" *ngIf="message.title" data-testid="message-title">{{ message.title | translate }}</span>
                <div [class.mt-2]="message.title">
                    <p class="mb-0 text-small" data-testid="message-content">{{ message.content | translate }}</p>
                    <ul *ngIf="message.contentList" class="mb-0" data-testid="message-content-list">
                        <li *ngFor="let item of message.contentList">
                            {{ item | translate }}
                        </li>
                    </ul>
                </div>
            </div>
            <vp-icon
                *ngIf="message.closable"
                data-testid="message-close-button"
                icon="cross"
                class="vp-pointer at-close-message"
                (click)="remove.emit(message)"
            ></vp-icon>
        </div>
        <div *ngIf="message.buttonLabel" class="d-flex vp-gap-16 mt-2">
            <button
                *ngIf="message.routerLink || message.action"
                mat-button
                class="vp-button vp-button-no-padding mt-auto px-0 at-action-button"
                color="primary"
                data-testid="message-action-button"
                (click)="message.action ? message.action() : null"
                [disabled]="message.disabled"
                disableRipple="true"
                [routerLink]="message.routerLink"
                [queryParams]="message.routerLinkQueryParams"
            >
                <ng-container *ngTemplateOutlet="buttonContentElement; context: { $implicit: message.buttonLabel }"></ng-container>
            </button>
            <a
                *ngIf="message.url"
                disableRipple="true"
                mat-button
                class="vp-button vp-button-no-padding mt-auto px-0 at-action-button"
                color="primary"
                data-testid="message-action-link"
                [disabled]="message.disabled"
                [href]="message.url"
            >
                <ng-container *ngTemplateOutlet="buttonContentElement; context: { $implicit: message.buttonLabel }"></ng-container>
            </a>
        </div>
        <div *ngIf="message.links" class="d-flex vp-gap-16 mt-2">
            <ng-container *ngFor="let link of message.links">
                <button
                    *ngIf="link.routerLink"
                    mat-button
                    class="vp-button mt-auto at-action-button"
                    color="primary"
                    data-testid="message-action-button"
                    [disabled]="link.disabled"
                    [routerLink]="link.routerLink"
                    [queryParams]="link.routerLinkQueryParams"
                >
                    <ng-container *ngTemplateOutlet="buttonContentElement; context: { $implicit: link.label }"></ng-container>
                </button>
                <a
                    *ngIf="link.url"
                    disableRipple="true"
                    mat-button
                    class="vp-button vp-button-no-padding mt-auto px-0 at-action-button"
                    color="primary"
                    data-testid="message-action-link"
                    [disabled]="link.disabled"
                    [href]="link.url"
                >
                    <ng-container *ngTemplateOutlet="buttonContentElement; context: { $implicit: link.label }"></ng-container>
                </a>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #buttonContentElement let-buttonLabel>
    <span class="button-title">{{ buttonLabel | translate }}</span>
    <vp-icon icon="arrow-right" class="vp-icon-s"></vp-icon>
</ng-template>
