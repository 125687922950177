import { Injectable } from '@angular/core';
import { AccordionService, HeatGeneratorDetailsFormValue, HeatGeneratorDetailsOptions } from '@customer-apps/shared/interfaces';
import { BehaviorSubject, EMPTY, Observable, distinctUntilChanged, map, merge, mergeMap, of, tap, withLatestFrom } from 'rxjs';
import { ProjectStore } from '../../state/project/project.store';
import { HeatPumpIntermediaryStore } from '../../state/heat-pump-intermediary/heat-pump-intermediary.store';
import { HeatloadFormOptionsService, HeatloadFormService } from '../../heatload/services';
import { Question } from '@customer-apps/shared/utils';
import { BuildingType, FormName } from '@customer-apps/shared/enums';
import { TranslateService } from '@ngx-translate/core';
import { HeatPumpIntermediaryPrefillService } from './heat-pump-intermediary-prefill.service';
import { HeatloadPrefillMapper } from '../../services';

@Injectable({
    providedIn: 'root'
})
export class HeatPumpIntermediaryHeatGeneratorDetailsService implements AccordionService {
    constructor(
        private projectStore: ProjectStore,
        private heatPumpIntermediaryStore: HeatPumpIntermediaryStore,
        private prefillMapper: HeatloadPrefillMapper,
        private formService: HeatloadFormService,
        private formOptionsService: HeatloadFormOptionsService,
        private translate: TranslateService,
        private prefillService: HeatPumpIntermediaryPrefillService
    ) {
        this.initBuildingConstructionYear();
    }

    public questions$: Observable<Question[] | null> = merge(this.onEnergySource$(), this.onHeatingSystems$()).pipe(distinctUntilChanged());
    public isExpanded$ = this.heatPumpIntermediaryStore.sameValueSubmited$.pipe(map(form => form?.formName === FormName.EnergySource));
    /**
     * Sometimes it is possible only to prefill just the installation year. It is needed to send an event that the prefilling is finished event though it was not a full prefill.
     */
    private isConstructionYearPrefillFinished$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private buildingConstructionYear$: BehaviorSubject<number | undefined>;

    public getSuccessMessage(formValue: HeatGeneratorDetailsFormValue): string {
        const unit = this.translate.instant('MEASUREMENT_UNITS.KW');
        const installation = this.translate.instant(`HEATLOAD.HEAT_GENERATOR_DETAILS.BOILER_TYPE.${formValue.boilerType}`);
        return `${installation}, ${formValue.installationYear}, ${formValue.nominalPower} ${unit}`;
    }

    /**
     * Indicates a moment when a prefill with form value emition is needed.
     * Usually it changes its value once, initially.
     */
    public isPrefillNeeded(): boolean {
        return (
            Boolean(this.buildingConstructionYear$.value) &&
            !this.prefillService.isPrefilled(FormName.HeatGeneratorDetails) &&
            !this.isConstructionYearPrefillFinished$.value
        );
    }

    /**
     * Indicates a moment when a prefill without form value emition is needed.
     * Usually it changes its value many times. Whenever the form has to be filled again
     * and there is a prefill source available (project data, or store cache data)
     */
    public isSimplePrefillNeeded(): boolean {
        return !this.isConstructionYearPrefillFinished$.value;
    }

    public afterPrefill(): void {
        this.prefillService.setAsPrefilled(FormName.HeatGeneratorDetails);
    }

    public onPrefillInit(): void {
        this.toggleIsConstructionYearPrefillFinished(true);
    }

    public onSimplePrefillInit(): void {
        this.toggleIsConstructionYearPrefillFinished(true);
    }

    public toggleIsConstructionYearPrefillFinished(value: boolean): void {
        this.isConstructionYearPrefillFinished$.next(value);
    }

    /**
     * Here we return a clean UNPREFILLED question set. The question set for just component initialization.
     */
    private onEnergySource$() {
        return this.heatPumpIntermediaryStore.selectedHeatingEnergySource$.pipe(
            withLatestFrom(this.projectStore.project$, this.heatPumpIntermediaryStore.cache$),
            map(([energySource, project, cache]) => ({
                energySource,
                project,
                cache
            })),
            mergeMap(state => {
                if (!state.energySource) {
                    return of(null);
                }

                const cacheInstallationYear = state.cache?.heatGeneratorDetails?.installationYear;
                const installationConstructionYear = state.project!.building.currentHeatingSystem?.installationConstructionYear;
                const installationYearForHeatingSystems =
                    cacheInstallationYear || installationConstructionYear || this.buildingConstructionYear$.value;
                if (this.isSimplePrefillNeeded() && installationYearForHeatingSystems) {
                    this.heatPumpIntermediaryStore.getHeatingSystems(`${installationYearForHeatingSystems}`);
                    return EMPTY;
                }

                const options: HeatGeneratorDetailsOptions = this.formOptionsService.getHeatGeneratorDetailsFormOptions({
                    buildingType: state.project!.building.buildingType as BuildingType
                });

                const questions = this.formService.getHeatGeneratorDetailsQuestions(options);
                return of(questions);
            })
        );
    }

    /**
     * Here we return a PREFILLED question set. The question set for just component initialization.
     */
    private onHeatingSystems$() {
        return this.heatPumpIntermediaryStore.heatingSystems$.pipe(
            withLatestFrom(
                this.projectStore.project$,
                this.heatPumpIntermediaryStore.selectedHeatingEnergySource$,
                this.heatPumpIntermediaryStore.cache$
            ),
            map(([heatingSystems, project, energySourceState, cache]) => ({
                heatingSystems,
                project,
                energySourceState,
                cache
            })),
            mergeMap(state => {
                if (!this.isSimplePrefillNeeded()) {
                    return EMPTY;
                }
                if (!state.heatingSystems) {
                    return of(null);
                }

                const cachePrefill = this.prefillMapper.stateToHeatGeneratorDetailsPrefill(state.cache?.heatGeneratorDetails);
                const projectPrefill = this.prefillMapper.projectToHeatGeneratorDetailsPrefill(state.project);
                const options: HeatGeneratorDetailsOptions = this.formOptionsService.getHeatGeneratorDetailsFormOptions({
                    buildingType: state.project!.building.buildingType as BuildingType,
                    prefill: cachePrefill || projectPrefill,
                    energySourceState: state.energySourceState,
                    heatingSystems: state.heatingSystems,
                    buildingConstructionYear: this.buildingConstructionYear$.value
                });

                const questions = this.formService.getHeatGeneratorDetailsQuestions(options);

                return of(questions);
            })
        );
    }

    private initBuildingConstructionYear(): void {
        this.projectStore.project$
            .pipe(tap(project => (this.buildingConstructionYear$ = new BehaviorSubject(project?.building.buildingConstructionYear))))
            .subscribe();
    }
}
