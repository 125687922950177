import { WindowType } from '@customer-apps/shared/enums';
import { EnumDictionary } from '@customer-apps/shared/utils';

export const windowTypeToPhrase: EnumDictionary<WindowType, string> = {
    [WindowType.DoubleGlazing]: 'HEATLOAD.WINDOWS_AND_GLAZING.GLAZING_TYPE.DOUBLE_GLAZING',
    [WindowType.SingleGlazing]: 'HEATLOAD.WINDOWS_AND_GLAZING.GLAZING_TYPE.SINGLE_GLAZING',
    [WindowType.InsulatedGlazingAluminum]: 'HEATLOAD.WINDOWS_AND_GLAZING.GLAZING_TYPE.INSULATED_GLAZING_ALUMINIUM',
    [WindowType.InsulatedGlazingPlastic]: 'HEATLOAD.WINDOWS_AND_GLAZING.GLAZING_TYPE.INSULATED_GLAZING_PLASTIC',
    [WindowType.TripleGlazingPassive]: 'HEATLOAD.WINDOWS_AND_GLAZING.GLAZING_TYPE.TRIPLE_GLAZING_PASSIVE_HOUSE_FRAME',
    [WindowType.TripleGlazingWood]: 'HEATLOAD.WINDOWS_AND_GLAZING.GLAZING_TYPE.TRIPLE_GLAZING_IMPROVED_WOODEN_FRAME'
};
