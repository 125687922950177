/**
 * The detail type of the product.
 * Product can be a part of the device, a device
 * or even a special package plan like "Heat Pump Cooling Package".
 */
export enum ProductType {
    HybridAirHeatPump = 'HYBRID_AIR_HEAT_PUMP',
    WaterHeatPump = 'GROUNDWATER_HEAT_PUMP',
    GroundHeatPump = 'SOIL_HEAT_PUMP',
    AirHeatPump = 'AIR_HEAT_PUMP',
    MicroChp = 'MICRO_CHP',
    GasBoiler = 'GAS_BOILER',
    HeatingOilBoiler = 'HEATING_OIL_BOILER',
    HardwoodBoiler = 'HARDWOOD_BOILER',
    SoftwoodBoiler = 'SOFTWOOD_BOILER',
    PelletBoiler = 'PELLET_BOILER',
    HybridHeatingDevice = 'HYBRID_HEATING_DEVICE',
    ElectricHeater = 'ELECTRIC_HEATER',
    Photovoltaic = 'PHOTOVOLTAIC', // legacy
    PhotovoltaicModule = 'PHOTOVOLTAIC_MODULE',
    PhotovoltaicInverter = 'PHOTOVOLTAIC_INVERTER',
    PhotovoltaicBatteryInverter = 'PHOTOVOLTAIC_BATTERY_INVERTER',
    PhotovoltaicHybridInverter = 'PHOTOVOLTAIC_HYBRID_INVERTER',
    PhotovoltaicBatteryModule = 'PHOTOVOLTAIC_BATTERY_MODULE',
    PhotovoltaicAllInOneSystem = 'PHOTOVOLTAIC_ALL_IN_ONE_SYSTEM',
    PhotovoltaicPanelModule = 'PHOTOVOLTAIC_PANEL_MODULE',
    PowerInverter = 'POWER_INVERTER', // legacy
    PhotovoltaicModuleInverter = 'PHOTOVOLTAIC_MODULE_INVERTER',
    Solar = 'SOLAR',
    BatteryStorage = 'BATTERY_STORAGE', // legacy
    PhotovoltaicBattery = 'PHOTOVOLTAIC_BATTERY',
    HybridBatteryStorage = 'HYBRID_BATTERY_STORAGE', // legacy
    Ventilation = 'VENTILATION',
    VentilationDuctSystem = 'VENTILATION_DUCT_SYSTEM',
    EnergyManagementSystem = 'ENERGY_MANAGEMENT_SYSTEM',
    HeatPumpCoolingPackage = 'HEAT_PUMP_COOLING_PACKAGE',
    DHWCylinder = 'DOMESTIC_HOT_WATER_CYLINDER',
    FreshWaterModule = 'FRESH_WATER_MODULE',
    HeatExchanger = 'HEAT_EXCHANGER',
    HotWaterBufferTank = 'HOT_WATER_BUFFER_TANK',
    GeoCollector = 'GEO_COLLECTOR',

    /**
     * Products marked as OTHER are all accessories
     */
    Other = 'OTHER'
}
