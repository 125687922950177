import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
    AppState,
    ProjectFormValue,
    ProjectsFilter,
    ProjectsOrder,
    ProjectsPagination,
    ProjectsQueryParams
} from '@customer-apps/shared/interfaces';
import { ProjectBasicGetRecordsList } from '@customer-apps/api-planning-projects';
import { ProjectsActions } from './projects.actions';
import { ProjectsSelectors } from './projects.selectors';

@Injectable({ providedIn: 'root' })
export class ProjectsStore {
    public state$ = this.store$.pipe(select(ProjectsSelectors.getState()));
    public projectFormValue$ = this.store$.pipe(select(ProjectsSelectors.getProjectFormValue()));
    public filtersValue$ = this.store$.pipe(select(ProjectsSelectors.getFiltersValue()));
    public hasFiltersDefined$ = this.store$.pipe(select(ProjectsSelectors.getHasFiltersDefined()));
    public orderValue$ = this.store$.pipe(select(ProjectsSelectors.getOrderValue()));

    constructor(private store$: Store<AppState>) {}

    handleRouteQueryParams(queryParams: ProjectsQueryParams): void {
        this.store$.dispatch(ProjectsActions.handleRouteQueryParams({ queryParams }));
    }

    pageProjects(params: ProjectsPagination): void {
        this.store$.dispatch(ProjectsActions.pageProjects({ params }));
    }

    filterProjects(params: ProjectsFilter): void {
        this.store$.dispatch(ProjectsActions.filterProjects({ params }));
    }

    orderProjects(params: ProjectsOrder): void {
        this.store$.dispatch(ProjectsActions.orderProjects({ params }));
    }

    clearProjects(): void {
        this.store$.dispatch(ProjectsActions.clearProjects());
    }

    duplicateProject(projectId: string, projectName: string): void {
        this.store$.dispatch(ProjectsActions.duplicateProject({ projectId, projectName }));
    }

    deleteProject(project: ProjectBasicGetRecordsList): void {
        this.store$.dispatch(ProjectsActions.deleteProject({ projectId: project.id! }));
    }

    goToProjectDetails(project: ProjectBasicGetRecordsList): void {
        this.store$.dispatch(ProjectsActions.goToProjectDetails({ projectId: project.id! }));
    }

    backToProjects(): void {
        this.store$.dispatch(ProjectsActions.backToProjects());
    }

    buildingValidateAddress(projectFormValue: ProjectFormValue): void {
        this.store$.dispatch(ProjectsActions.buildingValidateAddress({ projectFormValue }));
    }
}
