<mat-table [dataSource]="data" [trackBy]="trackByFn">
    <ng-container [matColumnDef]="column.name" *ngFor="let column of columns">
        <mat-header-cell *matHeaderCellDef [style.flex]="column.flex">
            <div class="d-flex align-items-center vp-gap-4">
                <div class="text-small-bolder">{{ column.title | translate }}</div>
                <ng-container *ngIf="column.orderBy">
                    <ng-container *ngIf="order?.orderBy === column?.orderBy; else noOrderEl">
                        <button mat-icon-button class="vp-button vp-button-s" (click)="onOrderChange(column.orderBy, order?.orderType)">
                            <vp-icon icon="order-{{ order?.orderType | lowercase }}" class="vp-icon-auto">
                                <span class="path1"></span><span class="path2"></span>
                            </vp-icon>
                        </button>
                    </ng-container>
                    <ng-template #noOrderEl>
                        <button mat-icon-button class="vp-button vp-button-s" (click)="onOrderChange(column.orderBy)">
                            <vp-icon icon="order" class="vp-icon-auto"></vp-icon>
                        </button>
                    </ng-template>
                </ng-container>
            </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index" [style.flex]="column.flex">
            <ng-container *ngIf="column.templateRef; else displayEl">
                <div class="cell-wrapper">
                    <ng-container *ngTemplateOutlet="column.templateRef; context: { $implicit: { row: row, index: i } }"> </ng-container>
                </div>
            </ng-container>

            <ng-template #displayEl>
                <div class="cell-wrapper cell-wrapper-ellipsis">
                    <ng-container>{{ row[column.name] ?? '' }}</ng-container>
                </div>
            </ng-template>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
        [ngClass]="{ 'vp-pointer': pointer, 'mat-row-non-clickable': !pointer }"
        *matRowDef="let row; let index = index; columns: displayedColumns"
        (click)="rowClick.emit(row)"
        [cdkContextMenuDisabled]="!contextMenuTemplateRef"
        [cdkContextMenuTriggerFor]="contextMenuTemplateRef"
        [cdkContextMenuTriggerData]="{ $implicit: { row: row, index: index } }"
    ></mat-row>
</mat-table>
