import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'languageCode'
})
export class LanguageCodePipe implements PipeTransform {
    transform(countryLanguageCode: string | null): string {
        return countryLanguageCode ? countryLanguageCode.slice(0, 2).toLowerCase() : '';
    }
}
