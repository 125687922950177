import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, map, tap } from 'rxjs';
import { ProjectAccessStore } from '../state/project-access/project-access.store';
import { RoutePaths, RouterLinks } from '@customer-apps/shared/enums';

@Injectable({
    providedIn: 'root'
})
export class IsProjectLockedGuardService {
    constructor(private router: Router, private projectAccessStore: ProjectAccessStore) {}
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.projectAccessStore.status$.pipe(
            tap(status => {
                const projectId = route.paramMap.get('id');

                if (!projectId) {
                    this.router.navigate([RoutePaths.Error404]);
                }

                if (status.locked) {
                    this.router.navigate([`${RouterLinks.Project}${projectId}`]);
                }
            }),
            map(status => !status.locked)
        );
    }
}
