import { ControlTypes } from '@customer-apps/shared/enums';
import { QuestionGroupOptions } from '@customer-apps/shared/interfaces';
import { AddressSubquestion } from '@customer-apps/shared/utils';
import { QuestionGroupBase } from './question-group-base.model';

export class Addressbox extends QuestionGroupBase<AddressSubquestion[]> {
    override controlType = ControlTypes.Addressbox;
    constructor(options: QuestionGroupOptions<AddressSubquestion[]>) {
        super(options);
    }
}
