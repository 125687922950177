import { Action, createReducer, on } from '@ngrx/store';
import { FooterState } from '@customer-apps/shared/interfaces';
import { FooterActions } from './footer.actions';

import data from '../../../../../../package.json';

export const initialFooterState: FooterState = {
    version: data.version,
    appVersion: `v${data.version}`,
    apiVersion: '',
    calculationApiVersion: '',
    links: [
        { name: 'FOOTER.LINK.IMPRINT', url: 'https://legal-policies.viessmann.com/b2b/common/imprint.html' },
        { name: 'FOOTER.LINK.DATA_PROTECTION', url: 'https://legal-policies.viessmann.com/b2b/common/privacy-policy.html' },
        { name: 'FOOTER.LINK.COPYRIGHT', url: 'https://legal-policies.viessmann.com/b2b/common/copyright.html' }
    ]
};

const reducer = createReducer(
    initialFooterState,
    on(FooterActions.getApiInfoSuccess, (state, { calculationApiVersion }) => ({
        ...state,
        calculationApiVersion: calculationApiVersion ? `v${calculationApiVersion}` : ''
    }))
);

export function footerReducer(state: FooterState | undefined, action: Action) {
    return reducer(state, action);
}
