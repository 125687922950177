import { EnumDictionary } from '../types';
import { TechnicalDataKeys } from '@customer-apps/shared/enums';

export const technicalDataKeysToPhraseKey: EnumDictionary<TechnicalDataKeys, string> = {
    [TechnicalDataKeys.MaxDCPowerIn]: 'COMMON.PRODUCT_TECHNICAL_DATA.MAX_DC_POWER_IN',
    [TechnicalDataKeys.MaxACPowerOut]: 'COMMON.PRODUCT_TECHNICAL_DATA.MAX_AC_POWER_OUT',
    [TechnicalDataKeys.MaxEfficiency]: 'COMMON.PRODUCT_TECHNICAL_DATA.MAX_EFFICIENCY',
    [TechnicalDataKeys.MaxModuleEfficiency]: 'COMMON.PRODUCT_TECHNICAL_DATA.MAX_EFFICIENCY',
    [TechnicalDataKeys.MaxChargingPower]: 'COMMON.PRODUCT_TECHNICAL_DATA.MAX_CHARGING_POWER',
    [TechnicalDataKeys.MaxDischargingPower]: 'COMMON.PRODUCT_TECHNICAL_DATA.MAX_DISCHARGING_POWER',
    [TechnicalDataKeys.BatteryCapacity]: 'COMMON.PRODUCT_TECHNICAL_DATA.BATTERY_CAPACITY',
    [TechnicalDataKeys.ModuleNominalPower]: 'COMMON.PRODUCT_TECHNICAL_DATA.MODULE_NOMINAL_POWER',
    [TechnicalDataKeys.Dimensions]: 'COMMON.PRODUCT_TECHNICAL_DATA.DIMENSIONS',
    [TechnicalDataKeys.Height]: 'COMMON.PRODUCT_TECHNICAL_DATA.HEIGHT',
    [TechnicalDataKeys.Length]: 'COMMON.PRODUCT_TECHNICAL_DATA.LENGTH',
    [TechnicalDataKeys.Width]: 'COMMON.PRODUCT_TECHNICAL_DATA.WIDTH'
};
