/**
 * Defines a type of roof for the building.
 */
export enum RoofType {
    Saddle = 'SADDLE',
    HalfHipped = 'HALF_HIPPED',
    Hipped = 'HIPPED',
    Flat = 'FLAT',
    SinglePitch = 'SINGLE_PITCH'
}
