import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { RemoteConfig } from '@customer-apps/shared/interfaces';
import { EnvironmentService } from './environment.service';

@Injectable({
    providedIn: 'root'
})
export class RemoteConfigService {
    private readonly defaultAppConfig = this.environmentService.defaultAppConfig;

    private readonly configurationState = new BehaviorSubject({
        config: this.defaultAppConfig,
        loaded: false
    });

    constructor(private http: HttpClient, private environmentService: EnvironmentService) {}

    /**
     * Get remote configuration for ViGuide Planning
     */
    public getConfiguration(): void {
        this.http
            .get<RemoteConfig>(this.environmentService.remoteConfigUrl, {
                params: { appId: this.environmentService.remoteConfigAppId }
            })
            .subscribe({
                next: config => {
                    this.configurationState.next({ config: this.parse(config), loaded: true });
                },
                error: err => {
                    this.configurationState.next({ config: this.defaultAppConfig, loaded: true });
                }
            });
    }

    /**
     * Get remote configuration state
     */
    public get state$(): Observable<{ config: RemoteConfig; loaded: boolean }> {
        return this.configurationState.asObservable();
    }

    /**
     * Get remote configuration value
     */
    public get config(): RemoteConfig {
        return this.configurationState.getValue().config;
    }

    /**
     * Check if remote config is enabled. If not, use default configuration from environment.
     * Check if remote configuration flag is available. If not, use default configuration from environment.
     * @param remoteConfig
     */
    private parse(config: RemoteConfig): RemoteConfig {
        if (!this.environmentService.remoteConfigEnabled) {
            return this.defaultAppConfig;
        }

        for (const featureFlag in this.defaultAppConfig) {
            if (config[featureFlag] === undefined) {
                config[featureFlag] = this.defaultAppConfig[featureFlag];
            }
        }

        return config;
    }
}
