import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
    selector: '[vpImagePlaceholder]'
})
export class ImagePlaceholderDirective {
    private readonly defaultPlaceholderSrc = '/assets/images/placeholder.png';

    @Input('vpImagePlaceholder') placeholderSrc: string;

    constructor(private el: ElementRef<HTMLImageElement>) {
        this.el.nativeElement.onerror = () => {
            this.el.nativeElement.onerror = null;
            this.el.nativeElement.src = this.placeholderSrc || this.defaultPlaceholderSrc;
        };
    }
}
