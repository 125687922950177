import { Injectable } from '@angular/core';
import { BuildingPlacement, HeatingDistributionMethod, RoofType, WindowType } from '@customer-apps/shared/enums';
import { TranslateService } from '@ngx-translate/core';

/* istanbul ignore next */
@Injectable({
    providedIn: 'root'
})
export class FeatureTranslationService {
    constructor(private translate: TranslateService) {}

    public getRoofType(value: string | undefined): string | undefined {
        const roofTypes = {
            [RoofType.Flat]: this.translate.instant('HEATLOAD.ROOF_TYPE.FLAT'),
            [RoofType.SinglePitch]: this.translate.instant('HEATLOAD.ROOF_TYPE.PENT'),
            [RoofType.Saddle]: this.translate.instant('HEATLOAD.ROOF_TYPE.GABLE'),
            [RoofType.Hipped]: this.translate.instant('HEATLOAD.ROOF_TYPE.HIPPED'),
            [RoofType.HalfHipped]: this.translate.instant('HEATLOAD.ROOF_TYPE.HALF_HIPPED')
        };
        return roofTypes[value as RoofType];
    }

    public getWindowType(value: string | undefined): string | undefined {
        const windowTypes = {
            [WindowType.SingleGlazing]: this.translate.instant('HEATLOAD.WINDOWS_AND_GLAZING.SINGLE_GLAZING'),
            [WindowType.DoubleGlazing]: this.translate.instant('HEATLOAD.WINDOWS_AND_GLAZING.DOUBLE_GLAZING'),
            [WindowType.InsulatedGlazingPlastic]: this.translate.instant('HEATLOAD.WINDOWS_AND_GLAZING.INSULATING_GLAZING_PLASTIC'),
            [WindowType.InsulatedGlazingAluminum]: this.translate.instant(
                'HEATLOAD.WINDOWS_AND_GLAZING.GLAZING_TYPE.INSULATED_GLAZING_ALUMINIUM'
            ),
            [WindowType.TripleGlazingWood]: this.translate.instant(
                'HEATLOAD.WINDOWS_AND_GLAZING.GLAZING_TYPE.TRIPLE_GLAZING_IMPROVED_WOODEN_FRAME'
            ),
            [WindowType.TripleGlazingPassive]: this.translate.instant(
                'HEATLOAD.WINDOWS_AND_GLAZING.GLAZING_TYPE.TRIPLE_GLAZING_PASSIVE_HOUSE_FRAME'
            )
        };
        return windowTypes[value as WindowType];
    }

    public getBuildingPosition(value: string | undefined): string | undefined {
        const positions = {
            [BuildingPlacement.Detached]: this.translate.instant('HEATLOAD.BUILDING.POSITION.FREE_STANDING'),
            [BuildingPlacement.Middle]: this.translate.instant('HEATLOAD.BUILDING.POSITION.MIDDLE_HOUSE'),
            [BuildingPlacement.Corner]: this.translate.instant('HEATLOAD.BUILDING.POSITION.CORNER_HOUSE')
        };
        return positions[value as BuildingPlacement];
    }

    public getHeatDeliveryMethod(value: string | undefined): string | undefined {
        const methods = {
            [HeatingDistributionMethod.Radiator]: this.translate.instant('HEATLOAD.HEATING_DISTRIBUTION.METHOD.RADIATOR'),
            [HeatingDistributionMethod.Underfloor]: this.translate.instant('HEATLOAD.HEATING_DISTRIBUTION.METHOD.UNDERFLOOR_HEATING'),
            [HeatingDistributionMethod.RadiatorAndUnderfloor]: this.translate.instant(
                'HEATLOAD.HEATING_DISTRIBUTION.METHOD.RADIATOR_AND_UNDERFLOOR_HEATING'
            )
        };
        return methods[value as HeatingDistributionMethod];
    }
}
