import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../../../services';
import { AuthStore } from '../../../state/auth/auth.store';
import { SettingsStore } from '../../../state/settings/settings.store';

@Component({
    selector: 'vp-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {
    @Input() isAuthenticated: Observable<boolean>;
    @Output() logoutClick = new EventEmitter();

    constructor(public settingsStore: SettingsStore, public authStore: AuthStore, public environmentService: EnvironmentService) {}

    public logout(): void {
        this.logoutClick.emit();
    }
}
