import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ControlTypes } from '@customer-apps/shared/enums';
import { Question, QuestionBase, TextboxQuestion, TypesHTMLHelper } from '@customer-apps/shared/utils';
import { FormFieldHighlights } from '@customer-apps/shared/interfaces';
import { slideDown } from '../../animations';

@Component({
    selector: 'vp-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.scss'],
    animations: [slideDown]
})
export class QuestionComponent {
    @Input() form: FormGroup;
    @Input() question: Question;
    @Input() highlights: FormFieldHighlights = {};
    public hint: string | null;
    readonly CONTROL_TYPES: typeof ControlTypes = ControlTypes;
    readonly TYPES = TypesHTMLHelper;

    get control(): AbstractControl {
        return this.form.get((this.question as QuestionBase<any>).key) as AbstractControl;
    }

    get isInvalid(): boolean {
        return this.control?.invalid && this.control?.touched;
    }

    get errorMessage(): string {
        const errorMessage = this.control?.errors?.['message'];
        return errorMessage || (this.question as QuestionBase<any>).defaultErrorMessage;
    }

    public onInput(event: Event): void {
        this.validateOnInput((event as InputEvent).data);
    }

    public onBlur(): void {
        this.hint = null;
    }

    public trackBySelf(index: number, item: string): string {
        return item;
    }

    public trackByKey(index: number, item: { key: string }): string {
        return item.key;
    }

    private validateOnInput(value: string | null): void {
        (this.question as TextboxQuestion).hints.forEach(hint => {
            this.hint = hint(value);
        });
    }
}
