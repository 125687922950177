import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { SnackbarService } from '@customer-apps/shared/services';
import { ResponseSummaryAction } from '@customer-apps/api-planning-projects';
import { TranslateService } from '@ngx-translate/core';
import { ResponseSummaryActionDictionary } from '@customer-apps/shared/utils';
import { ResponseSummaryActionType } from '@customer-apps/shared/enums';
import { ProjectStore } from '../state/project/project.store';

@Injectable()
export class ResponseSummaryActionInterceptor implements HttpInterceptor {
    private readonly supportedActionTypes: ResponseSummaryActionType[] = [
        ResponseSummaryActionType.PlanningsRemoved,
        ResponseSummaryActionType.HeatLoadCalculationAutoSelected
    ];

    constructor(private snackbarService: SnackbarService, private injector: Injector) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        request = request.clone();
        const request$ = next.handle(request);
        return request$.pipe(
            tap(res => {
                if (res instanceof HttpResponse) {
                    const actionsSummary: ResponseSummaryAction[] = res.body?.actionsSummary;
                    actionsSummary?.map(actionSummary => this.handleActionType(actionSummary.actionType as ResponseSummaryActionType));
                }
            })
        );
    }

    private handleActionType(actionType: ResponseSummaryActionType): void {
        if (this.supportedActionTypes.some(supportedActionType => supportedActionType === actionType)) {
            const token = ResponseSummaryActionDictionary[actionType];
            if (token) {
                const message = this.translate.instant(token);
                this.snackbarService.showInfo(message, { duration: 5000 });
            }
        }
        this.handleCalculationMethodRecalculatedActionType(actionType);
    }

    private handleCalculationMethodRecalculatedActionType(actionType: ResponseSummaryActionType): void {
        if (actionType === ResponseSummaryActionType.SelectedCalculationMethodRecalculated) {
            this.projectHeatloadCalculatiosNumber$.pipe(filter(Boolean), take(1)).subscribe(calculationsNumber => {
                const recalculationActionTypes =
                    calculationsNumber === 1
                        ? ResponseSummaryActionType.CalculationMethodRecalculated
                        : ResponseSummaryActionType.SelectedCalculationMethodRecalculated;
                const message = ResponseSummaryActionDictionary[recalculationActionTypes];
                this.snackbarService.showInfo(this.translate.instant(message!), { duration: 5000 });
            });
        }
    }

    private get translate() {
        // Grabbing TranslateService by injector to avoid CI (circular dependency) issue
        return this.injector.get(TranslateService);
    }

    private get projectStore() {
        // Grabbing ProjectStore by injector to avoid CI (circular dependency) issue
        return this.injector.get(ProjectStore);
    }

    private get projectHeatloadCalculatiosNumber$(): Observable<number | undefined> {
        return this.projectStore.project$.pipe(map(project => project?.building?.heatLoadCalculations?.length));
    }
}
