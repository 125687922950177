<div class="d-flex flex-column justify-content-center align-items-center spinner-container">
    <div class="spinner">
        <mat-spinner mode="indeterminate" color="accent" [diameter]="diameter"></mat-spinner>
    </div>
    <div *ngIf="text || subtext" class="d-flex flex-column justify-content-center align-items-center spinner-content">
        <div *ngIf="text" class="spinner-text">
            {{ text | translate }}
        </div>
        <div *ngIf="subtext">
            {{ subtext | translate }}
        </div>
    </div>
</div>
