import { ProductType } from '@customer-apps/shared/enums';
import { EnumDictionary } from '@customer-apps/shared/utils';

export const productTypeToPhraseKeyDictionary: EnumDictionary<ProductType, string> = {
    [ProductType.AirHeatPump]: 'COMMON.PRODUCT_TYPE.AIR_HEAT_PUMP',
    [ProductType.BatteryStorage]: 'COMMON.PRODUCT_TYPE.BATTERY_STORAGE',
    [ProductType.PhotovoltaicBattery]: 'COMMON.PRODUCT_TYPE.BATTERY_STORAGE',
    [ProductType.DHWCylinder]: 'COMMON.PRODUCT_TYPE.DOMESTIC_HOT_WATER_CYLINDER',
    [ProductType.ElectricHeater]: 'COMMON.PRODUCT_TYPE.ELECTRIC_HEATER',
    [ProductType.EnergyManagementSystem]: 'COMMON.PRODUCT_TYPE.ENERGY_MANAGEMENT_SYSTEM',
    [ProductType.FreshWaterModule]: 'COMMON.PRODUCT_TYPE.FRESH_WATER_MODULE',
    [ProductType.GasBoiler]: 'COMMON.PRODUCT_TYPE.GAS_BOILER',
    [ProductType.GeoCollector]: 'COMMON.PRODUCT_TYPE.GEO_COLLECTOR',
    [ProductType.GroundHeatPump]: 'COMMON.PRODUCT_TYPE.SOIL_HEAT_PUMP',
    [ProductType.HardwoodBoiler]: 'COMMON.PRODUCT_TYPE.HARDWOOD_BOILER',
    [ProductType.HeatExchanger]: 'COMMON.PRODUCT_TYPE.HEAT_EXCHANGER',
    [ProductType.HeatPumpCoolingPackage]: 'COMMON.PRODUCT_TYPE.HEAT_PUMP_COOLING_PACKAGE',
    [ProductType.HeatingOilBoiler]: 'COMMON.PRODUCT_TYPE.HEATING_OIL_BOILER',
    [ProductType.HotWaterBufferTank]: 'COMMON.PRODUCT_TYPE.HOT_WATER_BUFFER_TANK',
    [ProductType.HybridAirHeatPump]: 'COMMON.PRODUCT_TYPE.HYBRID_AIR_HEAT_PUMP',
    [ProductType.HybridBatteryStorage]: 'COMMON.PRODUCT_TYPE.HYBRID_BATTERY_STORAGE',
    [ProductType.HybridHeatingDevice]: 'COMMON.PRODUCT_TYPE.HYBRID_HEATING_DEVICE',
    [ProductType.MicroChp]: 'COMMON.PRODUCT_TYPE.MICRO_CHP',
    [ProductType.PelletBoiler]: 'COMMON.PRODUCT_TYPE.PELLET_BOILER',
    [ProductType.PhotovoltaicModule]: 'COMMON.PRODUCT_TYPE.PHOTOVOLTAIC',
    [ProductType.Photovoltaic]: 'COMMON.PRODUCT_TYPE.PHOTOVOLTAIC',
    [ProductType.PhotovoltaicAllInOneSystem]: 'COMMON.PRODUCT_TYPE.PHOTOVOLTAIC_ALL_IN_ONE_SYSTEM',
    [ProductType.PhotovoltaicBatteryInverter]: 'COMMON.PRODUCT_TYPE.PHOTOVOLTAIC_BATTERY_INVERTER',
    [ProductType.PhotovoltaicBatteryModule]: 'COMMON.PRODUCT_TYPE.PHOTOVOLTAIC_BATTERY_MODULE',
    [ProductType.PhotovoltaicHybridInverter]: 'COMMON.PRODUCT_TYPE.PHOTOVOLTAIC_HYBRID_INVERTER',
    [ProductType.PhotovoltaicInverter]: 'COMMON.PRODUCT_TYPE.PHOTOVOLTAIC_INVERTER',
    [ProductType.PhotovoltaicPanelModule]: 'COMMON.PRODUCT_TYPE.PHOTOVOLTAIC_PANEL_MODULE',
    [ProductType.PowerInverter]: 'COMMON.PRODUCT_TYPE.POWER_INVERTER',
    [ProductType.PhotovoltaicModuleInverter]: 'COMMON.PRODUCT_TYPE.POWER_INVERTER',
    [ProductType.SoftwoodBoiler]: 'COMMON.PRODUCT_TYPE.SOFTWOOD_BOILER',
    [ProductType.Solar]: 'COMMON.PRODUCT_TYPE.SOLAR',
    [ProductType.Ventilation]: 'COMMON.PRODUCT_TYPE.VENTILATION',
    [ProductType.VentilationDuctSystem]: 'COMMON.PRODUCT_TYPE.VENTILATION_DUCT_SYSTEM',
    [ProductType.WaterHeatPump]: 'COMMON.PRODUCT_TYPE.GROUNDWATER_HEAT_PUMP',
    [ProductType.Other]: 'COMMON.PRODUCT_TYPE.OTHER'
};
