import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CountryLanguageSwitcherComponent } from './components/country-language-switcher/country-language-switcher.component';
import { GlobalSpinnerComponent } from './components/global-spinner/global-spinner.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { Error404Component } from './components/errors/404/error-404.component';
import { Error403Component } from './components/errors/403/error-403.component';
import { NoSalesForceAccessComponent } from './components/errors/no-sales-force-access/no-sales-force-access.component';
import { GlobalHeaderModule } from '@vi/global-header';
import { FooterComponent } from './components/footer/footer.component';
import { SharedUiModule } from '@customer-apps/shared/ui';
import { DswFooterComponent } from '@vi-succ/design-system-web';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
    declarations: [
        ToolbarComponent,
        Error404Component,
        Error403Component,
        NoSalesForceAccessComponent,
        GlobalSpinnerComponent,
        CountryLanguageSwitcherComponent,
        FooterComponent,
        OnboardingComponent
    ],
    imports: [CommonModule, RouterModule, SharedUiModule, GlobalHeaderModule, DswFooterComponent, CarouselModule],
    exports: [ToolbarComponent, GlobalSpinnerComponent, FooterComponent]
})
export class CoreModule {}
