/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { productsControllerFetchProducts } from '../fn/products-controller/products-controller-fetch-products';
import { ProductsControllerFetchProducts$Params } from '../fn/products-controller/products-controller-fetch-products';
import { ProductsResponseGetRecordsList } from '../models/products-response-get-records-list';


/**
 * Represents all API calls related to products.
 */
@Injectable({ providedIn: 'root' })
export class ProductsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `productsControllerFetchProducts()` */
  static readonly ProductsControllerFetchProductsPath = '/planning-projects/v1-develop/products';

  /**
   * Returns the product details for requested products.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productsControllerFetchProducts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  productsControllerFetchProducts$Response(params: ProductsControllerFetchProducts$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductsResponseGetRecordsList>> {
    return productsControllerFetchProducts(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the product details for requested products.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `productsControllerFetchProducts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  productsControllerFetchProducts(params: ProductsControllerFetchProducts$Params, context?: HttpContext): Observable<ProductsResponseGetRecordsList> {
    return this.productsControllerFetchProducts$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductsResponseGetRecordsList>): ProductsResponseGetRecordsList => r.body)
    );
  }

}
