import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { SnackbarOptions } from '@customer-apps/shared/interfaces';
import { LodashService } from './lodash.service';

/* istanbul ignore next */
@Injectable({ providedIn: 'root' })
export class SnackbarService {
    constructor(private snackbar: MatSnackBar) {}

    public showInfo(message: string, options?: SnackbarOptions): MatSnackBarRef<TextOnlySnackBar> {
        const config = this.getConfig(options);
        return this.snackbar.open(message, options?.action, config);
    }

    public showCustomInfo(component: any, options?: MatSnackBarConfig): MatSnackBarRef<unknown> {
        const config = this.getConfig(options);
        return this.snackbar.openFromComponent(component, config);
    }

    private getConfig(options?: Partial<MatSnackBarConfig>): MatSnackBarConfig {
        return {
            ...options,
            panelClass: options?.panelClass || ['vp-snackbar', 'vp-info'],
            duration: LodashService.isNil(options?.duration) ? 5000 : options?.duration,
            verticalPosition: options?.verticalPosition || 'bottom',
            horizontalPosition: options?.horizontalPosition || 'center'
        };
    }
}
