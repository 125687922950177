import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatus } from '@customer-apps/shared/enums';

@Pipe({
    name: 'orderStatusStyles'
})
export class OrderStatusStylesPipe implements PipeTransform {
    transform(projectStatus: OrderStatus): string {
        const styles = {
            [OrderStatus.Created]: 'status-blue',
            [OrderStatus.Completed]: 'status-green',
            [OrderStatus.Processing]: 'status-yellow',
            [OrderStatus.Rejected]: 'status-red'
        };
        return styles[projectStatus] || 'status-dark-grey';
    }
}
