import { AddressGetRecordsList, ProjectBasicGetRecordsList } from '@customer-apps/api-planning-projects';

export const basicProjectsToProjectList = (projects: ProjectBasicGetRecordsList[]) =>
    projects.map((project: ProjectBasicGetRecordsList) => {
        let address: Partial<AddressGetRecordsList> = {};

        if (project.address.streetName) {
            address.streetName = project.address.streetName;
        }

        address = {
            ...address,
            houseNumber: project.address.houseNumber,
            postalCode: project.address.postalCode,
            city: project.address.city,
            countryCode: project.address.countryCode
        };

        return {
            ...project,
            address
        } as ProjectBasicGetRecordsList;
    });
