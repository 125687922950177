import { animate, keyframes, style, transition, trigger } from '@angular/animations';

export const slideDown = trigger('slideDown', [
    transition(':enter', [
        animate(
            '300ms',
            keyframes([
                style({ opacity: 0, transform: 'translateY(-50%)', offset: 0 }),
                style({ opacity: 0, transform: 'translateY(-50%)', offset: 0.3 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 })
            ])
        )
    ])
]);
