import { Injectable } from '@angular/core';
import { LocalStorageSubstitute } from '@customer-apps/shared/utils';
import { debounceTime, fromEvent } from 'rxjs';

declare global {
    interface Window {
        dataLayer: any;
    }
}
/* istanbul ignore next */
@Injectable({
    providedIn: 'root'
})
export class WindowService {
    public get nativeWindow(): Window {
        return window;
    }

    public get localStorage(): Storage {
        try {
            window.localStorage.setItem('__item', 'value');
            window.localStorage.removeItem('__item');
            return window.localStorage;
        } catch (e) {
            return <Storage>new LocalStorageSubstitute();
        }
    }

    public get sessionStorage(): Storage {
        try {
            window.sessionStorage.setItem('__item', 'value');
            window.sessionStorage.removeItem('__item');
            return window.sessionStorage;
        } catch (e) {
            return <Storage>new LocalStorageSubstitute();
        }
    }

    public get scrollEnd$() {
        return fromEvent(this.nativeWindow, 'scroll').pipe(debounceTime(20));
    }

    public get registrationRedirectUrl(): string {
        return window.location.href.split('?')[0];
    }

    public get tokenFromUrl(): string {
        return window.location.hash.replace('#token=', '');
    }

    public changeHref(url: string): void {
        window.location.href = url;
    }

    public reload(): void {
        window.location.reload();
    }

    public randomUUID(): string {
        return window.crypto.randomUUID();
    }
}
