import { Injectable } from '@angular/core';
import { HeatGeneratorInformation, SystemItem } from '@customer-apps/api-planning-projects';
import { BuildingType, EnergySource, FormKeys, GEGSystemType, HeatGeneratorType } from '@customer-apps/shared/enums';
import { EnergySourceOptions, FormOptions, HeatGeneratorDetails, HeatingEnergySourceState } from '@customer-apps/shared/interfaces';
import { HeatloadService } from '../../heatload/services/heatload.service';
import { EnumDictionary, fractionToPercent } from '@customer-apps/shared/utils';

@Injectable({
    providedIn: 'root'
})
export class GEGFormOptionsService {
    constructor(private heatloadService: HeatloadService) {}

    public getSystemFormOptions(options: { prefill?: GEGSystemType }): FormOptions {
        const questions = {
            [FormKeys.System]: { value: options.prefill }
        };

        return { questions };
    }

    public getEnergySourceFormOptions(options: {
        heatGenerators: HeatGeneratorInformation[];
        prefill?: HeatingEnergySourceState;
        heatGeneratorDictionary?: EnumDictionary<HeatGeneratorType, string>;
    }): EnergySourceOptions {
        const { prefill, heatGenerators, heatGeneratorDictionary } = options;
        const heatGeneratorOptions = heatGenerators.map(generator => generator.heatGeneratorType);

        const defaultEnergySourceOptions: EnergySourceOptions = {
            questions: {
                [FormKeys.HeatGenerator]: { options: heatGeneratorOptions }
            }
        };

        if (heatGeneratorDictionary) {
            defaultEnergySourceOptions.heatGeneratorDictionary = heatGeneratorDictionary;
        }

        if (!prefill) {
            return defaultEnergySourceOptions;
        }

        const questions: { [key: string]: any } = {
            [FormKeys.HeatGenerator]: { value: prefill.selectedHeatGenerator, options: heatGeneratorOptions }
        };

        const generator = heatGenerators.find(item => item.heatGeneratorType === prefill.selectedHeatGenerator)!;
        const isManySources = generator.energySources.length > 1;

        if (isManySources) {
            const energySourceOptions = generator!.energySources.map(source => source.type);
            questions[FormKeys.EnergySource] = { value: prefill?.selectedEnergySource.type, options: energySourceOptions };
        }

        const energySourceOptions: EnergySourceOptions = { questions };

        if (heatGeneratorDictionary) {
            energySourceOptions.heatGeneratorDictionary = heatGeneratorDictionary;
        }

        return energySourceOptions;
    }

    public getHeatGeneratorDetailsFormOptions(options: {
        buildingType: BuildingType;
        heatingSystems?: SystemItem[];
        energySource?: HeatingEnergySourceState;
        prefill?: HeatGeneratorDetails;
    }) {
        const { prefill, buildingType, heatingSystems, energySource } = options;

        const defaultOptions: FormOptions = {
            questions: {
                [FormKeys.InstallationYear]: {}
            },
            buildingType
        };

        if (!prefill) {
            return defaultOptions;
        }

        const boilerTypeOptions = heatingSystems?.map(item => item.type);
        const questions: { [key: string]: any } = {
            [FormKeys.InstallationYear]: { value: prefill?.installationYear },
            [FormKeys.NominalPower]: { value: prefill?.nominalPower },
            [FormKeys.BoilerType]: { value: prefill?.boilerType, options: boilerTypeOptions }
        };

        const selectedInstallationType = heatingSystems?.find(item => item.type === prefill?.boilerType);

        if (selectedInstallationType?.isLowTemperature) {
            questions[FormKeys.LowTemperature] = { value: prefill?.lowTemperature };
        }

        if (selectedInstallationType?.warmWaterByHeatGenerator) {
            questions[FormKeys.WarmWaterByHeatGenerator] = { value: prefill?.warmWaterByHeatGenerator };
        }

        const isElectricStorageHeater = this.heatloadService.isHeatGeneratorAnElectricStorageHeater(
            energySource?.selectedHeatGenerator as HeatGeneratorType,
            energySource?.selectedEnergySource.type as EnergySource
        );

        const formOptions = {
            questions,
            buildingType,
            selectedInstallationType
        };

        if (isElectricStorageHeater) {
            return formOptions;
        }

        if (prefill?.heatPumpOperatingMode && selectedInstallationType?.operatingModes) {
            questions[FormKeys.HeatPumpOperatingMode] = {
                value: prefill.heatPumpOperatingMode,
                options: selectedInstallationType.operatingModes
            };
            return formOptions;
        }

        questions[FormKeys.IsKnown] = { value: Boolean(prefill.gasLossExhaustKnown) };

        if (prefill?.gasLossExhaust) {
            questions[FormKeys.GasLossExhaust] = { value: fractionToPercent(prefill.gasLossExhaust) };
        }

        return formOptions;
    }
}
