import { EnergySource } from '@customer-apps/shared/enums';
import { EnumDictionary } from '../types';

export const fuelToPhraseKey: EnumDictionary<EnergySource, string> = {
    [EnergySource.Pellet]: 'GEG_SYSTEM.FUEL.PELLET',
    [EnergySource.Softwood]: 'GEG_SYSTEM.FUEL.SOFTWOOD',
    [EnergySource.Hardwood]: 'GEG_SYSTEM.FUEL.HARDWOOD',
    [EnergySource.Gas]: 'GEG_SYSTEM.FUEL.GAS',
    [EnergySource.Methane]: 'GEG_SYSTEM.FUEL.METHANE',
    [EnergySource.Hydrogen]: 'GEG_SYSTEM.FUEL.HYDROGEN',
    [EnergySource.DistrictHeating]: 'GEG_SYSTEM.FUEL.DISTRICT_HEATING',
    [EnergySource.Electricity]: 'GEG_SYSTEM.FUEL.ELECTRICITY',
    [EnergySource.ElectricityFeed]: 'GEG_SYSTEM.FUEL.ELECTRICITY_FEED',
    [EnergySource.ElectricityOwnNeeds]: 'GEG_SYSTEM.FUEL.ELECTRICITY_OWN_NEEDS',
    [EnergySource.ElectricityProduced]: 'GEG_SYSTEM.FUEL.ELECTRICITY_PRODUCED',
    [EnergySource.HeatPumpElectricity]: 'GEG_SYSTEM.FUEL.ELECTRICITY_HEATPUMP',
    [EnergySource.HeatingOil]: 'GEG_SYSTEM.FUEL.HEATING_OIL',
    [EnergySource.SolarThermal]: 'GEG_SYSTEM.FUEL.SOLAR_THERMAL'
};
