import { createSelector } from '@ngrx/store';
import { DatePipe } from '@angular/common';
import { AppState } from '@customer-apps/shared/interfaces';
import { LockResult, MessageType } from '@customer-apps/shared/enums';

export namespace ProjectAccessSelectors {
    export const ProjectAccessState = (state: AppState) => state.projectAccess;

    export const SettingsState = (state: AppState) => state.settings;

    export const getState = () => createSelector(ProjectAccessState, state => state);

    export const getStatus = () =>
        createSelector(ProjectAccessState, SettingsState, (state, settingsState) => {
            return {
                locked: state.lockResult !== LockResult.LockedSuccessfully || state.verifyResult === LockResult.AlreadyLockedByOtherUser,
                lockedByOtherUser:
                    state.lockResult === LockResult.AlreadyLockedByOtherUser || state.verifyResult === LockResult.AlreadyLockedByOtherUser,
                lockedByCurrentUser: state.lockResult === LockResult.LockedSuccessfully,
                lockedByUserId: state.lockedByUserId,
                lockStartDate: new DatePipe(settingsState.locale!).transform(state.lockStartDate, 'dd.MM.yyyy, HH:mm')
            };
        });

    export const getUnlockOnUnload = () =>
        createSelector(ProjectAccessState, state => state.invokeBeforeUnload && state.lockResult === LockResult.LockedSuccessfully);

    export const getSessionExpiredMessage = () =>
        createSelector(ProjectAccessState, state => {
            return state.showExpiredLockSessionMessage
                ? {
                      title: 'WARNING_BOX.PROJECT_LOCK_SESSION_EXPIRED_TITLE',
                      content: 'WARNING_BOX.PROJECT_LOCK_SESSION_EXPIRED_MESSAGE',
                      type: MessageType.Info,
                      uuid: 'projectLockSessionExpired'
                  }
                : null;
        });
}
