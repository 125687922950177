/**
 * Defines all possible methods used to calculate heating load for the building.
 */
export enum HeatLoadCalculationMethod {
    LivingSpace = 'LIVING_SPACE',
    BuildingHull = 'BUILDING_HULL',
    EnergyCertificate = 'ENERGY_CERTIFICATE',
    Consumption = 'CONSUMPTION',
    CustomValue = 'CUSTOM_VALUE'
}
