/**
 * Defines a set of possible heat generator types.
 */
export enum HeatGeneratorType {
    GasBoiler = 'GAS_BOILER',
    OilBoiler = 'OIL_BOILER',
    WoodStove = 'WOOD_STOVE',
    HeatPump = 'HEAT_PUMP',
    ElectricHeater = 'ELECTRIC_HEATER',
    SolarThermal = 'SOLAR_THERMAL',
    DistrictHeating = 'DISTRICT_HEATING'
}
