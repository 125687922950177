import { getLocaleCurrencyCode, getLocaleCurrencySymbol, getLocaleId, getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { WindowService } from '@customer-apps/shared/services';
import { LocaleUtils } from '@customer-apps/shared/utils';
import { EnvironmentService } from './environment.service';

@Injectable({
    providedIn: 'root'
})
export class LocaleService {
    constructor(
        @Inject(LOCALE_ID) private localeId: string,
        private envService: EnvironmentService,
        private windowService: WindowService
    ) {}

    public get locale(): string | null {
        const storedLocale = this.windowService.localStorage.getItem(this.envService.localeLocalStorageKey);
        return LocaleUtils.supportedLocales.some(locale => locale === storedLocale) ? storedLocale : null;
    }

    public set locale(locale: string | null) {
        this.windowService.localStorage.setItem(this.envService.localeLocalStorageKey, locale as string);
    }

    public get initLocale(): string {
        return this.locale || LocaleUtils.defaultBrowserLocale;
    }

    /*
     * Returns decimal marker by locale. Throws error if locale data is not registered in the app.module
     */
    public getDecimalMarker(): string {
        try {
            return getLocaleNumberSymbol(this.localeId, NumberSymbol.Decimal);
        } catch (error) {
            console.error(error);
            return getLocaleNumberSymbol(LocaleUtils.defaultLocale, NumberSymbol.Decimal);
        }
    }

    public getCurrencyCode(): string | null {
        return getLocaleCurrencyCode(this.localeId);
    }

    public getCurrencySymbol(): string | null {
        return getLocaleCurrencySymbol(this.localeId);
    }
    /**
     * @depracated  Please use locale from settings store instead
     */
    public getLocaleId(): string {
        return getLocaleId(this.localeId);
    }

    /**
     * @depracated  Please use locale from settings store instead
     */
    public getCountryCode(): string {
        return this.localeId.split('-')[1];
    }

    /**
     * @depracated  Please use locale from settings store instead
     */
    public getLanguageCode(): string {
        return this.localeId.split('-')[0];
    }
}
