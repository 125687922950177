export enum ResponseSummaryActionType {
    RecheckNeededSet = 'RECHECK_NEEDED_SET',
    HeatLoadCalculationAutoSelected = 'HEAT_LOAD_CALCULATION_AUTO_SELECTED',
    HeatLoadFirstCalculationAutoSelected = 'HEAT_LOAD_FIRST_CALCULATION_AUTO_SELECTED',
    PlanningsRemoved = 'PLANNINGS_REMOVED',
    HeatLoadCalculationSpecificParamsAppied = 'HEAT_LOAD_CALCULATION_SPECIFIC_PARAMS_APPLIED',
    EnergyPricesSetToDefaults = 'ENERGY_PRICES_AND_TARIFFS_DEFAULTS_SET_FOR_DUPLICATION',
    CustomNumberOfPersons = 'CUSTOM_NUMBER_OF_PERSONS_SET_FOR_DUPLICATION',
    SelectedCalculationMethodRecalculated = 'SELECTED_CALCULATION_METHOD_RECALCULATED',
    CalculationMethodRecalculated = 'CALCULATION_METHOD_RECALCULATED'
}
