import { Pipe, PipeTransform } from '@angular/core';
import { GegRecommendation } from '@customer-apps/api-planning-projects';
import { GegRecommendationType } from '@customer-apps/shared/enums';

@Pipe({
    name: 'gegRecommendationIcon'
})
export class GegRecommendationIconPipe implements PipeTransform {
    transform(gegRecommendation: GegRecommendation): string {
        const icons: any = {
            [GegRecommendationType.Info]: 'check',
            [GegRecommendationType.Problem]: 'warning',
            [GegRecommendationType.Warning]: 'exclamation'
        };
        return gegRecommendation?.recommendationType ? icons[gegRecommendation.recommendationType] : '';
    }
}
