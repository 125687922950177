/**
 * Defines a window type from the perspective of glazing.
 */
export enum WindowType {
    SingleGlazing = 'SINGLE_GLAZING',
    DoubleGlazing = 'DOUBLE_GLAZING',
    InsulatedGlazingPlastic = 'INSULATING_GLAZING_PLASTIC',
    InsulatedGlazingAluminum = 'INSULATING_GLAZING_ALUM',
    TripleGlazingWood = 'TRIPLE_GLAZING_IMPROVED_WOODEN_FRAME',
    TripleGlazingPassive = 'TRIPLE_GLAZING_PASSIVE_HOUSE_FRAME'
}
