import { Injectable } from '@angular/core';
import { GegEvaluationScreenService } from './geg-evaluation-screen.service';
import { Router } from '@angular/router';
import { RoutePaths, RouterLinks } from '@customer-apps/shared/enums';
import { ProjectStore } from '../../state/project/project.store';
import { take, tap } from 'rxjs';

@Injectable()
export class GegAlternativeSystemService extends GegEvaluationScreenService {
    constructor(private router: Router, private projectStore: ProjectStore) {
        super();
    }

    public navigateBack(): void {
        this.projectStore.project$
            .pipe(
                take(1),
                tap(project => {
                    this.router.navigate([RouterLinks.GEG, project!.id, RoutePaths.EvaluatedSystem]);
                })
            )
            .subscribe();
    }
}
