import { Injectable } from '@angular/core';
import { distinctUntilChanged, filter, map, withLatestFrom } from 'rxjs';
import { HeatloadStore } from '../../state/heatload/heatload.store';
import { ProjectStore } from '../../state/project/project.store';
import { FormName, ProjectType } from '@customer-apps/shared/enums';
import { HeatloadPrefillService } from './heatload-prefill.service';
import { HeatloadFormService } from './heatload-form.service';
import { AccordionService, Modernization } from '@customer-apps/shared/interfaces';
import { HeatloadFormOptionsService } from './heatload-form-options.service';
import { HeatloadPrefillMapper, ProjectsService } from '../../services';
import { HeatloadService } from './heatload.service';

@Injectable({
    providedIn: 'root'
})
export class ModernizationsService implements AccordionService {
    constructor(
        private heatloadStore: HeatloadStore,
        private heatloadService: HeatloadService,
        private heatloadPrefillService: HeatloadPrefillService,
        private prefillMapper: HeatloadPrefillMapper,
        private heatloadFormOptionsService: HeatloadFormOptionsService,
        private projectStore: ProjectStore,
        private projectsService: ProjectsService,
        private heatloadFormService: HeatloadFormService
    ) {}

    public questions$ = this.heatloadStore.livingArea$.pipe(
        withLatestFrom(this.projectStore.project$, this.heatloadStore.cache$, (livingArea, project, cache) => ({
            livingArea,
            project,
            cache
        })),
        filter(({ project }) => this.projectsService.isRenovation(project!.projectType as ProjectType)),
        distinctUntilChanged((prev, curr) => prev.livingArea === curr.livingArea),
        map(state => {
            if (!state.livingArea) {
                return null;
            }
            const projectPrefill = this.prefillMapper.projectToModernizationPrefill(state.project!);
            const cachePrefill = this.prefillMapper.stateToModernizationPrefill(state.cache?.modernization);
            const formOptions = this.heatloadFormOptionsService.getModernizationFormOptions(cachePrefill || projectPrefill);
            return this.heatloadFormService.getModernizationQuestions(formOptions);
        })
    );

    public isPrefillNeeded(): boolean {
        return this.heatloadService.isRecalculation() && !this.heatloadPrefillService.isPrefilled(FormName.Modernizations);
    }

    public getSuccessMessage(modernization: Modernization): string {
        const results = [
            modernization.buildingConstructionYear,
            modernization.roofModernization,
            modernization.wallsModernization,
            modernization.windowsModernization
        ];

        const message = results.filter(Boolean).join(', ');

        return message;
    }

    public afterPrefill(): void {
        this.heatloadPrefillService.setAsPrefilled(FormName.Modernizations);
    }

    public isExpanded$ = this.heatloadStore.sameValueSubmited$.pipe(
        withLatestFrom(this.projectStore.project$, (form, project) => ({
            form,
            project
        })),
        map(
            ({ form, project }) =>
                form?.formName === FormName.LivingArea && this.projectsService.isRenovation(project!.projectType as ProjectType)
        )
    );
}
