import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'vp-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatorComponent {
    public pageIndexes: number[] = [];

    @Input() public pageIndex: number;
    @Input() public disabled = false;

    private _totalPagesCount: number;
    @Input()
    public set totalPagesCount(totalPagesCount: number) {
        this._totalPagesCount = totalPagesCount;
        this.updatePageIndexes();
    }
    public get totalPagesCount(): number {
        return this._totalPagesCount;
    }

    @Output() public pageIndexChange: EventEmitter<number> = new EventEmitter<number>();

    public onPageClick(index: number): void {
        this.pageIndex = index;
        this.pageIndexChange.emit(index);
        this.updatePageIndexes();
    }

    public onNavigationClick(shift: -1 | 1): void {
        this.pageIndex = this.pageIndex + shift;
        this.pageIndexChange.emit(this.pageIndex);
        this.updatePageIndexes();
    }

    private updatePageIndexes(): void {
        if (this.totalPagesCount <= 5) {
            this.pageIndexes = Array.from({ length: this.totalPagesCount }, (_, index) => index);
            return;
        }

        if (this.pageIndex < 3) {
            this.pageIndexes = [0, 1, 2, 3, -1 /** as ... */, this.totalPagesCount - 1];
            return;
        }

        if (this.pageIndex >= 3 && this.pageIndex <= this.totalPagesCount - 4) {
            this.pageIndexes = [
                0,
                -1 /** as ... */,
                this.pageIndex - 1,
                this.pageIndex,
                this.pageIndex + 1,
                -1 /** as ... */,
                this.totalPagesCount - 1
            ];
            return;
        }

        this.pageIndexes = [
            0,
            -1 /** as ... */,
            this.totalPagesCount - 4,
            this.totalPagesCount - 3,
            this.totalPagesCount - 2,
            this.totalPagesCount - 1
        ];
    }
}
