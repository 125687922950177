import { MultiTextbox } from './multi-textbox.model';
import { RadioQuestion } from './radio.model';
import { TextboxQuestion } from './textbox.model';
import { CustomAnswerRadio } from './custom-answer-radio.model';
import { SliderQuestion } from './slider.model';
import { CheckboxSet } from './checkbox-set.model';
import { Addressbox } from './addressbox.model';
import { Dropdown } from './dropdown.model';
import { SearchDropdown } from './search-dropdown.model';
import { CheckboxQuestion } from './checkbox.model';

export const TypesHTMLHelper = {
    TextboxQuestion,
    RadioQuestion,
    MultiTextbox,
    CustomAnswerRadio,
    SliderQuestion,
    CheckboxSet,
    CheckboxQuestion,
    Addressbox,
    Dropdown,
    SearchDropdown,
    NotAGroup: TextboxQuestion || RadioQuestion
};
