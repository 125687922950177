import { Pipe, PipeTransform } from '@angular/core';
import { GegRecommendation } from '@customer-apps/api-planning-projects';
import { GegRecommendationType } from '@customer-apps/shared/enums';

@Pipe({
    name: 'gegRecommendationType'
})
export class GegRecommendationTypePipe implements PipeTransform {
    transform(gegRecommendation: GegRecommendation): string {
        const types: any = {
            [GegRecommendationType.Info]: 'geg-info',
            [GegRecommendationType.Problem]: 'geg-problem',
            [GegRecommendationType.Warning]: 'geg-warning'
        };
        return gegRecommendation?.recommendationType ? types[gegRecommendation.recommendationType] : '';
    }
}
