import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmConfig, ConfirmOutput } from '@customer-apps/shared/interfaces';

@Component({
    selector: 'vp-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
    public form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<ConfirmComponent, ConfirmOutput>,
        @Inject(MAT_DIALOG_DATA)
        public data: ConfirmConfig,
        private formBuilder: FormBuilder
    ) {}

    public ngOnInit(): void {
        if (this.data.formOptions) {
            this.form = this.formBuilder.group(this.data.formOptions);
        }
    }

    public onClose(): void {
        this.dialogRef.close({ close: true });
    }

    public onCancel(): void {
        this.dialogRef.close({ cancel: true });
    }

    public onConfirm(): void {
        this.dialogRef.close(this.data.formOptions ? { form: this.form.value, confirm: true } : { confirm: true });
    }
}
