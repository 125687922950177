import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormKeys, GEGSystemType } from '@customer-apps/shared/enums';
import { FormOptions, QuestionOverride } from '@customer-apps/shared/interfaces';
import { GEGSystemTypeToPhraseKey, Question, RadioQuestion } from '@customer-apps/shared/utils';

@Injectable({
    providedIn: 'root'
})
export class GEGFormService {
    constructor() {}

    public getSystemQuestions(options?: FormOptions): Question[] {
        const systemTypeOverride = options?.questions?.[FormKeys.System];
        const systemType = this.getSystemType(systemTypeOverride);
        return [systemType];
    }

    private getSystemType(override?: QuestionOverride): RadioQuestion {
        return new RadioQuestion({
            key: FormKeys.System,
            question: 'GEG_SYSTEM_CHOICE.SYSTEM.SYSTEM_TYPE.TITLE',
            styles: 'd-flex flex-column vp-radio-margin vp-radio-width-400',
            defaultErrorMessage: 'GEG_SYSTEM_CHOICE.SYSTEM.SYSTEM_TYPE.ERRORS.REQUIRED',
            validators: [Validators.required],
            value: override?.value,
            options: [
                { key: GEGSystemTypeToPhraseKey[GEGSystemType.Current], value: GEGSystemType.Current },
                { key: GEGSystemTypeToPhraseKey[GEGSystemType.New], value: GEGSystemType.New }
            ]
        });
    }
}
