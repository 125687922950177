import { Injectable } from '@angular/core';
import { FormName } from '@customer-apps/shared/enums';
import { Subject, distinctUntilChanged } from 'rxjs';

/**
 * Helps to recognize the form which is currently under edition so the accordions can recongize the moment when to set themselves into disabled state.
 */
@Injectable({
    providedIn: 'root'
})
export class FormStateService {
    private _editedForm$: Subject<FormName | null> = new Subject();

    public editedForm$ = this._editedForm$.asObservable().pipe(distinctUntilChanged());

    /**
     * Emits the name of the form which is currently under edition
     */
    public setEditedForm(formName: FormName | null) {
        this._editedForm$.next(formName);
    }
}
