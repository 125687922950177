/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ProjectResponseGetById } from '../../models/project-response-get-by-id';

export interface ProjectsControllerGetProject$Params {

/**
 * Project id.
 */
  id: string;

/**
 * Optional parameter the planning application IDs list, informing that
 *             we want to obtain additional planning details for given applications. By providing
 *             this optional query parameters, given application plannings will be extended by two more
 *             detail fields called <b>applicationSpecific</b> and <b>electricityDemandHourly</b>.
 *             <br>
 *             <i>The planning for provided application ID may not exist in project, it will not throw any
 *             error, we can provide number of <b>detailsForApplicationId</b>, even for non-existing plannings.</i>
 *             <br>
 *             Please be aware that more applications need more background requests to get the details,
 *             so this call may take longer in total for bigger number of detailsForApplicationIds
 *             query parameters.
 */
  detailsForApplicationId?: Array<'HEAT_PUMP_PLANNER' | 'PHOTOVOLTAIC_PLANNER' | 'VENTILATION_PLANNER' | 'VIGUIDE_PLANNING'>;
}

export function projectsControllerGetProject(http: HttpClient, rootUrl: string, params: ProjectsControllerGetProject$Params, context?: HttpContext): Observable<StrictHttpResponse<ProjectResponseGetById>> {
  const rb = new RequestBuilder(rootUrl, projectsControllerGetProject.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
    rb.query('detailsForApplicationId', params.detailsForApplicationId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ProjectResponseGetById>;
    })
  );
}

projectsControllerGetProject.PATH = '/planning-projects/v1-develop/projects/{id}';
