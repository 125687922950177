import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'join'
})
export class JoinPipe implements PipeTransform {
    transform(value: Object, separator: string): string {
        return Object.values(value).join(separator);
    }
}
