import { EnumDictionary } from '../types';
import { TechnicalDataKeys } from '@customer-apps/shared/enums';

export const technicalDataKeysToUnit: Partial<EnumDictionary<TechnicalDataKeys, string>> = {
    [TechnicalDataKeys.MaxDCPowerIn]: 'MEASUREMENT_UNITS.W',
    [TechnicalDataKeys.MaxACPowerOut]: 'MEASUREMENT_UNITS.VA',
    [TechnicalDataKeys.MaxEfficiency]: 'MEASUREMENT_UNITS.PERCENT',
    [TechnicalDataKeys.MaxModuleEfficiency]: 'MEASUREMENT_UNITS.PERCENT',
    [TechnicalDataKeys.MaxChargingPower]: 'MEASUREMENT_UNITS.KW',
    [TechnicalDataKeys.MaxDischargingPower]: 'MEASUREMENT_UNITS.KW',
    [TechnicalDataKeys.BatteryCapacity]: 'MEASUREMENT_UNITS.KWH',
    [TechnicalDataKeys.ModuleNominalPower]: 'MEASUREMENT_UNITS.WP',
    [TechnicalDataKeys.Dimensions]: 'MEASUREMENT_UNITS.MM'
};
