import { ControlTypes } from '@customer-apps/shared/enums';
import { Mask, SliderOptions } from '@customer-apps/shared/interfaces';
import { QuestionBase } from './question-base.model';

export class SliderQuestion extends QuestionBase<number | ''> {
    override controlType = ControlTypes.Slider;
    public placeholder: string;
    public suffix: string;
    public min: number;
    public max: number;
    public mask?: Mask;
    constructor(options: SliderOptions) {
        super(options);
        this.placeholder = options.placeholder || '';
        this.suffix = options.suffix || '';
        this.max = options.max;
        this.min = options.min;
        this.value = options.value || '';
    }
}
