export enum MigrationSectionType {
    /**
     * Global language or country codes.
     */
    LocaleData = 'LOCALE_DATA',
    /**
     * Date related to building physical address, like street name, house number or postal code.
     */
    BuildingAddressData = 'BUILDING_ADDRESS_DATA',
    /**
     * Basic data related to the building, like number of persons living in the building.
     */
    BasicData = 'BASIC_BUILDING_DATA',
    /**
     * All unclassified parameters related to building, like building location, heating type and many more.
     * Heat load calculation methods section.
     * Old heating system, valid for modernisation only.
     */
    CalculationParameters = 'CALCULATION_PARAMS',
    /**
     * A data related to the energy prices & tariffs.
     */
    EnergyPricesAndTariffsData = 'ENERGY_PRICES_AND_TARIFFS_DATA',
    /**
     * A data related to plannings and variants required by ViGuide Planning.
     * A data related to planning products.
     */
    PlanningData = 'PLANNING_PRODUCTS'
}
