import { Component } from '@angular/core';

import { GlobalSpinnerService } from './global-spinner.service';

@Component({
    selector: 'vp-global-spinner',
    templateUrl: './global-spinner.component.html',
    styleUrls: ['./global-spinner.component.scss']
})
export class GlobalSpinnerComponent {
    constructor(public globalSpinnerService: GlobalSpinnerService) {}
}
