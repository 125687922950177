import { Injectable } from '@angular/core';
import { withLatestFrom, distinctUntilChanged, map, Observable, filter, first, merge } from 'rxjs';
import { HeatloadStore } from '../../state/heatload/heatload.store';
import { HeatloadPrefillService } from './heatload-prefill.service';
import { FormName, ProjectType, SuccessMessages } from '@customer-apps/shared/enums';
import { HeatloadFormService } from './heatload-form.service';
import { TranslateService } from '@ngx-translate/core';
import { HeatloadFormOptionsService } from './heatload-form-options.service';
import { AccordionService, VentilationFormValue } from '@customer-apps/shared/interfaces';
import { ProjectStore } from '../../state/project/project.store';
import { HeatloadPrefillMapper, ProjectsService } from '../../services';
import { ActivatedRoute } from '@angular/router';
import { HeatloadService } from './heatload.service';

@Injectable({
    providedIn: 'root'
})
export class VentilationService implements AccordionService {
    constructor(
        private heatloadPrefillService: HeatloadPrefillService,
        private prefillMapper: HeatloadPrefillMapper,
        private heatloadStore: HeatloadStore,
        private heatloadService: HeatloadService,
        private heatloadFormService: HeatloadFormService,
        private heatloadFormOptionsService: HeatloadFormOptionsService,
        private projectStore: ProjectStore,
        private projectsService: ProjectsService,
        private route: ActivatedRoute,
        private translate: TranslateService
    ) {}

    public questions$ = this.heatloadStore.modernization$.pipe(
        withLatestFrom(this.projectStore.project$, this.heatloadStore.cache$, (modernization, project, cache) => ({
            modernization,
            project,
            cache
        })),
        map(state => {
            if (!state.modernization) {
                return null;
            }

            const projectPrefill = this.prefillMapper.projectToVentilationPrefill(state.project!);
            const cachePrefill = this.prefillMapper.stateToVentilationPrefill(state.cache);
            const formOptions = this.heatloadFormOptionsService.getVentilationFormOptions(cachePrefill || projectPrefill);
            return this.heatloadFormService.getVentilationQuestions(formOptions);
        })
    );

    public isPrefillNeeded(): boolean {
        return this.heatloadService.isRecalculation() && !this.heatloadPrefillService.isPrefilled(FormName.Ventilation);
    }

    public afterPrefill(): void {
        this.heatloadPrefillService.setAsPrefilled(FormName.Ventilation);
    }

    public getSuccessMessage(isVentilation: VentilationFormValue): string {
        const message = isVentilation.ventilation ? SuccessMessages.Yes : SuccessMessages.No;
        return this.translate.instant(message);
    }

    public isExpanded$ = merge(this.isSameValueSubmitedModernizations$(), this.prefillCompletedByLivingArea$());

    private isSameValueSubmitedModernizations$(): Observable<boolean> {
        return this.heatloadStore.sameValueSubmited$.pipe(map(form => form?.formName === FormName.Modernizations));
    }

    private prefillCompletedByLivingArea$(): Observable<boolean> {
        return this.heatloadPrefillService.state$.pipe(
            withLatestFrom(this.projectStore.project$, (prefillState, project) => ({ prefillState, project })),
            map(({ prefillState, project }) => {
                const lastAccordionToBePrefilled = this.projectsService.isNewConstruction(project!.projectType as ProjectType)
                    ? FormName.EnergyStandard
                    : FormName.Ventilation;
                const isRecalculation = this.route.firstChild?.firstChild?.snapshot.queryParamMap.get('method');
                return prefillState[lastAccordionToBePrefilled].isPrefilled && !isRecalculation;
            }),
            filter(Boolean),
            first()
        );
    }
}
