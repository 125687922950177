import { EnergySource } from '@customer-apps/shared/enums';
import { EnumDictionary } from '../types';

export const energySourceToPhraseKey: Partial<EnumDictionary<EnergySource, string>> = {
    [EnergySource.Pellet]: 'HEATLOAD.ENERGY_SOURCE.SOURCES.PELLET',
    [EnergySource.Softwood]: 'HEATLOAD.ENERGY_SOURCE.SOURCES.SOFTWOOD',
    [EnergySource.Hardwood]: 'HEATLOAD.ENERGY_SOURCE.SOURCES.HARDWOOD',
    [EnergySource.Gas]: 'HEATLOAD.ENERGY_SOURCE.SOURCES.GAS',
    [EnergySource.Methane]: 'HEATLOAD.ENERGY_SOURCE.SOURCES.METHANE',
    [EnergySource.Hydrogen]: 'HEATLOAD.ENERGY_SOURCE.SOURCES.HYDROGEN'
};
