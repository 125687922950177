import { Injectable } from '@angular/core';
import { SystemItem } from '@customer-apps/api-planning-projects';
import { Mask } from '@customer-apps/shared/interfaces';
import { getPostalCodeMask, numericHint } from '@customer-apps/shared/utils';
import { LocaleService } from './locale.service';

@Injectable({
    providedIn: 'root'
})
export class FormToolsService {
    constructor(private localeService: LocaleService) {}

    public getNumericMask(options?: { separatorLimit?: string; numberOfDecimals?: string }): Mask {
        const numberOfDecimals = options?.numberOfDecimals || '0';
        const separatorLimit = options?.separatorLimit || '';

        return {
            pattern: `separator.${numberOfDecimals}`,
            decimalMarker: this.localeService.getDecimalMarker() as ',' | '.',
            separatorLimit
        };
    }

    public getNumericHint(): (value: string) => string | null {
        const decimalMarker = this.localeService.getDecimalMarker();
        return numericHint(decimalMarker);
    }

    public hasBoilerOptionsToAsk(installationType: SystemItem | undefined): boolean {
        return !!installationType?.isLowTemperature || !!installationType?.warmWaterByHeatGenerator;
    }

    public getPostalCodeMask(countryCode: string) {
        return getPostalCodeMask(countryCode);
    }

    public getLimitedTextMask(numberOfLetters: number): Mask {
        const customPatterns = {
            Z: {
                pattern: new RegExp('.'),
                optional: true
            }
        };

        return { pattern: `Z{${numberOfLetters}}`, customPatterns, dropSpecialCharacters: false };
    }

    public hasHeatGeneratorInstallationYearHint(
        heatGeneratorInstallationYear: string | undefined,
        buildingConstructionYear: number | undefined
    ): boolean {
        return !heatGeneratorInstallationYear && !!buildingConstructionYear;
    }
}
