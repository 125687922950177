import { Directive, Input, HostListener } from '@angular/core';
import { SnackbarOptions } from '@customer-apps/shared/interfaces';
import { SnackbarService } from '@customer-apps/shared/services';

@Directive({
    selector: '[vpSnackbar]'
})
export class SnackbarDirective {
    @Input('vpSnackbar') message: string;

    @Input() vpSnackbarOptions: SnackbarOptions;

    @HostListener('click') onClick() {
        if (this.message) {
            this.snackbarService.showInfo(this.message, this.vpSnackbarOptions);
        }
    }

    constructor(private snackbarService: SnackbarService) {}
}
