export const getUpdatedProperties = <T extends NonNullable<unknown>>(oldObj: T, newObj: T, ignoredProps?: string[]): Partial<T> => {
    const updatedObject: Partial<T> = {};

    const keys = Object.keys(newObj) as Array<keyof T>;
    keys.forEach(key => {
        const oldValue = oldObj?.[key];
        const newValue = newObj?.[key];

        if (ignoredProps?.includes(key as string)) {
            updatedObject[key] = newValue;
        } else if (Array.isArray(newValue)) {
            // If the property is an array, check if it's different, then include the whole array.
            if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
                updatedObject[key] = newValue;
            }
        } else if (typeof newValue === 'object' && newValue !== null && oldValue !== null) {
            // If the property is an object, recursively get its updated properties.
            const nestedChanges = getUpdatedProperties(oldValue as T, newValue as T, ignoredProps);
            if (Object.keys(nestedChanges).length > 0) {
                updatedObject[key] = nestedChanges as any;
            }
        } else {
            // For properties of other types, check if they have changed.
            if (oldValue !== newValue) {
                updatedObject[key] = newValue;
            }
        }
    });

    return updatedObject;
};
