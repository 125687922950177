/**
 * Defines a heating device type used in the building.
 */
export enum HeatingSystemType {
    CondensingBoiler = 'CONDENSING_BOILER',
    SolidFuelBoiler = 'SOLID_FUEL_BOILER',
    SpecialGasBoiler = 'GAS_SPECIAL_BOILER',
    FanBoiler = 'FAN_BOILER',
    FanBoilerChangedBurner = 'FAN_BOILER_WITH_CHANGED_BURNER',
    CirculatingWaterBoiler = 'CIRCULATION_WATER_BOILER',
    TwoFuelChangeBoiler = 'TWO_FUEL_CHANGE_BOILER',
    GroundwaterHeatPump = 'GROUNDWATER_HEAT_PUMP',
    SoilHeatPump = 'SOIL_HEAT_PUMP',
    AirHeatPump = 'AIR_HEAT_PUMP',
    ElectricHeater = 'ELECTRIC_HEATER',
    SolarThermal = 'SOLAR_THERMAL',
    DistrictHeating = 'DISTRICT_HEATING'
}
