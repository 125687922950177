/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { HeatLoadByLivingAreaRequestPost } from '../../models/heat-load-by-living-area-request-post';
import { HeatLoadByLivingAreaResponse } from '../../models/heat-load-by-living-area-response';

export interface CalculationsControllerLivingArea$Params {
      body: HeatLoadByLivingAreaRequestPost
}

export function calculationsControllerLivingArea(http: HttpClient, rootUrl: string, params: CalculationsControllerLivingArea$Params, context?: HttpContext): Observable<StrictHttpResponse<HeatLoadByLivingAreaResponse>> {
  const rb = new RequestBuilder(rootUrl, calculationsControllerLivingArea.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<HeatLoadByLivingAreaResponse>;
    })
  );
}

calculationsControllerLivingArea.PATH = '/planning-projects/v1-develop/calculations/heat-load/living-area';
