import { HeatGeneratorType } from '@customer-apps/shared/enums';
import { EnumDictionary } from '../types';

export const heatGeneratorToIconName: EnumDictionary<HeatGeneratorType, string> = {
    [HeatGeneratorType.OilBoiler]: 'oil-boiler',
    [HeatGeneratorType.DistrictHeating]: 'district-heating',
    [HeatGeneratorType.ElectricHeater]: 'electric-heater',
    [HeatGeneratorType.GasBoiler]: 'gas-boiler',
    [HeatGeneratorType.SolarThermal]: 'solar-thermal',
    [HeatGeneratorType.WoodStove]: 'wood-stove',
    [HeatGeneratorType.HeatPump]: 'heat-pump'
};
