import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, EMPTY, Observable } from 'rxjs';
import { LanguageCountryDictionary, LanguageDictionary } from '@customer-apps/shared/utils';
import { AuthStore } from '../../../state/auth/auth.store';
import { SettingsStore } from '../../../state/settings/settings.store';
import { CountryLangugePair } from '@customer-apps/shared/interfaces';
import { SnackbarService } from '@customer-apps/shared/services';
import { RollOutPolicy } from '../../../policies';

@Component({
    selector: 'vp-country-language-switcher',
    templateUrl: './country-language-switcher.component.html',
    styleUrls: ['./country-language-switcher.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountryLanguageSwitcherComponent implements OnInit {
    public chevronIcon: 'expand_more' | 'expand_less' = 'expand_more';
    public languageCountryType = LanguageCountryDictionary;
    public languageType = LanguageDictionary;
    public countryLanguagePairs$: Observable<CountryLangugePair[]>;

    constructor(
        public settingsStore: SettingsStore,
        public authStore: AuthStore,
        private snackbarService: SnackbarService,
        private rollOutPolicy: RollOutPolicy,
        private translateSerivice: TranslateService
    ) {}

    ngOnInit() {
        this.countryLanguagePairs$ = this.rollOutPolicy.getCountryLanguagePairs$().pipe(
            catchError(() => {
                const message = this.translateSerivice.instant('COMMON.ERRORS.LANGUAGE_SWITCHER');
                this.snackbarService.showInfo(message);
                return EMPTY;
            })
        );
    }
}
