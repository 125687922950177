<ng-container [formGroup]="form">
    <div [formGroupName]="question.groupName" [ngClass]="question.styles">
        <ng-container *ngFor="let questionPart of question.questions; trackBy: trackByKey; let isLast = last; let isFirst = first">
            <span *ngIf="questionPart.paragraph" class="me-2" [class.ms-1]="!isFirst"> {{ questionPart.paragraph | translate }}</span>
            <mat-form-field
                [hideRequiredMarker]="true"
                appearance="outline"
                subscriptSizing="dynamic"
                [class.with-label]="questionPart.label"
                [ngClass]="questionPart.styles"
            >
                <mat-label *ngIf="questionPart.label" [attr.for]="questionPart.key">{{ questionPart.label | translate }}</mat-label>
                <input
                    [formControlName]="questionPart.key"
                    [placeholder]="questionPart.placeholder | translate"
                    [id]="questionPart.key"
                    [attr.data-testid]="questionPart.key"
                    [type]="questionPart.type"
                    [mask]="questionPart.mask.pattern"
                    [patterns]="$any(questionPart).mask?.customPatterns"
                    [decimalMarker]="$any(questionPart).mask?.decimalMarker"
                    [separatorLimit]="$any(questionPart).mask?.separatorLimit"
                    [dropSpecialCharacters]="
                        ($any(questionPart).mask?.dropSpecialCharacters | isNil) ? true : $any(questionPart).mask?.dropSpecialCharacters
                    "
                    [suffix]="questionPart.suffix ? ' ' + (questionPart.suffix | translate) : ''"
                    matInput
                />
                <mat-hint *ngIf="questionPart.permanentHint">
                    {{ questionPart.permanentHint | translate }}
                </mat-hint>
                <mat-error *ngIf="isInvalid(questionPart)">
                    <vp-form-error-message>
                        {{ errorMessage(questionPart) | translate }}
                    </vp-form-error-message>
                </mat-error>
            </mat-form-field>
            <span class="ms-1" *ngIf="questionPart.paragraph && isLast">.</span>
        </ng-container>
    </div>
</ng-container>
