import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SettingsActions } from './settings.actions';
import { SettingsSelectors } from './settings.selectors';
import { AppState } from '@customer-apps/shared/interfaces';

@Injectable({ providedIn: 'root' })
export class SettingsStore {
    public state$ = this.store$.pipe(select(SettingsSelectors.getState()));
    public countryCode$ = this.store$.pipe(select(SettingsSelectors.getCountryCode()));
    public locale$ = this.store$.pipe(select(SettingsSelectors.getLocale()));
    public isInitialized$ = this.store$.pipe(select(SettingsSelectors.getIsInitialized()));
    public languageCode$ = this.store$.pipe(select(SettingsSelectors.getLanguageCode()));
    public salesOrganization$ = this.store$.pipe(select(SettingsSelectors.getSalesOrganization()));

    constructor(private store$: Store<AppState>) {}

    initDefaultLocale(): void {
        this.store$.dispatch(SettingsActions.initDefaultLocale());
    }

    changeLocale(locale: string, reloadUrl?: string): void {
        this.store$.dispatch(SettingsActions.changeLocale({ locale, reload: true, reloadUrl }));
    }
}
