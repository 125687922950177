export enum TechnicalDataKeys {
    MaxDCPowerIn = 'maxDCPowerIn',
    MaxACPowerOut = 'maxACPowerOut',
    MaxEfficiency = 'maxEfficiency',
    MaxModuleEfficiency = 'maxModuleEfficiency',
    MaxChargingPower = 'maxChargingPower',
    MaxDischargingPower = 'maxDischargingPower',
    BatteryCapacity = 'batteryCapacity',
    ModuleNominalPower = 'moduleNominalPower',
    Dimensions = 'dimensions',
    Length = 'length',
    Width = 'width',
    Height = 'height'
}
