import { AppState } from '@customer-apps/shared/interfaces';
import { createSelector } from '@ngrx/store';

export namespace HeatPumpIntermediarySelectors {
    const heatPumpIntermediaryState = (state: AppState) => state.heatPumpIntermediary;

    export const getState = createSelector(heatPumpIntermediaryState, state => state);

    export const isOldDeviceUsed$ = createSelector(heatPumpIntermediaryState, state => state.isOldDeviceUsed);

    export const subsidyEligibility$ = createSelector(heatPumpIntermediaryState, state => state.subsidyEligibility);

    export const livingArea = createSelector(heatPumpIntermediaryState, state => state.livingArea);

    export const heatGenerators$ = createSelector(heatPumpIntermediaryState, state => state.heatGenerators);

    export const heatGeneratorTypes$ = createSelector(heatGenerators$, generators => generators?.map(item => item.heatGeneratorType));

    export const consumptionProfiles$ = createSelector(heatPumpIntermediaryState, state => state.consumptionProfiles);

    export const selectedHeatingEnergySource$ = createSelector(heatPumpIntermediaryState, state => state.heatingEnergySource);

    export const building$ = createSelector(heatPumpIntermediaryState, state => state.building);

    export const getHeatGeneratorDetails = createSelector(heatPumpIntermediaryState, state => state.heatGeneratorDetails);

    export const getEnergyConsumption = createSelector(heatPumpIntermediaryState, state => state.energyConsumption);

    export const getHeatingDistribution = createSelector(heatPumpIntermediaryState, state => state.heatingDistribution);

    export const heatGeneratorsAndOldDeviceUsage$ = createSelector(heatGeneratorTypes$, isOldDeviceUsed$, (generators, isOldDeviceUsed) => {
        return { heatGenerators: generators, isOldDeviceUsed: isOldDeviceUsed?.value };
    });

    export const getHeatingSystems = createSelector(heatPumpIntermediaryState, state => state.heatingSystems);

    export const heatGeneratorsAndConsumptionProfilesAndOldDeviceUsage$ = createSelector(
        heatGeneratorTypes$,
        consumptionProfiles$,
        isOldDeviceUsed$,
        (generators, profiles, isOldDeviceUsed) => {
            return { heatGenerators: generators, consumptionProfiles: profiles, isOldDeviceUsed: isOldDeviceUsed?.value };
        }
    );

    export const sameValueSubmited = createSelector(heatPumpIntermediaryState, state => state.sameValueSubmited);

    export const cache = createSelector(heatPumpIntermediaryState, state => state.cache);
}
