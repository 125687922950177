import { HeatLoadCalculationMethod } from '@customer-apps/shared/enums';
import { EnumDictionary } from '@customer-apps/shared/utils';

export const CalculationMethodToPhraseKeyDictionary: EnumDictionary<HeatLoadCalculationMethod, string> = {
    [HeatLoadCalculationMethod.BuildingHull]: 'HEATLOAD.CALCULATION_METHOD.BUILDING_HULL',
    [HeatLoadCalculationMethod.Consumption]: 'HEATLOAD.CALCULATION_METHOD.CONSUMPTION',
    [HeatLoadCalculationMethod.EnergyCertificate]: 'HEATLOAD.CALCULATION_METHOD.ENERGY_CERTIFICATE',
    [HeatLoadCalculationMethod.LivingSpace]: 'HEATLOAD.CALCULATION_METHOD.LIVING_SPACE',
    [HeatLoadCalculationMethod.CustomValue]: 'HEATLOAD.CALCULATION_METHOD.CUSTOM_VALUE'
};
