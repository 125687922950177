class QueueItem<T> {
    public next?: QueueItem<T>;
    constructor(public value: T) {}
}

export class Queue<T> {
    private first?: QueueItem<T>;
    private last?: QueueItem<T>;

    /**
     *  Add element to the end of the queue
     */
    public enqueue(item: T) {
        const queueItem = new QueueItem(item);
        if (!this.last) {
            this.last = queueItem;
        } else {
            this.last.next = queueItem;
            this.last = queueItem;
        }

        if (!this.first) {
            this.first = queueItem;
        }
    }

    /**
     * Remove element from the front of the queue
     */
    public dequeue(): T | undefined {
        const dequeuedItem = this.first;
        this.first = dequeuedItem?.next;

        return dequeuedItem?.value;
    }

    public getFirst() {
        return this.first ? Object.assign({}, this.first) : undefined;
    }

    public getLast() {
        return this.last ? Object.assign({}, this.last) : undefined;
    }

    public toArray(): QueueItem<T>[] {
        const array: QueueItem<T>[] = [];
        this._toArray(this.first, array);
        return array;
    }

    private _toArray(target: QueueItem<T> | undefined, array: QueueItem<T>[]): void {
        if (!target) {
            return;
        }

        array.push(target);
        this._toArray(target.next, array);
    }
}
