import { ApplicationId } from '@customer-apps/shared/enums';
import { EnumDictionary } from '@customer-apps/shared/utils';

export const variantApplicationIdDictionary: EnumDictionary<ApplicationId, string> = {
    [ApplicationId.HeatPumpPlanner]: 'VARIANT_TILE.SYSTEM_NAME.HEAT_PUMP_PLANNER',
    [ApplicationId.PhotovoltaicPlanner]: 'VARIANT_TILE.SYSTEM_NAME.PHOTOVOLTAIC_PLANNER',
    [ApplicationId.VentilationPlanner]: 'VARIANT_TILE.SYSTEM_NAME.VENTILATION_PLANNER',
    [ApplicationId.CogenerationCalculator]: 'VARIANT_TILE.SYSTEM_NAME.COGENERATION_CALCULATOR',
    [ApplicationId.ElectricityPlanner]: 'VARIANT_TILE.SYSTEM_NAME.ELECTRICITY_PLANNER',
    [ApplicationId.SystemAdviser]: 'VARIANT_TILE.SYSTEM_NAME.SYSTEM_ADVISER',
    [ApplicationId.CylinderPlanner]: 'VARIANT_TILE.SYSTEM_NAME.CYLINDER_PLANNER',
    [ApplicationId.ViGuidePlanning]: 'VARIANT_TILE.SYSTEM_NAME.VIGUIDE_PLANNING'
};
