import { createAction, props } from '@ngrx/store';
import {
    BuildingHullFormValue,
    CalculationMethodValue,
    DistributionAndScheduleFormValue,
    EnergyCertificateFormValue,
    EnergyConsumptionFormValue,
    EnergyStandardFormValue,
    HeatGeneratorDetailsFormValue,
    HeatGeneratorFormValue,
    InsulationFormValue,
    LivingAreaFormValue,
    ModernizationsAndInsulationFormValue,
    ModernizationsFormValue,
    RoofTypeFormValue,
    VentilationFormValue,
    WindowsAndGlazingFormValue,
    RoofDimensionsFormValue,
    SameFormValue
} from '@customer-apps/shared/interfaces';
import { EnergyStandard, HeatGeneratorInformation, SystemItem, WindowInformation } from '@customer-apps/api-planning-projects';

export namespace HeatloadActions {
    export const submitCalculationMethod = createAction('[HEATLOAD] Submit Calculation Method', props<CalculationMethodValue>());

    export const submitLivingArea = createAction('[HEATLOAD] Submit Living Area', props<LivingAreaFormValue>());

    export const submitModernization = createAction('[HEATLOAD] Submit Modernizations', props<ModernizationsFormValue>());

    export const submitVentilation = createAction('[HEATLOAD] Submit Ventilation', props<VentilationFormValue>());

    export const submitEnergyCertificate = createAction('[HEATLOAD] Submit Energy Certificate', props<EnergyCertificateFormValue>());

    export const submitBuildingHull = createAction('[HEATLOAD] Submit Building Hull', props<BuildingHullFormValue>());

    export const submitRoofType = createAction('[HEATLOAD] Submit Roof Type', props<RoofTypeFormValue>());

    export const submitRoofDimensions = createAction('[HEATLOAD] Submit Roof Dimensions', props<RoofDimensionsFormValue>());

    export const submitModernizationsAndInsulation = createAction(
        '[HEATLOAD] Submit Modernizations And Insulation',
        props<ModernizationsAndInsulationFormValue | InsulationFormValue>()
    );

    export const submitWindowsAndGlazing = createAction('[HEATLOAD] Submit Windows And Glazing', props<WindowsAndGlazingFormValue>());

    export const submitEnergyStandard = createAction('[HEATLOAD] Submit Energy Standard Value', props<EnergyStandardFormValue>());

    export const submitHeatGenerator = createAction('[HEATLOAD] Submit Heat Generator', props<HeatGeneratorFormValue>());

    export const submitHeatGeneratorDetails = createAction(
        '[HEATLOAD] Submit Heat Generator Details',
        props<HeatGeneratorDetailsFormValue>()
    );

    export const submitEnergyConsumption = createAction('[HEATLOAD] Submit Energy Consumption', props<EnergyConsumptionFormValue>());

    export const submitHeatingDistribution = createAction(
        '[HEATLOAD] Submit Distribution And Schedule',
        props<DistributionAndScheduleFormValue>()
    );

    export const submitSameValue = createAction('[HEATLOAD] Submit Same Value', props<SameFormValue>());

    export const resetSameValue = createAction('[HEATLOAD] Reset Same Value');

    export const getHeatGeneratorsSuccess = createAction(
        '[HEATLOAD] Get Heat Generators Success',
        props<{ generators: HeatGeneratorInformation[] }>()
    );

    export const getWindowsSuccess = createAction('[HEATLOAD] Get Windows Success', props<{ windows: WindowInformation[] }>());

    export const getHeatingSystems = createAction('[HEATLOAD] Get Heating Systems', props<{ installationYear: string }>());

    export const getHeatingSystemsSuccess = createAction(
        '[HEATLOAD] Get Heating Systems Success',
        props<{ heatingSystems: SystemItem[] }>()
    );

    export const getEnergyStandards = createAction('[HEATLOAD] Get Energy Standards');

    export const getEnergyStandardsSuccess = createAction(
        '[HEATLOAD] Get Energy Standards Success',
        props<{ standards: EnergyStandard[] }>()
    );

    export const resetHeatload = createAction('[HEATLOAD] Reset');
}
