export enum ControlTypes {
    Textbox = 'Textbox',
    Radio = 'Radio',
    MultiTextbox = 'MultiTextbox',
    CustomAnswerRadio = 'CustomAnswerRadio',
    Slider = 'Slider',
    Checkbox = 'Checkbox',
    CheckboxSet = 'CheckboxSet',
    Dropdown = 'Dropdown',
    SearchDropdown = 'SearchDropdown',
    Addressbox = 'Addressbox'
}
