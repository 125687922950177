import { ProjectStatus } from '@customer-apps/shared/enums';
import { EnumDictionary } from '@customer-apps/shared/utils';

export const projectStatusDictionary: EnumDictionary<ProjectStatus, string> = {
    [ProjectStatus.Design]: 'PROJECT_STATUS.OPEN',
    [ProjectStatus.PlanningDone]: 'PROJECT_STATUS.PLANNING_DONE',
    [ProjectStatus.QuoteCreated]: 'PROJECT_STATUS.QUOTE_CREATED',
    [ProjectStatus.OrderPlaced]: 'PROJECT_STATUS.ORDER_PLACED',
    [ProjectStatus.Completed]: 'PROJECT_STATUS.COMPLETED',
    [ProjectStatus.Closed]: 'PROJECT_STATUS.CLOSED',
    [ProjectStatus.Migrated]: 'PROJECT_STATUS.MIGRATED',
    [ProjectStatus.Archived]: 'PROJECT_STATUS.ARCHIVED',
    [ProjectStatus.MigratedClosed]: 'PROJECT_STATUS.MIGRATED_CLOSED'
};
