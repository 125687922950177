import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../services';

@Injectable()
export class EnvironmentHeadersInterceptor implements HttpInterceptor {
    constructor(private envService: EnvironmentService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        request = request.clone({
            setHeaders: this.envService.environmentHeaders
        });
        const request$ = next.handle(request);
        return request$;
    }
}
