import { createAction, props } from '@ngrx/store';
import { AccountValidationResponse } from '@customer-apps/api-planning-projects';
import { UserResult } from '@customer-apps/shared/interfaces';

export namespace AuthActions {
    export const startAuthFlow = createAction('[AUTH] Start Auth Flow');
    export const getCsrfTokenFromStorage = createAction('[AUTH] Get CSRF Token From Storage');
    export const getCsrfToken = createAction('[AUTH] Get CSRF Token', props<{ appId: string }>());
    export const getCsrfTokenSuccess = createAction('[AUTH] Get CSRF Token Success', props<{ token: string }>());
    export const getCsrfTokenError = createAction('[AUTH] Get CSRF Token Error');

    export const getMe = createAction('[AUTH] Get Me');
    export const getMeSuccess = createAction('[AUTH] Get Me Success', props<{ me: UserResult }>());
    export const getMeError = createAction('[AUTH] Get Me Error');
    export const logout = createAction('[AUTH] Logout');
    export const clearToken = createAction('[AUTH] Clear Token');

    export const noSalesForceAccess = createAction('[AUTH] No Sales Force Access');
    export const validateAccess = createAction('[AUTH] Validate Access');
    export const validateAccessSuccess = createAction('[AUTH] Validate Access Success', props<{ access: AccountValidationResponse }>());

    export const authorize = createAction('[AUTH] Authorize');
    export const authorizeSuccess = createAction('[AUTH] Authorize Success');
}
