import { createSelector } from '@ngrx/store';
import { AppState, SettingsState } from '@customer-apps/shared/interfaces';
import { LocaleUtils } from '@customer-apps/shared/utils';

export namespace SettingsSelectors {
    const settingsState = (state: AppState) => state.settings;

    export const getState = () => createSelector(settingsState, (state: SettingsState) => state);

    export const getLocale = () => createSelector(settingsState, (state: SettingsState) => state.locale);

    export const getIsInitialized = () => createSelector(settingsState, (state: SettingsState) => state.isInitilized);

    export const getCountryCode = () =>
        createSelector(getLocale(), (locale: string | null) => (locale ? LocaleUtils.getCountryCode(locale) : null));

    export const getLanguageCode = () =>
        createSelector(getLocale(), (locale: string | null) => (locale ? LocaleUtils.getLanguageCode(locale) : null));

    export const getOrganizations = () => createSelector(settingsState, (state: SettingsState) => state.salesOrganizations);

    export const getSalesOrganization = () =>
        createSelector(getCountryCode(), getOrganizations(), (countryCode, organizations) => {
            if (!countryCode || !organizations) {
                return null;
            }
            const selectedOrganization = organizations.find(item => item.countryCode === countryCode);

            if (!selectedOrganization) {
                return null;
            }

            return selectedOrganization.salesOrganization;
        });
}
