/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ProjectsResponseGetRecordsList } from '../../models/projects-response-get-records-list';

export interface ProjectsControllerGetProjects$Params {

/**
 * ISO standard Alpha-2 country code.Optional parameter to include only the projects with given countryCode set globally.
 */
  countryCode?: 'AT' | 'BE' | 'CH' | 'CZ' | 'DE' | 'ES' | 'FR' | 'NL' | 'PL' | 'SK';

/**
 * Optional parameter informing if projects with
 *     projectStatus=ARCHIVED should be included in response.
 */
  includeArchivedProjects?: boolean;

/**
 * Optional parameter allowing to fetch all the projects regardless of the user.
 *     Works only for the users with Viguideplanning.Admin role.
 */
  allProjects?: boolean;

/**
 * Optional pagination parameter. Defines projects batch size per page.
 */
  pageSize?: number;

/**
 * Optional pagination parameter. Indexing is zero based, so page 1 is index 0.
 */
  pageIndex?: number;

/**
 * Optional filtering parameter for searching project by project name, user name or address.
 *     Should be a string containing a set of case insensitive words separated by space.
 */
  filter?: string;

/**
 * Optional filtering parameter. Defines which projects should be included in the response.
 */
  projectStatus?: ('CLOSED' | 'COMPLETED' | 'DESIGN' | 'ORDER_PLACED' | 'PLANNING_DONE' | 'QUOTE_CREATED' | Array<'CLOSED' | 'COMPLETED' | 'DESIGN' | 'ORDER_PLACED' | 'PLANNING_DONE' | 'QUOTE_CREATED'>);

/**
 * Optional filtering parameter that is used to filter projects based on the last modified date.
 *     The response will only include projects that were changed atfer the specified date. ISO date/time is expected.
 */
  lastChangeDateFrom?: string;

/**
 * Optional filtering parameter that is used to filter projects based on the last modified date.
 *     The response will only include projects that were changed before the specified date. ISO date/time is expected.
 */
  lastChangeDateTo?: string;

/**
 * Optional sorting parameter. Defines according to which parameter the result should be sorted.
 */
  orderBy?: 'PROJECT_NAME' | 'LAST_CHANGE_DATE';

/**
 * An optional sort parameter that specifies whether to sort the data in ascending or descending order
 *     based on the orderBy parameter.
 */
  orderType?: 'ASC' | 'DESC';
}

export function projectsControllerGetProjects(http: HttpClient, rootUrl: string, params?: ProjectsControllerGetProjects$Params, context?: HttpContext): Observable<StrictHttpResponse<ProjectsResponseGetRecordsList>> {
  const rb = new RequestBuilder(rootUrl, projectsControllerGetProjects.PATH, 'get');
  if (params) {
    rb.query('countryCode', params.countryCode, {});
    rb.query('includeArchivedProjects', params.includeArchivedProjects, {});
    rb.query('allProjects', params.allProjects, {});
    rb.query('pageSize', params.pageSize, {});
    rb.query('pageIndex', params.pageIndex, {});
    rb.query('filter', params.filter, {});
    rb.query('projectStatus', params.projectStatus, {});
    rb.query('lastChangeDateFrom', params.lastChangeDateFrom, {});
    rb.query('lastChangeDateTo', params.lastChangeDateTo, {});
    rb.query('orderBy', params.orderBy, {});
    rb.query('orderType', params.orderType, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ProjectsResponseGetRecordsList>;
    })
  );
}

projectsControllerGetProjects.PATH = '/planning-projects/v1-develop/projects';
