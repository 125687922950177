import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
    AppState,
    BuildingHullFormValue,
    CalculationMethodValue,
    DistributionAndScheduleFormValue,
    EnergyCertificateFormValue,
    EnergyConsumptionFormValue,
    EnergyStandardFormValue,
    HeatGeneratorDetailsFormValue,
    HeatGeneratorFormValue,
    InsulationFormValue,
    LivingAreaFormValue,
    ModernizationsAndInsulationFormValue,
    ModernizationsFormValue,
    RoofTypeFormValue,
    VentilationFormValue,
    WindowsAndGlazingFormValue,
    RoofDimensionsFormValue,
    SameFormValue
} from '@customer-apps/shared/interfaces';

import { HeatloadActions } from './heatload.actions';
import { HeatloadSelectors } from './heatload.selectors';

@Injectable({ providedIn: 'root' })
export class HeatloadStore {
    public state$ = this.store$.pipe(select(HeatloadSelectors.getState()));
    public definedValue$ = this.store$.pipe(select(HeatloadSelectors.getDefinedValue()));
    public calculationMethod$ = this.store$.pipe(select(HeatloadSelectors.getCalculationMethod()));
    public buildingHull$ = this.store$.pipe(select(HeatloadSelectors.getBuildingHull()));
    public roofType$ = this.store$.pipe(select(HeatloadSelectors.getRoofType()));
    public roofDimensions$ = this.store$.pipe(select(HeatloadSelectors.getRoofDimensions()));
    public modernizationsAndInsulation$ = this.store$.pipe(select(HeatloadSelectors.getModernizationsAndInsulation()));
    public windowsAndGlazing$ = this.store$.pipe(select(HeatloadSelectors.getWindowsAndGlazing()));
    public windows$ = this.store$.pipe(select(HeatloadSelectors.getWindows()));
    public livingArea$ = this.store$.pipe(select(HeatloadSelectors.getLivingArea()));
    public energyStandards$ = this.store$.pipe(select(HeatloadSelectors.getEnergyStandards()));
    public selectedEnergyStandard$ = this.store$.pipe(select(HeatloadSelectors.getSelectedEnergyStandard()));
    public modernization$ = this.store$.pipe(select(HeatloadSelectors.getModernization()));
    public ventilation$ = this.store$.pipe(select(HeatloadSelectors.getVentilation()));
    public energyCertificate$ = this.store$.pipe(select(HeatloadSelectors.getEnergyCertificate()));
    public heatGenerators$ = this.store$.pipe(select(HeatloadSelectors.getHeatGenerators()));
    public energySource$ = this.store$.pipe(select(HeatloadSelectors.getEnergySource()));
    public heatGeneratorDetails$ = this.store$.pipe(select(HeatloadSelectors.getHeatGeneratorDetails()));
    public energyConsumption$ = this.store$.pipe(select(HeatloadSelectors.getEnergyConsumption()));
    public heatingDistribution$ = this.store$.pipe(select(HeatloadSelectors.getHeatingDistribution()));
    public heatingSystems$ = this.store$.pipe(select(HeatloadSelectors.getHeatingSystems()));
    public sameValueSubmited$ = this.store$.pipe(select(HeatloadSelectors.sameValueSubmited()));
    public cache$ = this.store$.pipe(select(HeatloadSelectors.getCache()));

    constructor(private store$: Store<AppState>) {}

    public submitCalculationMethod(calculationMethod: CalculationMethodValue): void {
        this.store$.dispatch(HeatloadActions.submitCalculationMethod(calculationMethod));
    }

    public submitLivingArea(livingArea: LivingAreaFormValue): void {
        this.store$.dispatch(HeatloadActions.submitLivingArea(livingArea));
    }

    public submitModernization(modernization: ModernizationsFormValue): void {
        this.store$.dispatch(HeatloadActions.submitModernization(modernization));
    }

    public submitVentilation(ventilation: VentilationFormValue): void {
        this.store$.dispatch(HeatloadActions.submitVentilation(ventilation));
    }

    public submitEnergyCertificate(energyCertificate: EnergyCertificateFormValue): void {
        this.store$.dispatch(HeatloadActions.submitEnergyCertificate(energyCertificate));
    }

    public submitBuildingHull(buildingHull: BuildingHullFormValue): void {
        this.store$.dispatch(HeatloadActions.submitBuildingHull(buildingHull));
    }

    public submitRoofType(roofType: RoofTypeFormValue): void {
        this.store$.dispatch(HeatloadActions.submitRoofType(roofType));
    }

    public submitRoofDimensions(roofDimensions: RoofDimensionsFormValue): void {
        this.store$.dispatch(HeatloadActions.submitRoofDimensions(roofDimensions));
    }

    public submitModernizationsAndInsulation(
        modernizationsAndInsulation: ModernizationsAndInsulationFormValue | InsulationFormValue
    ): void {
        this.store$.dispatch(HeatloadActions.submitModernizationsAndInsulation(modernizationsAndInsulation));
    }

    public submitWindowsAndGlazing(windowsAndGlazing: WindowsAndGlazingFormValue): void {
        this.store$.dispatch(HeatloadActions.submitWindowsAndGlazing(windowsAndGlazing));
    }

    public submitEnergyStandard(energyStandards: EnergyStandardFormValue): void {
        this.store$.dispatch(HeatloadActions.submitEnergyStandard(energyStandards));
    }

    public submitHeatGenerator(energySource: HeatGeneratorFormValue): void {
        this.store$.dispatch(HeatloadActions.submitHeatGenerator(energySource));
    }

    public submitHeatGeneratorDetails(heatGeneratorDetails: HeatGeneratorDetailsFormValue): void {
        this.store$.dispatch(HeatloadActions.submitHeatGeneratorDetails(heatGeneratorDetails));
    }

    public submitEnergyConsumption(energyConsumption: EnergyConsumptionFormValue): void {
        this.store$.dispatch(HeatloadActions.submitEnergyConsumption(energyConsumption));
    }

    public submitHeatingDistribution(heatingDistribution: DistributionAndScheduleFormValue): void {
        this.store$.dispatch(HeatloadActions.submitHeatingDistribution(heatingDistribution));
    }

    public getHeatingSystems(installationYear: string): void {
        this.store$.dispatch(HeatloadActions.getHeatingSystems({ installationYear }));
    }

    public submitSameValue(form: SameFormValue): void {
        this.store$.dispatch(HeatloadActions.submitSameValue(form));
    }

    public resetSameValue(): void {
        this.store$.dispatch(HeatloadActions.resetSameValue());
    }

    public resetHeatload(): void {
        this.store$.dispatch(HeatloadActions.resetHeatload());
    }
}
