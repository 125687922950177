import { Directive, ElementRef, HostListener } from '@angular/core';
import { BoundingRect } from '@customer-apps/shared/interfaces';
import { ActiveAnchorService } from '@customer-apps/shared/services';
import { projectDetailsOffset } from '@customer-apps/shared/utils';

@Directive({
    selector: '[vpActiveAnchor]'
})
export class ActiveAnchorDirective {
    constructor(private element: ElementRef, private activeAnchorService: ActiveAnchorService) {}

    @HostListener('window:scroll', ['$event']) onScroll() {
        const boundingRect: BoundingRect = this.element.nativeElement.getBoundingClientRect();
        const isElementInTheView = boundingRect.top < projectDetailsOffset && boundingRect.top + boundingRect.height > projectDetailsOffset;
        if (isElementInTheView) {
            this.activeAnchorService.setAnchor({ id: this.element.nativeElement.id, event: 'scroll' });
        }
    }

    @HostListener('document:click', ['$event']) onClick() {
        this.activeAnchorService.checkIfBottomReached();
    }
}
