import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GlobalSpinnerService } from '../core/components/global-spinner/global-spinner.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(private globalSpinnerService: GlobalSpinnerService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        request = request.clone();
        const request$ = next.handle(request);
        return request$.pipe(
            finalize(() => {
                this.globalSpinnerService.reset();
            })
        );
    }
}
