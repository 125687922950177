import { Injectable } from '@angular/core';
import { Observable, filter, map, withLatestFrom } from 'rxjs';
import { ProjectStore } from '../../state/project/project.store';
import { ProjectsService } from '../../services';
import { Question } from '@customer-apps/shared/utils';
import { HeatPumpIntermediaryFormService } from './heat-pump-intermediary-form.service';
import { FormName, HeatLoadCalculationMethod, HeatPumpIntermediarySuccessMessages, ProjectType } from '@customer-apps/shared/enums';
import { HeatPumpIntermediaryStore } from '../../state/heat-pump-intermediary/heat-pump-intermediary.store';
import { HeatPumpIntermediaryFormOptionsService } from './heat-pump-intermediary-form-options.service';
import { HeatPumpIntermediaryPrefillService } from './heat-pump-intermediary-prefill.service';
import { AccordionService } from '@customer-apps/shared/interfaces';
import { HeatPumpIntermediaryPrefillMapper } from './heat-pump-intermediary-prefill.mapper';

@Injectable({
    providedIn: 'root'
})
export class HeatPumpIntermediaryBuildingService implements AccordionService {
    constructor(
        private projectStore: ProjectStore,
        private prefillService: HeatPumpIntermediaryPrefillService,
        private heatPumpIntermediaryStore: HeatPumpIntermediaryStore,
        private formService: HeatPumpIntermediaryFormService,
        private formOptionsService: HeatPumpIntermediaryFormOptionsService,
        private projectsService: ProjectsService,
        private prefillMapper: HeatPumpIntermediaryPrefillMapper
    ) {}

    public questions$: Observable<Question[] | null> = this.heatPumpIntermediaryStore.isOldDeviceUsed$.pipe(
        withLatestFrom(this.projectStore.project$, this.heatPumpIntermediaryStore.cache$, (isOldDeviceUsed, project, cache) => ({
            isOldDeviceUsed,
            project,
            cache
        })),
        filter(
            ({ project }) =>
                this.projectsService.isRenovation(project!.projectType as ProjectType) &&
                (this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.Consumption) ||
                    this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.CustomValue))
        ),
        map(({ isOldDeviceUsed, project, cache }) => {
            if (!isOldDeviceUsed) {
                return null;
            }
            const cachePrefill = this.prefillMapper.stateToBuildingPrefill(cache?.building);
            const projectPrefill = this.prefillMapper.projectToBuildingPrefill(project);
            const options = this.formOptionsService.getBuildingFormOptions({ prefill: cachePrefill || projectPrefill });
            return this.formService.getBuildingQuestions(options);
        })
    );

    public getSuccessMessage(): string {
        return HeatPumpIntermediarySuccessMessages.Building;
    }

    public isPrefillNeeded(): boolean {
        return !this.prefillService.isPrefilled(FormName.Building);
    }

    public afterPrefill(): void {
        this.prefillService.setAsPrefilled(FormName.Building);
    }

    public isExpanded$ = this.heatPumpIntermediaryStore.sameValueSubmited$.pipe(
        withLatestFrom(this.projectStore.project$, (form, project) => ({ form, project })),
        map(({ form, project }) => {
            return (
                form?.formName === FormName.Device &&
                this.projectsService.isRenovation(project!.projectType as ProjectType) &&
                (this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.Consumption) ||
                    this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.CustomValue))
            );
        })
    );
}
