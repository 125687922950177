<mat-form-field appearance="outline" [ngClass]="question | formFieldStyles" [hideRequiredMarker]="true">
    <mat-label *ngIf="question.label" [attr.for]="question.key">{{ question.label | translate }}</mat-label>
    <mat-select
        [formControl]="control"
        [errorStateMatcher]="matcher"
        [disableOptionCentering]="true"
        [attr.data-testid]="question.key"
        panelClass="vp-dropdown-panel-class"
    >
        <mat-option
            *ngFor="let option of question.options"
            [value]="option.value"
            [attr.data-testid]="question.key + option.value"
            [id]="question.key + option.value"
            >{{ option.key | translate }}
        </mat-option>
    </mat-select>
    <mat-error>
        <vp-form-error-message>
            {{ errorMessage | translate }}
        </vp-form-error-message>
    </mat-error>
</mat-form-field>
