import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { AuthStore } from 'apps/viguide-planning/src/app/state/auth/auth.store';
import { Observable, take } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { normalizeCommonJSImport } from '@customer-apps/shared/utils';
import { SettingsStore } from './state/settings/settings.store';
import { SnackbarService, WindowService } from '@customer-apps/shared/services';
import { ProjectAccessStore } from './state/project-access/project-access.store';
import { OnboardingService, RemoteConfigService } from './services';

@Component({
    selector: 'vp-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    public authenticated: Observable<boolean>;

    constructor(
        public authStore: AuthStore,
        private meta: Meta,
        private title: Title,
        private translateService: TranslateService,
        private settingsStore: SettingsStore,
        private projectAccessStore: ProjectAccessStore,
        private windowService: WindowService,
        private remoteConfigService: RemoteConfigService,
        private onboarding: OnboardingService,
        private snackbarService: SnackbarService,
        private swUpdate: SwUpdate,
        private changeDetectorRef: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.remoteConfigService.getConfiguration();
        this.onboarding.openDialog();
        this.checkForNewVersion();
        this.importScrollBehaviorPolyfill();
        this.authenticated = authStore.authenticated$.pipe(tap(() => this.changeDetectorRef.detectChanges()));
    }

    public ngOnInit(): void {
        this.authStore.startAuthFlow();
        this.settingsStore.initDefaultLocale();
        this.setMetaTags();
    }

    public logout(): void {
        this.projectAccessStore.unlockProjectBeforeUnload();
        this.authStore.clearToken();
    }

    private setMetaTags() {
        this.translateService
            .stream('COMMON.APP_NAME')
            .pipe(take(1))
            .subscribe(appName => {
                this.title.setTitle(appName);
                this.meta.addTag({ name: 'author', content: appName });
            });
    }

    private async importScrollBehaviorPolyfill() {
        if (!('scrollBehavior' in this.document.documentElement.style)) {
            /**
             * Scroll behavior polyfill
             * https://www.npmjs.com/package/scroll-behavior-polyfill
             */
            await normalizeCommonJSImport(import('scroll-behavior-polyfill'));
        }
    }

    private checkForNewVersion(): void {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.checkForUpdate();
            this.swUpdate.versionUpdates
                .pipe(
                    filter(versionEvent => versionEvent.type === 'VERSION_READY'),
                    switchMap(() => this.translateService.get(['APP.NEW_VERSION', 'BUTTON.RELOAD'])),
                    map(keys => ({
                        message: keys['APP.NEW_VERSION'],
                        action: keys['BUTTON.RELOAD']
                    })),
                    switchMap(({ message, action }) =>
                        this.snackbarService
                            .showInfo(message, {
                                duration: 0,
                                action,
                                verticalPosition: 'top',
                                horizontalPosition: 'center'
                            })
                            .onAction()
                    )
                )
                .subscribe(() => {
                    this.windowService.nativeWindow.location.reload();
                });
        }
    }
}
