import { createReducer, on } from '@ngrx/store';
import { GEGEvaluationActions } from './geg-evaluation.actions';
import { GEGEvaluationState } from '@customer-apps/shared/interfaces';

export const initialState: GEGEvaluationState = {};

export const GEGEvaluationReducer = createReducer(
    initialState,
    on(GEGEvaluationActions.evaluateAlternativeGEG, state => {
        return {
            ...state,
            loadingAlternativeEvaluation: true
        };
    }),
    on(GEGEvaluationActions.evaluateAlternativeGEGSuccess, (state, action) => {
        return {
            ...state,
            alternativeEvaluation: action.evaluation,
            loadingAlternativeEvaluation: false
        };
    }),
    on(GEGEvaluationActions.resetAlternativeEvaluation, state => ({
        ...state,
        alternativeEvaluation: undefined,
        loadingAlternativeEvaluation: undefined
    })),
    on(GEGEvaluationActions.evaluateReviewedGEG, state => {
        return {
            ...state,
            loadingReviewedEvaluation: true
        };
    }),
    on(GEGEvaluationActions.evaluateReviewedGEGSuccess, (state, action) => {
        return {
            ...state,
            reviewedEvaluation: action.evaluation,
            loadingReviewedEvaluation: false
        };
    }),
    on(GEGEvaluationActions.resetReviewedEvaluation, state => ({
        ...state,
        reviewedEvaluation: undefined,
        loadingReviewedEvaluation: undefined
    }))
);
