/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AccountValidationResponse } from '../models/account-validation-response';
import { usersControllerValidateAccess } from '../fn/users-controller/users-controller-validate-access';
import { UsersControllerValidateAccess$Params } from '../fn/users-controller/users-controller-validate-access';


/**
 * Represents an API resource used to get the data related to users and accounts.
 */
@Injectable({ providedIn: 'root' })
export class UsersControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `usersControllerValidateAccess()` */
  static readonly UsersControllerValidateAccessPath = '/planning-projects/v1-develop/users/validate-access';

  /**
   * Validates access for current session user. The response contains information if user has access to this API server or not.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerValidateAccess()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerValidateAccess$Response(params?: UsersControllerValidateAccess$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountValidationResponse>> {
    return usersControllerValidateAccess(this.http, this.rootUrl, params, context);
  }

  /**
   * Validates access for current session user. The response contains information if user has access to this API server or not.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerValidateAccess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerValidateAccess(params?: UsersControllerValidateAccess$Params, context?: HttpContext): Observable<AccountValidationResponse> {
    return this.usersControllerValidateAccess$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountValidationResponse>): AccountValidationResponse => r.body)
    );
  }

}
