import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'countryCode'
})
export class CountryCodePipe implements PipeTransform {
    transform(countryLanguageCode: string | null): string {
        return countryLanguageCode ? countryLanguageCode.slice(-2).toLowerCase() : '';
    }
}
