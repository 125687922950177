import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Dropdown, DropdownErrorMatcher } from '@customer-apps/shared/utils';

@Component({
    selector: 'vp-dropdown',
    templateUrl: './dropdown.component.html'
})
export class DropdownComponent {
    @Input() question: Dropdown;
    @Input() form: FormGroup;
    public matcher = new DropdownErrorMatcher();

    get control(): FormControl {
        return this.form.get((this.question as Dropdown).key) as FormControl;
    }

    get errorMessage(): string {
        const errorMessage = this.control?.errors?.['message'];
        return errorMessage || (this.question as Dropdown).defaultErrorMessage;
    }
}
