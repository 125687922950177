import { Pipe, PipeTransform } from '@angular/core';
import MarkdownIt from 'markdown-it';

@Pipe({
    name: 'markdownIt'
})
export class MarkdownItPipe implements PipeTransform {
    transform(markdown: string): string {
        return new MarkdownIt().render(markdown);
    }
}
