export enum CountryLanguageCodes {
    deDE = 'de-DE',
    enDE = 'en-DE',
    frBE = 'fr-BE',
    nlBE = 'nl-BE',
    deAT = 'de-AT',
    deCH = 'de-CH',
    frCH = 'fr-CH',
    frFR = 'fr-FR',
    esES = 'es-ES',
    plPL = 'pl-PL',
    csCZ = 'cs-CZ',
    skSK = 'sk-SK'
}
