<mat-toolbar color="primary" class="justify-content-between">
    <div class="d-flex align-items-center">
        <div class="d-none d-sm-flex vp-gap-4 app-name">
            <img class="app-logo vp-pointer" src="/assets/images/vi-logo.svg" alt="Viessmann" routerLink="/projects" />
            <span>･</span>
            <span data-testid="app-title">{{ 'COMMON.APP_NAME' | translate }}</span>
        </div>
    </div>
    <div *ngIf="settingsStore.state$ | async as state" class="d-flex align-items-center">
        <vp-country-language-switcher *ngIf="isAuthenticated | async"></vp-country-language-switcher>
        <vih-support-link data-testid="global-login-support-link"></vih-support-link>
        <vih-app-switcher
            data-testid="global-login-app-switcher"
            [appId]="environmentService.appId"
            [userId]="$any(authStore.userId$ | async)"
            [lang]="state.locale | languageCode"
        ></vih-app-switcher>
        <vih-login
            data-testid="global-login-button"
            [appManagesAuthentication]="true"
            [authenticated]="isAuthenticated"
            [lang]="state.locale | languageCode"
            (logoutEvent)="logout()"
        ></vih-login>
    </div>
</mat-toolbar>
