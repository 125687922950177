export enum ProjectStatus {
    Design = 'DESIGN',
    PlanningDone = 'PLANNING_DONE',
    QuoteCreated = 'QUOTE_CREATED',
    OrderPlaced = 'ORDER_PLACED',
    Completed = 'COMPLETED',
    Closed = 'CLOSED',
    Archived = 'ARCHIVED',
    Migrated = 'MIGRATED',
    MigratedClosed = 'MIGRATED_CLOSED'
}
