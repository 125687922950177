// Order of elements reflects order by the design
// Changing order of elements here will affect UI
export enum EnergySourcesOrder {
    'HEATING_OIL',
    'GAS',
    'ELECTRICITY',
    'ELECTRICITY_HEATPUMP',
    'PELLET',
    'SOFTWOOD',
    'HARDWOOD'
}
