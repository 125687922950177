export enum CountryCode {
    DE = 'DE',
    BE = 'BE',
    AT = 'AT',
    NL = 'NL',
    FR = 'FR',
    CH = 'CH',
    ES = 'ES',
    SK = 'SK',
    CZ = 'CZ',
    PL = 'PL'
}
