<ng-container *ngIf="settingsStore.state$ | async as state">
    <ng-container *ngIf="countryLanguagePairs$ | async as countryLanguagePairs">
        <button
            *ngIf="countryLanguagePairs?.length! > 1; else noLocaleOptionsElement"
            mat-button
            [matMenuTriggerFor]="countryLanguagesOptions"
            (click)="chevronIcon = 'expand_less'"
            data-testid="country-language-switcher-button"
        >
            {{ state.locale | languageCode | uppercase }}
            <mat-icon>{{ chevronIcon }}</mat-icon>
        </button>

        <mat-menu #countryLanguagesOptions="matMenu" (closed)="chevronIcon = 'expand_more'">
            <button
                *ngFor="let pair of countryLanguagePairs"
                mat-menu-item
                (click)="settingsStore.changeLocale(pair.locale)"
                [attr.data-testid]="pair.locale + '-button'"
            >
                <div class="d-flex align-items-center vp-gap-8" [class.selected-locale]="state.locale === pair.locale">
                    <span class="flag flag-{{ pair.locale | countryCode }}"></span>
                    {{ languageCountryType[pair.locale] | translate }}
                </div>
            </button>
        </mat-menu>
    </ng-container>

    <ng-template #noLocaleOptionsElement>
        <span class="locale" data-testid="country-language-switcher-locale">
            {{ state.locale | languageCode | uppercase }}
        </span>
    </ng-template>
</ng-container>
