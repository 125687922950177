import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, iif, interval, of } from 'rxjs';
import { map, switchMap, catchError, tap, delay, withLatestFrom, takeUntil, filter } from 'rxjs/operators';
import { ProjectsControllerService } from '@customer-apps/api-planning-projects';
import { LockResult, RoutePaths } from '@customer-apps/shared/enums';
import { SnackbarService, WindowService } from '@customer-apps/shared/services';
import { GlobalSpinnerService } from '../../core';
import { EnvironmentService } from '../../services';
import { ProjectActions } from '../project/project.actions';
import { ProjectStore } from '../project/project.store';
import { ProjectAccessActions } from './project-access.actions';
import { ProjectAccessStore } from './project-access.store';
import { CustomSnackbarMessageComponent } from '../../shared/components/custom-snackbar-message/custom-snackbar-message.component';

@Injectable()
export class ProjectAccessEffects {
    constructor(
        private actions$: Actions,
        private projectsControllerService: ProjectsControllerService,
        private projectAccessStore: ProjectAccessStore,
        private projectStore: ProjectStore,
        private translate: TranslateService,
        private snackbarService: SnackbarService,
        private router: Router,
        private windowService: WindowService,
        private environmentService: EnvironmentService,
        private globalSpinnerService: GlobalSpinnerService
    ) {}

    public verifyProjectLock$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectAccessActions.verifyProjectLock),
            switchMap(({ id }) =>
                this.projectsControllerService.projectsControllerVerifyProjectLock({ id }).pipe(
                    map(res => ProjectAccessActions.verifyProjectLockSuccess(res)),
                    catchError(() => EMPTY)
                )
            )
        )
    );

    public lockProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectAccessActions.lockProject),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.LOCKING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.LOCKING_PROJECT'
                });
            }),
            withLatestFrom(this.projectAccessStore.state$, (action, state) => ({ id: action.id, lockResult: state.lockResult })),
            switchMap(({ id, lockResult }) => {
                const lockProjectId = this.windowService.localStorage.getItem(this.environmentService.lockProjectIdStorageKey);
                if (lockProjectId === id) {
                    this.windowService.localStorage.removeItem(this.environmentService.lockProjectIdStorageKey);
                    return of(
                        ProjectAccessActions.lockProjectSuccess({
                            projectId: lockProjectId,
                            result: LockResult.LockedSuccessfully
                        })
                    );
                }

                if (lockResult === LockResult.LockedSuccessfully) {
                    return of(ProjectActions.getProject({ id }));
                }

                return this.projectsControllerService.projectsControllerLockProject({ id }).pipe(
                    map(res => ProjectAccessActions.lockProjectSuccess(res)),
                    catchError(() => EMPTY)
                );
            })
        )
    );

    public nextAttemptProjectLock$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectAccessActions.nextAttemptProjectLock),
            switchMap(({ id }) =>
                this.projectsControllerService.projectsControllerLockProject({ id }).pipe(
                    map(res => ProjectAccessActions.nextAttemptProjectLockResult(res)),
                    catchError(() => EMPTY)
                )
            )
        )
    );

    public nextAttemptProjectLockResult$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectAccessActions.nextAttemptProjectLockResult),
            switchMap(res => {
                switch (res.result) {
                    case LockResult.LockedSuccessfully:
                    case LockResult.AlreadyLockedByOtherUser:
                        return of(ProjectAccessActions.nextAttemptProjectLockSuccess({ res, otherSessionExists: false }));

                    case LockResult.AlreadyLockedByCurrentUser:
                        return [
                            ProjectAccessActions.showOtherSessionExistsSnackbar({
                                action: this.translate.instant('COMMON.BUTTONS.GOT_IT'),
                                message: this.translate.instant('PROJECT_ACCESS.LOCK_PROJECT.ALREADY_LOCKED_BY_CURRENT_USER')
                            }),
                            ProjectAccessActions.nextAttemptProjectLockSuccess({ res, otherSessionExists: true })
                        ];

                    default:
                        return EMPTY;
                }
            })
        )
    );

    public unlockProjectBeforeUnload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectAccessActions.unlockProjectBeforeUnload),
            withLatestFrom(this.projectStore.project$, this.projectAccessStore.unlockOnUnload$, (_action, project, unlockOnUnload) => ({
                id: project?.id!,
                unlockOnUnload
            })),
            switchMap(({ id, unlockOnUnload }) =>
                iif(
                    () => !!id && unlockOnUnload,
                    this.projectsControllerService.projectsControllerUnlockProject({ id }).pipe(
                        map(res => ProjectAccessActions.unlockProjectSuccess(res)),
                        catchError(() => EMPTY)
                    ),
                    EMPTY
                )
            )
        )
    );

    public unlockProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectAccessActions.unlockProject),
            withLatestFrom(this.projectStore.project$, this.projectAccessStore.status$, (action, project, status) => ({
                id: action?.id || project?.id!,
                lockedByCurrentUser: status.lockedByCurrentUser
            })),
            switchMap(({ id, lockedByCurrentUser }) =>
                iif(
                    () => !!id && lockedByCurrentUser,
                    this.projectsControllerService.projectsControllerUnlockProject({ id }).pipe(
                        map(res => ProjectAccessActions.unlockProjectSuccess(res)),
                        catchError(() => EMPTY)
                    ),
                    EMPTY
                )
            )
        )
    );

    public verifyProjectLockSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectAccessActions.verifyProjectLockSuccess),
            withLatestFrom(this.projectAccessStore.status$, (action, status) => ({
                result: action.result,
                locked: status.locked
            })),
            tap(({ result, locked }) => {
                // Disabled project locking [CAVP-1969]
                if (this.environmentService.disableProjectLocking) {
                    return;
                }

                if (locked) {
                    /**
                     * READ-ONLY MODE - If result is ALREADY_LOCKED_BY_CURRENT_USER or UNLOCKED
                     * we refresh the page to enable edit mode
                     */
                    if (result === LockResult.AlreadyLockedByCurrentUser || result === LockResult.Unlocked) {
                        this.windowService.reload();
                    }

                    /**
                     * READ-ONLY MODE - If result is ALREADY_LOCKED_BY_OTHER_USER
                     * we stay in read-only mode
                     */
                    return;
                }

                /**
                 * EDIT MODE - If result is ALREADY_LOCKED_BY_OTHER_USER or UNLOCKED
                 * we immediately break the session end move the user to the Projects List screen
                 */
                if (result === LockResult.AlreadyLockedByOtherUser || result === LockResult.Unlocked) {
                    this.router.navigate([RoutePaths.Projects]);
                }

                /**
                 * EDIT MODE - If result is ALREADY_LOCKED_BY_CURRENT_USER
                 * we do nothing
                 */
            }),
            switchMap(({ result, locked }) => {
                // Disabled project locking [CAVP-1969]
                if (this.environmentService.disableProjectLocking) {
                    return EMPTY;
                }

                /**
                 * EDIT MODE - If result is ALREADY_LOCKED_BY_OTHER_USER or UNLOCKED
                 * we show the correct top info panel
                 */
                if (!locked && (result === LockResult.AlreadyLockedByOtherUser || result === LockResult.Unlocked)) {
                    return [ProjectAccessActions.stopVerifyProjectLock(), ProjectAccessActions.showExpiredLockSessionMessage()];
                }
                return EMPTY;
            })
        )
    );

    public lockProjectSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectAccessActions.lockProjectSuccess),
            delay(0),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.LOADING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.LOADING_PROJECT'
                });
            }),
            map(action => ({
                action,
                nextAttemptLockDelay:
                    action.result === LockResult.AlreadyLockedByCurrentUser ? this.environmentService.nextAttemptLockDelay : 0
            })),
            switchMap(payload =>
                iif(
                    () => !payload.nextAttemptLockDelay,
                    of(ProjectAccessActions.nextAttemptProjectLockSuccess({ res: payload.action, otherSessionExists: false })),
                    of(ProjectAccessActions.nextAttemptProjectLock({ id: payload.action.projectId })).pipe(
                        tap(() => {
                            this.globalSpinnerService.next({
                                isShown: true,
                                loadingText: 'LOADER.TEXT.LOCKING_PROJECT',
                                loadingSubtext: 'LOADER.SUBTEXT.LOCKING_PROJECT'
                            });
                        }),
                        delay(payload.nextAttemptLockDelay)
                    )
                )
            )
        )
    );

    public nextAttemptProjectLockSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectAccessActions.nextAttemptProjectLockSuccess),
            delay(0),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.LOADING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.LOADING_PROJECT'
                });
            }),
            switchMap(payload =>
                iif(() => payload.otherSessionExists, of(ProjectActions.getProject({ id: payload.res.projectId })), [
                    ProjectAccessActions.startVerifyProjectLock(),
                    ProjectActions.getProject({ id: payload.res.projectId })
                ])
            )
        )
    );

    public verifingLockProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectAccessActions.startVerifyProjectLock),
            switchMap(() =>
                interval(this.environmentService.verifyLockPollingInterval).pipe(
                    takeUntil(this.actions$.pipe(ofType(ProjectAccessActions.stopVerifyProjectLock)))
                )
            ),
            withLatestFrom(this.projectStore.project$, (_action, project) => ({ id: project?.id! })),
            switchMap(({ id }) => of(ProjectAccessActions.verifyProjectLock({ id })))
        )
    );

    public skipProjectUnlockBeforeUnload$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectAccessActions.skipProjectUnlockBeforeUnload),
                withLatestFrom(this.projectStore.project$, (_action, project) => ({
                    projectId: project?.id!
                })),
                tap(({ projectId }) => {
                    if (projectId) {
                        this.windowService.localStorage.setItem(this.environmentService.lockProjectIdStorageKey, projectId!);
                    }
                })
            ),
        { dispatch: false }
    );

    public showOtherSessionExistsSnackbar$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectAccessActions.showOtherSessionExistsSnackbar),
                switchMap(({ action, message }) =>
                    this.snackbarService
                        .showCustomInfo(CustomSnackbarMessageComponent, {
                            data: { message, action },
                            duration: 0,
                            verticalPosition: 'top'
                        })
                        .onAction()
                )
            ),
        { dispatch: false }
    );
}
