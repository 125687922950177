import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfirmConfig, ConfirmOutput } from '@customer-apps/shared/interfaces';
import { ConfirmComponent } from './confirm.component';

@Injectable({
    providedIn: 'root'
})
export class ConfirmService {
    private dialogs: { [uuid: string]: Subject<ConfirmOutput | undefined> } = {};

    constructor(private matDialog: MatDialog) {}

    public getDialog(uuid: string): Observable<ConfirmOutput | undefined> {
        return this.dialogs[uuid].asObservable();
    }

    public register(uuid: string): void {
        this.dialogs[uuid] = new Subject();
    }

    public confirm(uuid: string, output: ConfirmOutput | undefined): void {
        this.dialogs[uuid].next(output);
        this.dialogs[uuid].complete();
        delete this.dialogs[uuid];
    }

    public showDialog(config: ConfirmConfig): Observable<ConfirmOutput | undefined> {
        const uuid = config.uuid || Math.random().toString(36);
        this.register(uuid);

        const dialogRef: MatDialogRef<ConfirmComponent, ConfirmOutput> = this.matDialog.open(ConfirmComponent, {
            width: '520px',
            disableClose: true,
            autoFocus: false,
            data: { ...config }
        });

        return dialogRef.afterClosed().pipe(
            tap(output => {
                this.confirm(uuid, output);
            })
        );
    }
}
