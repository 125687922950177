import cloneDeep from 'lodash/cloneDeep';
import differenceBy from 'lodash/differenceBy';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import flatten from 'lodash/flatten';
import round from 'lodash/round';

/* istanbul ignore next */
export class LodashService {
    static cloneDeep<T>(target: T): T {
        return cloneDeep(target);
    }

    static differenceBy(firstTarget: any[], secondTarget: any[], nameOrFunction: string | ((...args: any[]) => string)): any[] {
        return differenceBy(firstTarget, secondTarget, nameOrFunction);
    }

    static get(obj: any, path: string | string[]): any {
        return get(obj, path);
    }

    static flatten(value: any[]): any[] {
        return flatten(value);
    }

    static isNil(value: any): boolean {
        return isNil(value);
    }

    static isNaN(value: any): boolean {
        return isNaN(value);
    }

    static isEqual(obj1: any, obj2: any): boolean {
        return isEqual(obj1, obj2);
    }

    static omit<T>(obj: any, prop: string | string[]): Pick<T, never> {
        return omit(obj, prop);
    }

    static round(value: number, precision?: number | undefined): number {
        return round(value, precision);
    }
}
