import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { GlobalSpinnerState } from '@customer-apps/shared/interfaces';

const initGlobalSpinnerState: GlobalSpinnerState = {
    isShown: false,
    loadingText: 'LOADER.LOADING'
};

@Injectable({
    providedIn: 'root'
})
export class GlobalSpinnerService {
    private renderer: Renderer2;
    private state$: BehaviorSubject<GlobalSpinnerState> = new BehaviorSubject(initGlobalSpinnerState);

    constructor(@Inject(DOCUMENT) private document: Document, private rendererFactory: RendererFactory2) {
        this.initBodyElementChanges();
    }

    public get state(): Observable<GlobalSpinnerState> {
        return this.state$.asObservable();
    }

    public next(state: GlobalSpinnerState): void {
        this.state$.next(state);
    }

    public reset(): void {
        this.state$.next(initGlobalSpinnerState);
    }

    private initBodyElementChanges(): void {
        this.renderer = this.rendererFactory.createRenderer(null, null);
        this.state.subscribe(state => {
            if (state.isShown) {
                this.renderer.addClass(this.document.body, 'vp-overflow-hidden');
            } else {
                this.renderer.removeClass(this.document.body, 'vp-overflow-hidden');
            }
        });
    }
}
