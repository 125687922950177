import { ApplicationId } from '@customer-apps/shared/enums';
import { EnumDictionary } from '@customer-apps/shared/utils';

export const orderApplicationIdDictionary: EnumDictionary<ApplicationId, string> = {
    [ApplicationId.HeatPumpPlanner]: 'ORDER.TITLE.HEAT_PUMP_PLANNER',
    [ApplicationId.PhotovoltaicPlanner]: 'ORDER.TITLE.PHOTOVOLTAIC_PLANNER',
    [ApplicationId.VentilationPlanner]: 'ORDER.TITLE.VENTILATION_PLANNER',
    [ApplicationId.CogenerationCalculator]: 'ORDER.TITLE.COGENERATION_CALCULATOR',
    [ApplicationId.ElectricityPlanner]: 'ORDER.TITLE.ELECTRICITY_PLANNER',
    [ApplicationId.SystemAdviser]: 'ORDER.TITLE.SYSTEM_ADVISER',
    [ApplicationId.CylinderPlanner]: 'ORDER.TITLE.CYLINDER_PLANNER',
    [ApplicationId.ViGuidePlanning]: 'ORDER.TITLE.VIGUIDE_PLANNING'
};
