/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { UpdateProjectRequestPartial } from '../../models/update-project-request-partial';
import { UpdateProjectResponse } from '../../models/update-project-response';

export interface ProjectsControllerPatchProject$Params {

/**
 * Project id.
 */
  id: string;

/**
 * Optional query parameter denoting a method name which affected by this PATCH call, so that
 *             API can skip potential shared dependency recalculation for this method
 */
  affectedCalculationMethod?: Array<'LIVING_SPACE' | 'BUILDING_HULL' | 'ENERGY_CERTIFICATE' | 'CONSUMPTION' | 'CUSTOM_VALUE'>;
  
    /**
     * Patching request - contains only fields to be modified.
     */
    body?: UpdateProjectRequestPartial
}

export function projectsControllerPatchProject(http: HttpClient, rootUrl: string, params: ProjectsControllerPatchProject$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateProjectResponse>> {
  const rb = new RequestBuilder(rootUrl, projectsControllerPatchProject.PATH, 'patch');
  if (params) {
    rb.path('id', params.id, {});
    rb.query('affectedCalculationMethod', params.affectedCalculationMethod, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<UpdateProjectResponse>;
    })
  );
}

projectsControllerPatchProject.PATH = '/planning-projects/v1-develop/projects/{id}';
