import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CheckboxQuestion } from '@customer-apps/shared/utils';
import { slideDown } from '../../animations';

@Component({
    selector: 'vp-checkbox',
    templateUrl: './checkbox.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [slideDown]
})
export class CheckboxComponent {
    @Input() question: CheckboxQuestion;
    @Input() form: FormGroup;

    get control(): FormControl {
        return this.form.get((this.question as CheckboxQuestion).key) as FormControl;
    }

    get errorMessage(): string {
        const errorMessage = this.control?.errors?.['message'];
        return errorMessage || (this.question as CheckboxQuestion).defaultErrorMessage;
    }

    get isInvalid(): boolean {
        return this.control?.invalid;
    }

    get isTouched(): boolean {
        return this.control?.touched;
    }
}
