import { Injectable } from '@angular/core';
import { Observable, filter, first, map, merge, withLatestFrom } from 'rxjs';
import { HeatloadStore } from '../../state/heatload/heatload.store';
import { BuildingType, CountryCode, FormName, HeatLoadCalculationMethod, ProjectType, SuccessMessages } from '@customer-apps/shared/enums';
import { HeatloadPrefillService } from './heatload-prefill.service';
import { AccordionService, FormOptions, LivingAreaFormValue } from '@customer-apps/shared/interfaces';
import { ProjectStore } from '../../state/project/project.store';
import { HeatloadFormService } from './heatload-form.service';
import { TranslateService } from '@ngx-translate/core';
import { HeatloadFormOptionsService } from './heatload-form-options.service';
import { HeatloadPrefillMapper, ProjectsService } from '../../services';
import { ActivatedRoute } from '@angular/router';
import { HeatloadService } from './heatload.service';

@Injectable({
    providedIn: 'root'
})
export class LivingAreaService implements AccordionService {
    constructor(
        private heatloadFormOptionsService: HeatloadFormOptionsService,
        private heatloadPrefillService: HeatloadPrefillService,
        private heatloadService: HeatloadService,
        private prefillMapper: HeatloadPrefillMapper,
        private heatloadStore: HeatloadStore,
        private translate: TranslateService,
        private projectStore: ProjectStore,
        private route: ActivatedRoute,
        private projectsService: ProjectsService,
        private heatloadFormService: HeatloadFormService
    ) {}

    public questions$ = this.heatloadStore.calculationMethod$.pipe(
        withLatestFrom(this.projectStore.project$, this.heatloadStore.cache$, (method, project, cache) => ({
            isLivingArea: method?.value === HeatLoadCalculationMethod.LivingSpace,
            project,
            cache
        })),
        map(state => {
            if (!state.isLivingArea) {
                return null;
            }
            const projectPrefill = this.prefillMapper.projectToLivingAreaPrefill(state.project!);
            const cachePrefill = this.prefillMapper.stateToLivingAreaPrefill(state.cache);
            const options: FormOptions = this.heatloadFormOptionsService.getLivingAreaFormOptions({
                prefill: cachePrefill || projectPrefill,
                buildingType: state.project!.building.buildingType as BuildingType,
                countryCode: state.project!.address.countryCode as CountryCode
            });
            return this.heatloadFormService.getLivingAreaQuestions(options as FormOptions);
        })
    );

    public getSuccessMessage(formValue: LivingAreaFormValue): string {
        return this.translate.instant(SuccessMessages.LivingAreaSize, { value: formValue.livingArea });
    }

    public isPrefillNeeded(): boolean {
        return this.heatloadService.isRecalculation() && !this.heatloadPrefillService.isPrefilled(FormName.LivingArea);
    }

    public afterPrefill(): void {
        this.heatloadPrefillService.setAsPrefilled(FormName.LivingArea);
    }

    public isExpanded$ = merge(this.isSubmitedCalculationMethodSameValue$(), this.prefillCompletedByLivingArea$());

    private prefillCompletedByLivingArea$(): Observable<boolean> {
        return this.heatloadPrefillService.state$.pipe(
            withLatestFrom(this.projectStore.project$, (prefillState, project) => ({ prefillState, project })),
            map(({ prefillState, project }) => {
                const lastAccordionToBePrefilled = this.projectsService.isNewConstruction(project!.projectType as ProjectType)
                    ? FormName.EnergyStandard
                    : FormName.Ventilation;
                const isRecalculation = this.route.firstChild?.firstChild?.snapshot.queryParamMap.get('method');
                return prefillState[lastAccordionToBePrefilled].isPrefilled && !!isRecalculation;
            }),
            filter(Boolean),
            first()
        );
    }

    private isSubmitedCalculationMethodSameValue$() {
        return this.heatloadStore.sameValueSubmited$.pipe(
            map(
                form =>
                    form?.formName === FormName.CalculationMethod &&
                    form.value.methods.standardMethod === HeatLoadCalculationMethod.LivingSpace
            )
        );
    }
}
