import { ApplicationId } from '@customer-apps/shared/enums';
import { EnumDictionary } from '@customer-apps/shared/utils';

export const addPlanningByApplicationIdDictionary: EnumDictionary<ApplicationId, string> = {
    [ApplicationId.HeatPumpPlanner]: 'VARIANT_TILE.ADD_SYSTEM.HEAT_PUMP_PLANNER',
    [ApplicationId.PhotovoltaicPlanner]: 'VARIANT_TILE.ADD_SYSTEM.PHOTOVOLTAIC_PLANNER',
    [ApplicationId.VentilationPlanner]: 'VARIANT_TILE.ADD_SYSTEM.VENTILATION_PLANNER',
    [ApplicationId.CogenerationCalculator]: 'VARIANT_TILE.ADD_SYSTEM.COGENERATION_CALCULATOR',
    [ApplicationId.ElectricityPlanner]: 'VARIANT_TILE.ADD_SYSTEM.ELECTRICITY_PLANNER',
    [ApplicationId.SystemAdviser]: 'VARIANT_TILE.ADD_SYSTEM.SYSTEM_ADVISER',
    [ApplicationId.CylinderPlanner]: 'VARIANT_TILE.ADD_SYSTEM.CYLINDER_PLANNER',
    [ApplicationId.ViGuidePlanning]: 'VARIANT_TILE.ADD_SYSTEM.VIGUIDE_PLANNING'
};
