import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { BuildingType, FormKeys, ProjectType } from '@customer-apps/shared/enums';
import { FormOptions } from '@customer-apps/shared/interfaces';
import { CommonFormService } from '../../services/common-form.service';
import { RadioQuestion, Question, TextboxQuestion } from '@customer-apps/shared/utils';
import { FormToolsService, ValidatorsService } from '../../services';

@Injectable({
    providedIn: 'root'
})
export class HeatPumpIntermediaryFormService {
    constructor(
        private commonFormService: CommonFormService,
        private validatorsService: ValidatorsService,
        private formToolsService: FormToolsService
    ) {}

    private get device() {
        return new RadioQuestion({
            key: FormKeys.IsOldDeviceUsed,
            question: 'HEAT_PUMP_INTERMEDIARY.DEVICE.DEVICE.TITLE',
            styles: 'vp-radio-columns',
            defaultErrorMessage: 'HEAT_PUMP_INTERMEDIARY.DEVICE.DEVICE.ERRORS.REQUIRED',
            validators: [Validators.required],
            options: [
                { key: 'HEAT_PUMP_INTERMEDIARY.DEVICE.DEVICE.YES', value: true },
                { key: 'HEAT_PUMP_INTERMEDIARY.DEVICE.DEVICE.NO', value: false }
            ]
        });
    }

    private get incomeLowForSubsidy() {
        return new RadioQuestion({
            key: FormKeys.IsIncomeLowForSubsidy,
            question: 'HEAT_PUMP_INTERMEDIARY.HOUSEHOLD.INCOME.TITLE',
            styles: 'vp-radio-columns',
            defaultErrorMessage: 'HEAT_PUMP_INTERMEDIARY.HOUSEHOLD.INCOME.ERRORS.REQUIRED',
            validators: [Validators.required],
            options: [
                { key: 'HEAT_PUMP_INTERMEDIARY.HOUSEHOLD.YES', value: true },
                { key: 'HEAT_PUMP_INTERMEDIARY.HOUSEHOLD.NO', value: false }
            ]
        });
    }

    private get ownerOfTheBuilding() {
        return new RadioQuestion({
            key: FormKeys.IsOwnerOfTheBuilding,
            question: 'HEAT_PUMP_INTERMEDIARY.HOUSEHOLD.BUILDING_OWNER.TITLE',
            styles: 'vp-radio-columns',
            defaultErrorMessage: 'HEAT_PUMP_INTERMEDIARY.HOUSEHOLD.BUILDING_OWNER.ERRORS.REQUIRED',
            validators: [Validators.required],
            options: [
                { key: 'HEAT_PUMP_INTERMEDIARY.HOUSEHOLD.YES', value: true },
                { key: 'HEAT_PUMP_INTERMEDIARY.HOUSEHOLD.NO', value: false }
            ]
        });
    }

    private get ownerMainResidence() {
        return new RadioQuestion({
            key: FormKeys.IsOwnerMainResidence,
            question: 'HEAT_PUMP_INTERMEDIARY.HOUSEHOLD.MAIN_RESIDENCE.TITLE',
            styles: 'vp-radio-columns',
            defaultErrorMessage: 'HEAT_PUMP_INTERMEDIARY.HOUSEHOLD.MAIN_RESIDENCE.ERRORS.REQUIRED',
            validators: [Validators.required],
            options: [
                { key: 'HEAT_PUMP_INTERMEDIARY.HOUSEHOLD.YES', value: true },
                { key: 'HEAT_PUMP_INTERMEDIARY.HOUSEHOLD.NO', value: false }
            ]
        });
    }

    private get oldHeatingSystemDefective() {
        return new RadioQuestion({
            key: FormKeys.IsOldHeatingSystemDefective,
            question: 'HEAT_PUMP_INTERMEDIARY.HOUSEHOLD.DEFECTIVE.TITLE',
            styles: 'vp-radio-columns',
            defaultErrorMessage: 'HEAT_PUMP_INTERMEDIARY.HOUSEHOLD.DEFECTIVE.ERRORS.REQUIRED',
            validators: [Validators.required],
            options: [
                { key: 'HEAT_PUMP_INTERMEDIARY.HOUSEHOLD.YES', value: true },
                { key: 'HEAT_PUMP_INTERMEDIARY.HOUSEHOLD.NO', value: false }
            ]
        });
    }

    private get residentialUnits() {
        return new TextboxQuestion({
            question: 'HEAT_PUMP_INTERMEDIARY.SUBSIDY.RESIDENTIAL_UNITS.TITLE',
            description: 'HEAT_PUMP_INTERMEDIARY.SUBSIDY.RESIDENTIAL_UNITS.DESCRIPTION',
            defaultErrorMessage: 'HEAT_PUMP_INTERMEDIARY.SUBSIDY.RESIDENTIAL_UNITS.REQUIRED',
            label: 'HEAT_PUMP_INTERMEDIARY.SUBSIDY.RESIDENTIAL_UNITS.LABEL',
            key: FormKeys.NumberOfResidentialUnits,
            hints: [this.formToolsService.getNumericHint()],
            validators: [Validators.required, this.validatorsService.minMaxValidator({ min: 2, max: 500 })]
        });
    }

    public getDeviceQuestions(options?: FormOptions): RadioQuestion[] {
        const deviceOverride = options?.questions?.[FormKeys.IsOldDeviceUsed];
        const device = this.device;
        device.value = deviceOverride?.value;

        return [device];
    }

    public getBuildingQuestions(options?: FormOptions): Question[] {
        const buildingConstructionYear = this.commonFormService.getBuildingConstructionYear(options);
        return [buildingConstructionYear];
    }

    public getSubsidyEligibilityQuestions(options?: FormOptions): (RadioQuestion | TextboxQuestion)[] {
        // Multi family house
        if (options?.buildingType === BuildingType.MultiFamilyHouse) {
            const residentialUnitsOverride = options?.questions?.[FormKeys.NumberOfResidentialUnits];
            const residentialUnits = this.residentialUnits;
            residentialUnits.value = residentialUnitsOverride?.value;
            return [residentialUnits];
        }

        // Single family house
        const incomeLowForSubsidyOverride = options?.questions?.[FormKeys.IsIncomeLowForSubsidy];
        const ownerOfTheBuildingOverride = options?.questions?.[FormKeys.IsOwnerOfTheBuilding];
        const ownerMainResidenceOverride = options?.questions?.[FormKeys.IsOwnerMainResidence];
        const oldHeatingSystemDefectiveOverride = options?.questions?.[FormKeys.IsOldHeatingSystemDefective];
        const incomeLowForSubsidy = this.incomeLowForSubsidy;
        const ownerOfTheBuilding = this.ownerOfTheBuilding;
        const ownerMainResidence = this.ownerMainResidence;
        const oldHeatingSystemDefective = this.oldHeatingSystemDefective;
        incomeLowForSubsidy.value = incomeLowForSubsidyOverride?.value;
        ownerOfTheBuilding.value = ownerOfTheBuildingOverride?.value;
        ownerMainResidence.value = ownerMainResidenceOverride?.value;
        oldHeatingSystemDefective.value = oldHeatingSystemDefectiveOverride?.value;

        return options?.projectType === ProjectType.Renovation
            ? [incomeLowForSubsidy, ownerOfTheBuilding, ownerMainResidence, oldHeatingSystemDefective]
            : [incomeLowForSubsidy, ownerOfTheBuilding, ownerMainResidence];
    }
}
